const isEncoded = (path: string): boolean =>
  (path || '') !== decodeURIComponent(path)

export const pathToURI = (path: string): string =>
  path
    .split('>')
    .filter(item => item !== 'root')
    .map(item => {
      return isEncoded(item) ? item : encodeURIComponent(item)
    })
    .join('>')
    .replace(new RegExp('>', 'g'), '/')

export const decodePath = (path: string): string =>
  path
    .split('>')
    .filter(item => item !== 'root')
    .map(item => decodeURIComponent(decodeURIComponent(item)))
    .join('>')

export const encodePathArray = (pathArray: string[]): string =>
  pathArray
    .map(item => (isEncoded(item) ? item : encodeURIComponent(item)))
    .join('>')

export const encodePath = (path: string): string =>
  path
    .split('>')
    .map(item => (isEncoded(item) ? item : encodeURIComponent(item)))
    .join('>')

export const pathMatches = (path1: string, path2: string): boolean =>
  decodeURIComponent(path1) === decodeURIComponent(path2)
