import React from 'react'

type Props = {
  children: React.ReactNode
}

type State = {
  error: Error | null
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  // @ts-ignore
  static getDerivedStateFromError(error): State {
    return { error }
  }

  // @ts-ignore
  componentDidCatch(error) {
    this.setState({
      error: error,
    })
  }

  render(): React.ReactNode {
    if (this.state.error) {
      return (
        <div>
          Something has gone wrong please report:{' '}
          {JSON.stringify(this.state.error.message)} to{' '}
          <a href="https://moonpig-group.slack.com/archives/C013CJCP093">
            #mp-studio
          </a>
        </div>
      )
    }

    return this.props.children
  }
}
