import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { SEARCH_GROUPS_BY_PATH_SUBSTRING } from '../queries'
import {
  SearchGroupsByPathSubstringQuery,
  SearchGroupsByPathSubstringQueryVariables,
} from '../__graphql__/queries'
import { StudioGroupSearch } from '../__graphql__/types'

export type SearchGroupsByPathService = (
  searchString: string,
) => Promise<StudioGroupSearch[]>

export const useSearchGroupsByPath = (): SearchGroupsByPathService => {
  const client = useApolloClient()

  return useCallback(
    async searchString => {
      const { data, error } = await client.query<
        SearchGroupsByPathSubstringQuery,
        SearchGroupsByPathSubstringQueryVariables
      >({
        query: SEARCH_GROUPS_BY_PATH_SUBSTRING,
        variables: {
          path: searchString,
        },
      })

      if (error) {
        throw new Error(error.message)
      }

      if (data?.studioGroupsByPathSubstring === null) {
        return []
      }

      if (data?.studioGroupsByPathSubstring) {
        return data.studioGroupsByPathSubstring as StudioGroupSearch[]
      }

      return []
    },
    [client],
  )
}
