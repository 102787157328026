import React, {
  ButtonHTMLAttributes,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Draggable } from '@hello-pangea/dnd'
import { Flex, Text } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
  IconSystemImage,
  IconSystemImageUpload,
  IconSystemTextField,
} from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { StyledIconBase } from '@styled-icons/styled-icon'
import {
  DragIndicator as DragIndicatorIcon,
  Link as LinkIcon,
  Title as TextFormatIcon,
} from '@styled-icons/material'
import {
  Category as ShapesIcon,
  Photo as PhotoIcon,
} from '@styled-icons/material-outlined'
import {
  StudioElementWithId,
  StudioImageElement,
  StudioRectangleElement,
  StudioTextElement,
} from '../../types'
import { useMachine } from '../../state/xstate'
import {
  getImageBlobFromUrl,
  isImageElement,
  isPlaceholderTextPart,
  isRectangleElement,
  isTextElement,
  loadImageFromBlob,
  studioImageAssetFromFile,
} from '../../utils'
import {
  DEFAULT_DRAGGABLE_TEXT_TITLE,
  LAYER_IMAGE_ACCEPTED_EXTENSIONS,
  SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES,
} from '../../constants'
import { useElementData, useTemplateElementsCollection } from '../../data'
import { ElementError } from './ElementError'
import { TextElementPartControls } from './TextElementPartControls'
import { IconSystemPlaceholderTextField } from './img/IconSystemPlaceholderTextField'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuContainer,
} from '../Dropdown'
import { FileUploadInput } from './ElementActions/Inputs/FileUploadInput'
import { IMAGE_UPLOAD_FAILED_ACCEPT_MESSAGE } from '../../strings'
import { useErrorMessages } from '../../hooks'

export const IconStyleWrapper = styled.div`
  ${StyledIconBase} {
    color: ${colorValue('colorBlack100')};
    flex-shrink: 0;
  }
`

const TextTruncate = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledPillButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  font-family: Moonpig;
  font-weight: 300;
  font-size: 16px;
`

const StyledListItem = styled.div<{
  isSelected: boolean
  isDragging: boolean
  hasError: boolean | undefined
}>`
  width: 100%;
  padding: 4px;
  border: 2px solid #e2e3e6;
  background-color: white;

  &:hover {
    background-color:  ${colorValue('colorBackground06')}
    border-color: ${colorValue('colorBlack20')};
  }

  &:focus {
    border-color: ${colorValue('colorBorder01')};
  }

  ${({ isSelected, hasError, isDragging, theme: { colors } }) =>
    (isSelected && !hasError) || (isDragging && !hasError)
      ? `
      &, &:hover {
          border-color: ${colors.colorBorder01}; 
          background-color: ${colors.colorBackground03};
        }
      `
      : undefined}

  ${({ isSelected, theme: { colors } }) =>
    !isSelected &&
    `
    &:focus-within { 
      border-color: ${colors.colorBorder01};
    }
  `};

  ${({ isDragging }) => s({ boxShadow: isDragging ? 2 : 0 })}
  ${({ hasError, isSelected, theme: { colors } }) =>
    hasError &&
    `
    border-width: ${isSelected ? '4px' : '2px'};
    
    &, &:focus-within {
      border-color: ${colors.colorFeedbackError};
    }

    &, &:hover { 
      border-color: ${colors.colorFeedbackError}; 
      background-color: ${colors.colorBackgroundError};
    }
    `}
`

const PillButton: FC<ButtonHTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledPillButton {...rest}>{children}</StyledPillButton>
    </div>
  )
}

const ImageElement: FC<{
  elementId: string
  element: StudioImageElement
}> = ({ elementId, element }) => {
  const { send } = useMachine()

  const description = element.editable
    ? `Select Editable Image ${element.name}`
    : `Select Image ${element.name}`

  return (
    <Flex flexDirection="column" flex="1">
      <PillButton
        aria-label={description}
        title={description}
        data-testid={`selection-element-${elementId}`}
        onClick={(event: MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          send({
            type: 'SELECT_ELEMENT',
            id: elementId,
          })
        }}
      >
        {element.editable ? (
          <IconSystemImageUpload
            height={'22px'}
            width={'22px'}
            aria-label={`Editable Image ${element.name}`}
          />
        ) : (
          <IconSystemImage
            height={'22px'}
            width={'22px'}
            aria-label={`Static Image ${element.name}`}
          />
        )}
        <TextTruncate
          width="200px"
          ml={2}
          typography="typeBodyText"
          textAlign="left"
        >
          {element.name}
        </TextTruncate>
      </PillButton>

      {element.mask && (
        <PillButton
          aria-label={`Select ${element.name}`}
          title={`Select Image`}
          onClick={() => {
            send({
              type: 'SELECT_ELEMENT',
              id: elementId,
            })
          }}
        >
          <span>
            <LinkIcon size="24px" />
            <PhotoIcon size="24px" />
          </span>
          <Text ml={2} typography="body" textAlign="left">
            Mask
          </Text>
        </PillButton>
      )}
    </Flex>
  )
}

const isEditable = (element: StudioTextElement): boolean => {
  return element.text.__typename !== 'StudioStaticTextPart'
}

const textTypeDescriptions = {
  StudioStaticTextPart: 'Static Text',
  StudioPlainTextPart: 'Plain Text',
  StudioStyledTextPart: 'Styled Text',
  StudioPlaceholderTextPart: 'Placeholder Text',
}

const TextElement: FC<{
  elementId: string
  element: StudioTextElement
}> = ({ elementId, element }) => {
  const { send } = useMachine()

  const [textTypeDescription, setTextTypeDescription] =
    useState<string>('Plain Text')

  useEffect(() => {
    const description = textTypeDescriptions[element.text.__typename]
    setTextTypeDescription(description)
  }, [element.text.__typename])

  return (
    <Flex flexDirection="column" flex="1">
      <Flex flex={1}>
        <PillButton
          title={`Select ${textTypeDescription}`}
          data-testid={`selection-element-${elementId}`}
          onClick={(event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()

            send({
              type: 'SELECT_ELEMENT',
              id: elementId,
            })
          }}
        >
          <Flex flex={1} justifyContent="space-between" justifyItems="center">
            <Flex>
              {isEditable(element) ? (
                isPlaceholderTextPart(element.text) ? (
                  <IconSystemPlaceholderTextField
                    height={'24px'}
                    width={'24px'}
                    aria-label={textTypeDescription}
                  />
                ) : (
                  <IconSystemTextField
                    height={'24px'}
                    width={'24px'}
                    aria-label={textTypeDescription}
                  />
                )
              ) : (
                <TextFormatIcon size="24px" aria-label={textTypeDescription} />
              )}
              <TextTruncate
                width="200px"
                ml={2}
                typography="typeBodyText"
                textAlign="left"
              >
                {element.name || DEFAULT_DRAGGABLE_TEXT_TITLE}
              </TextTruncate>
            </Flex>
          </Flex>
        </PillButton>
      </Flex>
      <TextElementPartControls
        element={element}
        elementId={elementId}
      ></TextElementPartControls>
    </Flex>
  )
}

const RectangleElement: FC<{
  elementId: string
  element: StudioRectangleElement
}> = ({ elementId, element }) => {
  const { send } = useMachine()
  return (
    <PillButton
      title="Select Rectangle"
      onClick={(event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()

        send({
          type: 'SELECT_ELEMENT',
          id: elementId,
        })
      }}
    >
      <ShapesIcon size="24px" />
      <TextTruncate ml={2} typography="typeBodyText" textAlign="left">
        {element.name}
      </TextTruncate>
    </PillButton>
  )
}

export const ElementItem: FC<{
  draggableIndex: number
  element: StudioElementWithId
}> = ({ draggableIndex, element }) => {
  const { state } = useMachine()
  const triggerRef = React.createRef<HTMLButtonElement>()

  const selectedElementId = state.context.selectedElementId
  const isSelected = selectedElementId === element.id

  const { removeElementById } = useTemplateElementsCollection()

  const deleteElement = useCallback(() => {
    if (isSelected) {
      removeElementById(element.id)
    }
  }, [removeElementById, element.id, isSelected])

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (['Delete', 'Backspace'].includes(e.key)) {
      deleteElement()
    }
  }

  return (
    <DropdownMenuContainer triggerRef={triggerRef} onKeyDown={handleKeyDown}>
      <Draggable
        draggableId={`${element.id}-${element.name}`}
        index={draggableIndex}
      >
        {(provided, dragSnapshot) => (
          <IconStyleWrapper>
            <Flex
              flexDirection="column"
              data-testid={`draggable-element-${draggableIndex}`}
              width={1}
              pt={3}
              pb={3}
              {...{ style: { userSelect: 'none' } }}
            >
              <>
                <StyledListItem
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={provided.draggableProps.style}
                  isSelected={isSelected}
                  isDragging={dragSnapshot.isDragging}
                  className={isSelected ? 'is-selected' : undefined}
                  hasError={element.hasError}
                >
                  <Flex
                    justifyContent="space-between"
                    style={{ position: 'relative' }}
                  >
                    <Flex
                      {...provided.dragHandleProps}
                      title="Reorder"
                      marginTop={4}
                    >
                      <DragIndicatorIcon size="24px" />
                    </Flex>
                    <Flex
                      flexGrow="1"
                      justifyItems="center"
                      justifyContent="space-between"
                      maxWidth="calc(100% - 24px)"
                    >
                      {isImageElement(element) && (
                        <ImageElement
                          elementId={element.id}
                          element={element}
                        />
                      )}
                      {isTextElement(element) && (
                        <TextElement elementId={element.id} element={element} />
                      )}
                      {isRectangleElement(element) && (
                        <RectangleElement
                          elementId={element.id}
                          element={element}
                        />
                      )}
                      <Flex marginTop={4} marginRight={4}>
                        <LayerElementContextMenu
                          element={element}
                          triggerRef={triggerRef}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </StyledListItem>
                {element.hasError && <ElementError element={element} />}
              </>
            </Flex>
          </IconStyleWrapper>
        )}
      </Draggable>
    </DropdownMenuContainer>
  )
}

type LayoutElementContextMenu = {
  element: StudioElementWithId
  triggerRef?: React.RefObject<HTMLButtonElement>
}

const StyledDropdownMenuTrigger = styled(Flex)`
  width: 20px;
  height: 20px;
`

const LayerElementContextMenu: React.FC<LayoutElementContextMenu> = ({
  element,
  triggerRef,
}) => {
  const swapImageInputRef = useRef<HTMLInputElement>(null)
  const addMaskInputRef = useRef<HTMLInputElement>(null)

  const { updateElement } = useElementData(element.id)
  const { send } = useMachine()
  const { removeElementById } = useTemplateElementsCollection()
  const { showImageSizeError } = useErrorMessages()

  const handleDeleteAction = () => {
    send({ type: 'DELETE_CONFIRM' })
    removeElementById(element.id)
  }

  const handleRemoveMaskAction = () => {
    updateElement({
      mask: null,
    })
  }

  const handleDownloadAction = async (type: 'image' | 'mask') => {
    if (element.__typename === 'StudioImageElement') {
      const url = type === 'image' ? element.image.url : element?.mask?.url

      if (!url) {
        return
      }

      const imageData = await getImageBlobFromUrl(url)
      const image = await loadImageFromBlob(imageData)
      const extension = image.mimeType.split('/')[1] ?? 'unknown'

      const anchor = document.createElement('a')
      anchor.href = image.url
      anchor.download = `${element.name}-${type}.${extension}`
      anchor.style.display = 'none'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    }
  }

  const handleSwapImageAction = () => {
    if (swapImageInputRef.current) {
      swapImageInputRef.current.click()
    }
  }

  const handleAddMaskAction = () => {
    if (addMaskInputRef.current) {
      addMaskInputRef.current.click()
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        right: 10,
        top: 5,
      }}
    >
      <FileUploadInput
        ref={swapImageInputRef}
        failAcceptMessage={IMAGE_UPLOAD_FAILED_ACCEPT_MESSAGE}
        id={`std-swap-image-for-${element.id}`}
        accept={LAYER_IMAGE_ACCEPTED_EXTENSIONS.join(',')}
        onChange={async fileList => {
          if (fileList && fileList.length > 0) {
            const file = Array.from(fileList!)[0]
            const image = await studioImageAssetFromFile(file)
            if (file.size > SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES) {
              showImageSizeError()
              return
            }
            updateElement({
              image,
            })
          }
        }}
      />

      <FileUploadInput
        ref={addMaskInputRef}
        failAcceptMessage={IMAGE_UPLOAD_FAILED_ACCEPT_MESSAGE}
        id={`std-swap-mask-for-${element.id}`}
        accept={LAYER_IMAGE_ACCEPTED_EXTENSIONS.join(',')}
        onChange={async fileList => {
          if (fileList && fileList.length > 0) {
            const file = Array.from(fileList!)[0]
            const mask = await studioImageAssetFromFile(file)
            if (file.size > SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES) {
              showImageSizeError()
              return
            }
            updateElement({
              mask,
            })
          }
        }}
      />

      <DropdownMenu
        hiddenByDefault
        data-testid={`layer-element-${element.id}`}
        triggerRef={triggerRef}
        trigger={({ isExpanded }) => (
          <StyledDropdownMenuTrigger>
            {isExpanded ? (
              <IconSystemChevronUp width={'32px'} height={'32px'} />
            ) : (
              <IconSystemChevronDown width={'32px'} height={'32px'} />
            )}
          </StyledDropdownMenuTrigger>
        )}
      >
        {element.__typename === 'StudioImageElement' && (
          <>
            {element.image != null && (
              <>
                <DropdownMenuItem
                  onSelect={() => handleDownloadAction('image')}
                >
                  Download Image
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleSwapImageAction()}>
                  Swap Image
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}

            {element.mask !== null && (
              <>
                <DropdownMenuItem onSelect={() => handleDownloadAction('mask')}>
                  Download Mask
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => handleRemoveMaskAction()}>
                  Remove Mask
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}

            {element.mask == null && (
              <>
                <DropdownMenuItem onSelect={() => handleAddMaskAction()}>
                  Add Mask
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}
          </>
        )}
        <DropdownMenuItem onSelect={() => handleDeleteAction()}>
          Delete
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  )
}
