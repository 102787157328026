import polly from 'polly-js'
import { StudioGroupTemplate } from '../../types'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { RENAME_TEMPLATE } from '../../mutations'
import { fetchTemplate } from '../FetchTemplate'
import {
  RenameTemplateMutation,
  RenameTemplateMutationVariables,
} from '../../__graphql__/mutations'
import { StudioRenameTemplateResult } from '../../__graphql__/types'

export type RenameTemplateService = (
  node: StudioGroupTemplate,
  name: string,
) => Promise<StudioRenameTemplateResult>

const shouldNotRetryErrors = [
  'UnableToFetchTemplate',
  'StudioDuplicateNameError',
  'StudioInvalidNameError',
  'StudioRenameTemplateError',
  'StudioVersionMismatchError',
  'StudioDuplicatePathError',
]

const shouldRetry = (err: Error) => {
  return !shouldNotRetryErrors.includes(err.message)
}

const renameTemplate = async (
  client: ApolloClient<object>,
  node: StudioGroupTemplate,
  name: string,
) => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      let template = undefined

      try {
        template = await fetchTemplate(client, node.id)
      } catch (caught) {
        throw new Error('UnableToFetchTemplate')
      }

      const { data } = await client.mutate<
        RenameTemplateMutation,
        RenameTemplateMutationVariables
      >({
        errorPolicy: 'all',
        mutation: RENAME_TEMPLATE,
        variables: {
          input: {
            template: {
              id: node.id,
              name,
              version: template.version,
            },
          },
        },
      })

      if (data?.renameTemplate.__typename === 'StudioRenamedTemplate') {
        return data.renameTemplate
      }

      throw new Error(data?.renameTemplate.__typename)
    })
}

export const useRenameTemplate = (): RenameTemplateService => {
  const client = useApolloClient()

  return async (node: StudioGroupTemplate, name: string) =>
    renameTemplate(client, node, name)
}
