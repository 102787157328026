import {
  Alert,
  Box,
  Flex,
  Heading,
  Modal,
  PrimaryButton,
  TertiaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { useMachine } from '@xstate/react'
import React, { FC, useMemo } from 'react'
import { CrossIconButton } from '../../Buttons'
import { createDeleteModalMachine } from '../../../machines/delete-modal-machine'
import { useDeleteNode } from '../../../services/DeleteNode'
import { StudioNode } from '../../../types'
import { useAddNotification } from '../../Notifications'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import { StyledHeading } from '../components/Text'
import { deletedRecentlyOpened } from '../../TemplateExplorer/lib'

type DeleteDialogProps = {
  node: StudioNode
  onDeleted: () => void
  onClose: () => void
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  node,
  onClose,
  onDeleted,
}) => {
  const addNotification = useAddNotification()
  const deleteNode = useDeleteNode()
  const machine = useMemo(() => createDeleteModalMachine({ node }), [node])

  const [state, send] = useMachine(machine, {
    actions: {
      notifySuccess: context => {
        const type =
          context.node.__typename === 'StudioGroupTemplate'
            ? 'Template'
            : 'Folder'

        addNotification({
          id: 'DELETING',
          text: `${type} deleted successfully`,
          variant: 'success',
        })

        onDeleted()
      },
      notifyError: context => {
        if (
          context.error?.name &&
          ['StudioDeleteNonEmptyGroupError'].includes(context.error.name)
        ) {
          onClose()
          addNotification({
            id: 'DELETING',
            heading: 'Delete failed',
            text: context.error.message,
            variant: 'error',
          })
        }

        if (context.error?.name === 'StudioTemplateIsMappedError') {
          onClose()

          const products = context.error?.products

          const message = (
            <React.Fragment>
              <p>
                This template is in use in {products.length} product
                {products.length > 1 && 's'}. Unpublish the product
                {products.length > 1 && 's'} and delete this template from the
                mapping{products.length > 1 && 's'}.
              </p>
              {products.length < 4 && (
                <ul>
                  {products.map((productKey, i) => (
                    <li key={`${i}-${productKey}`}>{productKey}</li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          )

          addNotification({
            id: 'DELETING',
            heading: 'Delete failed',
            text: message,
            variant: 'error',
          })
        }
      },
    },
    services: {
      delete: async context => {
        await deleteNode(context.node)

        if (context.node.__typename === 'StudioGroupTemplate') {
          deletedRecentlyOpened(context.node.id)
        }

        return
      },
    },
  })

  const handleDelete = () => {
    send({ type: 'DELETE_CONFIRMED' })
  }

  const handleCancel = () => {
    onClose()
  }

  const nodeType = useMemo(() => {
    switch (node.__typename) {
      case 'StudioGroup':
        return 'folder'
      case 'StudioGroupTemplate':
        return 'template'
    }
  }, [node])

  const shouldShowError =
    state.context.error?.name === 'StudioDeleteTemplateError' ||
    state.context.error?.name === 'StudioDeleteGroupError'

  return (
    <>
      <OverrideReachModalPadding />
      <Modal
        testId={`delete-${nodeType}-dialog`}
        label={`delete-${nodeType}-dialog`}
        isOpen={state.context.node !== undefined}
      >
        <Flex
          width="500px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <StyledHeading width={'100%'} level="h2" fontWeight={400}>
            Delete {nodeType}
            <CrossIconButton
              data-testid="context-menu-close-icon"
              onClick={handleCancel}
            />
          </StyledHeading>
          {shouldShowError && (
            <Box paddingX={6} paddingTop={6}>
              <Alert variant="error">
                <Heading level="h4" mb={0}>
                  Oops!
                </Heading>
                <span>{state.context.error?.message}</span>
              </Alert>
            </Box>
          )}
          <Box paddingX={6} paddingY={6}>
            <Flex flexDirection="column">
              <Text typography={'body'} mb={7}>
                You're about to delete {nodeType}: {state.context.node.name}
              </Text>
              <Text typography={'body'}>
                This can't be undone. Are you sure you want to proceed?
              </Text>
            </Flex>
            <Flex marginTop={7} width="100%" justifyContent="space-between">
              <TertiaryButton
                width="100%"
                marginRight="6px"
                onClick={handleCancel}
              >
                Cancel
              </TertiaryButton>
              <PrimaryButton
                loading={state.matches('confirmed')}
                width="100%"
                marginLeft="6px"
                onClick={handleDelete}
              >
                Delete {nodeType}
              </PrimaryButton>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}
