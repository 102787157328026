import { StudioAssetStatus } from '../__graphql__/types'
import { UNSET_ASSET_KEY } from '../constants'
import { StudioElement } from '../types'
import { getImageBlobFromUrl } from './image'

const base64Image =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8A9CgoRwTkAYxPdxhtAAAAAElFTkSuQmCC'

// Checks all elements in a template.
//
// Images are fetched, if successful,
// they're saved to a local object URI.
// If failed, they're replaced
// with a Base64 red square
// and the error property is added.
//
// Fonts are checked if they're the fallback type,
// if so the error property is added.
export async function validateElements(
  elements: StudioElement[],
  elementsWithValidationErrors: number[] = [],
) {
  const createAssetFromBlob = (
    blob: Blob,
    status: StudioAssetStatus,
    key: string,
  ) => {
    return {
      __typename: 'StudioAsset' as const,
      url: URL.createObjectURL(blob),
      status: status,
      key: key,
      contentType: blob.type,
    }
  }
  const validatedElements = await Promise.all(
    elements.map(async (element, index) => {
      if (element.__typename === 'StudioImageElement') {
        let maskBlob = null
        let imageBlob = null
        try {
          imageBlob = await getImageBlobFromUrl(element.image.url, true)

          if (element.mask) {
            maskBlob = await getImageBlobFromUrl(element.mask.url, true)
          }

          return elementsWithValidationErrors.includes(index)
            ? Promise.resolve({
                ...element,
                image: createAssetFromBlob(
                  imageBlob,
                  element.image.status,
                  element.image.key,
                ),
                mask: maskBlob
                  ? createAssetFromBlob(
                      maskBlob,
                      element.mask!.status,
                      element.mask!.key,
                    )
                  : null,
                hasError: true,
              })
            : Promise.resolve({
                ...element,
                image: createAssetFromBlob(
                  imageBlob,
                  element.image.status,
                  element.image.key,
                ),
                mask: maskBlob
                  ? createAssetFromBlob(
                      maskBlob,
                      element.mask!.status,
                      element.mask!.key,
                    )
                  : null,
              })
        } catch (err) {
          console.warn(
            `Error validating Image at URL ${element.image.url}: \n`,
            err,
          )
          const errorAsset = {
            __typename: 'StudioAsset' as const,
            url: base64Image,
            status: StudioAssetStatus.FAILED,
            key: UNSET_ASSET_KEY,
            contentType: 'image/png',
          }

          return Promise.resolve({
            ...element,
            image: imageBlob
              ? createAssetFromBlob(
                  imageBlob,
                  element.image.status,
                  element.image.key,
                )
              : errorAsset,
            mask: element.mask
              ? maskBlob
                ? createAssetFromBlob(
                    maskBlob,
                    element.mask.status,
                    element.mask.key,
                  )
                : errorAsset
              : null,
            hasError: true,
          })
        }
      }

      if (element.__typename === 'StudioTextElement') {
        return element.font.__typename === 'StudioFallbackFont'
          ? Promise.resolve({
              ...element,
              hasError: true,
            })
          : elementsWithValidationErrors.includes(index)
          ? Promise.resolve({
              ...element,
              hasError: true,
            })
          : Promise.resolve({
              ...element,
              hasError: false,
            })
      }

      return Promise.resolve(element)
    }),
  )
  return validatedElements
}
