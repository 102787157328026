import {
  StudioElement,
  StudioImageElement,
  StudioPlaceholderTextPart,
  StudioPlainTextPart,
  StudioRectangleElement,
  StudioStaticTextPart,
  StudioStyledTextPart,
  StudioTextElement,
  StudioTextParts,
} from '../types'

export { rotateAboutCenter, rotate, scale, translate } from './matrix'
export { useTheme } from './useTheme'
export * from './image'
export type { Matrix } from './matrix'
export * from './validateElements'

export const isTextElement = (
  element?: Partial<StudioElement> | null,
): element is StudioTextElement => {
  return Boolean(element) && element!.__typename === 'StudioTextElement'
}

export const isImageElement = (
  element?: Partial<StudioElement> | null,
): element is StudioImageElement => {
  return Boolean(element) && element!.__typename === 'StudioImageElement'
}

export const isRectangleElement = (
  element?: Partial<StudioElement> | null,
): element is StudioRectangleElement => {
  return Boolean(element) && element!.__typename === 'StudioRectangleElement'
}

export const isStaticTextPart = (
  textPart?: Partial<StudioTextParts> | null,
): textPart is StudioStaticTextPart => {
  return Boolean(textPart) && textPart!.__typename === 'StudioStaticTextPart'
}

export const isStyledTextPart = (
  textPart?: Partial<StudioTextParts> | null,
): textPart is StudioStyledTextPart => {
  return Boolean(textPart) && textPart!.__typename === 'StudioStyledTextPart'
}

export const isPlainTextPart = (
  textPart?: Partial<StudioTextParts> | null,
): textPart is StudioPlainTextPart => {
  return Boolean(textPart) && textPart!.__typename === 'StudioPlainTextPart'
}

export const isPlaceholderTextPart = (
  textPart?: Partial<StudioTextParts> | null,
): textPart is StudioPlaceholderTextPart => {
  return (
    Boolean(textPart) && textPart!.__typename === 'StudioPlaceholderTextPart'
  )
}

export const clamp = (value: number, min: number, max: number): number =>
  Math.min(max, Math.max(min, value))
