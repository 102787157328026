import React, { FC, useState } from 'react'
import {
  Box,
  Flex,
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { useMachine } from '@xstate/react'
import { StudioTextInput } from '../../StudioTextInput'
import { StyledHeading } from '../components/Text'
import { CrossIconButton } from '../../Buttons'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import {
  createDuplicateModalMachine,
  DuplicateDoneEvent,
} from '../../../machines/duplicate-modal-machine'
import { useAddNotification } from '../../Notifications'
import { useDuplicateTemplate } from '../../../services/DuplicateTemplate'

type SaveAsDialogProps = {
  template: { id: string; name: string; parentPath: string }
  onDuplicated: (path: string) => void
  onClose: () => void
}

export const SaveAsDialog: FC<SaveAsDialogProps> = ({
  template,
  onDuplicated,
  onClose,
}) => {
  const duplicateTemplateService = useDuplicateTemplate()
  const addNotification = useAddNotification()

  const [inputValue, setInputValue] = useState(() => `${template.name}_copy`)

  const machine = React.useMemo(
    () =>
      createDuplicateModalMachine({
        id: template.id,
        parentPath: template.parentPath,
      }),
    [template],
  )

  const [, send] = useMachine(machine, {
    actions: {
      notifySuccess: (_, event) => {
        addNotification({
          id: 'DUPLICATING',
          text: `New template was saved successfully`,
          variant: 'success',
        })

        const path = (event as DuplicateDoneEvent).data.path
          .split('>')
          .join('/')

        onDuplicated(path)
      },
      notifyError: context => {
        addNotification({
          id: 'DUPLICATING',
          text: context.error?.message,
          variant: 'error',
        })

        onClose()
      },
    },
    services: {
      duplicate: async context => {
        return await duplicateTemplateService(
          context.id,
          inputValue,
          context.parentPath,
        )
      },
    },
  })

  const onDuplicate = () => {
    send({ type: 'DUPLICATE_CONFIRMED', name: inputValue })
  }

  return (
    <>
      <OverrideReachModalPadding />
      <Modal label="Save as" isOpen={true} testId={`duplicate-template-dialog`}>
        <Flex
          width="400px"
          flexDirection="column"
          justifyContent="space-between"
          as="form"
          onSubmit={e => {
            e.preventDefault()

            onDuplicate()
          }}
        >
          <StyledHeading
            level="h2"
            marginBottom="24px"
            textAlign="center"
            fontWeight={400}
          >
            Save as
            <CrossIconButton
              data-test-id="save-as-modal-close-icon"
              onClick={onClose}
            />
          </StyledHeading>

          <Box padding="16px">
            <StudioTextInput
              label="Name"
              name="name"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </Box>

          <Flex width="100%" padding="16px">
            <TertiaryButton
              onClick={onClose}
              width="100%"
              marginRight="12px"
              type="button"
            >
              Cancel
            </TertiaryButton>
            <PrimaryButton width="100%" type="submit">
              Save
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
