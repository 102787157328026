import { FC, useCallback, useEffect, useMemo } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Flex } from '@moonpig/launchpad-components'
import { Overlay } from '../Dialogs/Overlay'
import { NewFolderDialog } from '../Dialogs'
import { useMachine } from '../../state/xstate'
import { StudioGroup, StudioNode } from '../../types'
import { Content, Header, SideNav } from './components'
import { useSearch } from './hooks'
import { useTemplateExplorer } from './TemplateExplorerProvider'
import { getRecentlyOpenedGroup } from './lib'
import { RenameDialog } from '../Dialogs/Rename/Rename'
import { MoveDialog } from '../Dialogs/Move/Move'
import { pathMatches, pathToURI } from '../../utils/path'
import { DeleteDialog } from '../Dialogs/Delete/Delete'
import { Loading } from './components/Content/Loading'
import { RecentlyOpened } from './components/Content/RecentlyOpened'
import { useNavigate } from 'react-router-dom'
import { colorValue } from '@moonpig/launchpad-theme'

const StyledDialog = styled.div`
  width: 100%;
  height: 100%;
  max-width: calc(100% - 64px);
  max-height: calc(100% - 64px);
  overflow: hidden;
  display: flex;
  background-color: ${colorValue('colorBackground01')};
`

export const TemplateExplorer: FC<{
  show: boolean
  onSearch: (searchString: string) => Promise<StudioGroup>
  logicalPath: string
}> = ({ show, onSearch, logicalPath }) => {
  const navigate = useNavigate()
  const { state, send } = useMachine()
  const templateExplorer = useTemplateExplorer()
  const recentlyOpenedGroup = getRecentlyOpenedGroup()
  const {
    search,
    clear: clearSearch,
    searching: isSearching,
  } = useSearch(onSearch)

  const onNodeSelect = (node: StudioNode, newWindow: boolean) => {
    if (node.__typename === 'StudioGroup') {
      if (newWindow) {
        window.open(
          `${node.path ? '/' : ''}${pathToURI(node.path)}${
            window.location.search
          }`,
          '_blank',
        )
      } else {
        navigate({
          pathname: `${node.path ? '/' : ''}${pathToURI(node.path)}`,
          search: window.location.search,
        })

        node && send({ type: 'SELECT_GROUP', path: node.path })
      }
    } else if (node.__typename === 'StudioGroupTemplate') {
      const parentURI = pathToURI(
        node.parentPath ? node.parentPath : state.context.selectedPath || '',
      )

      const nodePath = `${parentURI ? '/' : ''}${parentURI}/${node.name}`

      if (newWindow) {
        window.open(nodePath, '_blank')
      } else {
        parentURI &&
          navigate({
            pathname: nodePath,
            search: window.location.search,
          })

        node && send({ type: 'SELECT_TEMPLATE', id: node.id })
      }
    }
  }

  const setRecentlyOpened = useCallback(() => {
    templateExplorer.dispatch({
      type: 'SET_CURRENT_GROUP',
      payload: { group: recentlyOpenedGroup },
    })
  }, [recentlyOpenedGroup, templateExplorer])

  useEffect(() => {
    if (
      logicalPath &&
      !pathMatches(logicalPath, templateExplorer.state.currentGroup.path) &&
      state.matches('TEMPLATE_EXPLORER')
    ) {
      if (logicalPath === 'recently-opened') {
        setRecentlyOpened()
      } else {
        send({ type: 'SELECT_GROUP', path: logicalPath })
      }
    }
  }, [
    state,
    send,
    logicalPath,
    setRecentlyOpened,
    templateExplorer.state.currentGroup,
  ])

  const onActionableComplete = () => {
    templateExplorer.dispatch({ type: 'CLEAR_ACTIONABLE_NODE' })

    send({ type: 'SELECT_GROUP', path: logicalPath })
  }

  const isLoadingGroup = state.matches({ TEMPLATE_EXPLORER: 'LOADING_GROUP' })
  const isRecentlyOpenedGroup =
    templateExplorer.state.currentGroup.id === 'recently-opened'

  const actions = useMemo(() => {
    const createNewFolder = !isRecentlyOpenedGroup && !isSearching
    const uploadTemplate = !isRecentlyOpenedGroup && !isSearching
    const uploadFont = true
    return {
      createNewFolder,
      uploadTemplate,
      uploadFont,
    }
  }, [isRecentlyOpenedGroup, isSearching])

  return (
    <Overlay show={show}>
      {templateExplorer.state.actionableNode?.action === 'create' && (
        <NewFolderDialog
          node={templateExplorer.state.actionableNode.node}
          onCreated={onActionableComplete}
          onClose={() =>
            templateExplorer.dispatch({ type: 'CLEAR_ACTIONABLE_NODE' })
          }
        />
      )}

      {templateExplorer.state.actionableNode?.action === 'rename' && (
        <RenameDialog
          node={templateExplorer.state.actionableNode.node}
          onRenamed={onActionableComplete}
          onClose={() =>
            templateExplorer.dispatch({ type: 'CLEAR_ACTIONABLE_NODE' })
          }
        />
      )}

      {templateExplorer.state.actionableNode?.action === 'move' && (
        <MoveDialog
          node={templateExplorer.state.actionableNode.node}
          onMoved={onActionableComplete}
          onClose={() =>
            templateExplorer.dispatch({ type: 'CLEAR_ACTIONABLE_NODE' })
          }
        />
      )}

      {templateExplorer.state.actionableNode?.action === 'delete' && (
        <DeleteDialog
          node={templateExplorer.state.actionableNode.node}
          onDeleted={onActionableComplete}
          onClose={() =>
            templateExplorer.dispatch({ type: 'CLEAR_ACTIONABLE_NODE' })
          }
        />
      )}

      <StyledDialog>
        <SideNav
          onGroupSelect={onNodeSelect}
          recentlyOpenedGroup={recentlyOpenedGroup}
          onMoved={onActionableComplete}
        />
        <Flex width="100%" height="100%" flexDirection="column">
          <Header
            actions={actions}
            onGroupSelect={onNodeSelect}
            search={{
              onChange: async searchString => {
                if (searchString.length === 0) {
                  clearSearch()
                  templateExplorer.dispatch({
                    type: 'SET_CURRENT_GROUP',
                    payload: { group: recentlyOpenedGroup },
                  })
                }
              },
              onEnterDown: async searchString => {
                if (searchString.length > 0) {
                  navigate({
                    pathname: '/',
                    search: window.location.search,
                  })
                  await search(searchString)
                }
              },
            }}
            onMoved={onActionableComplete}
          />

          {(isSearching ||
            isLoadingGroup ||
            templateExplorer.state.isMovingTemplate) && <Loading />}

          {!isLoadingGroup &&
            !isSearching &&
            !templateExplorer.state.isMovingTemplate &&
            (isRecentlyOpenedGroup ? (
              <RecentlyOpened
                group={recentlyOpenedGroup}
                setSelectedGroup={onNodeSelect}
                onTemplateSelect={onNodeSelect}
                onMoved={onActionableComplete}
              />
            ) : (
              <Content
                group={templateExplorer.state.currentGroup}
                setSelectedGroup={onNodeSelect}
                onTemplateSelect={onNodeSelect}
                onMoved={onActionableComplete}
              />
            ))}
        </Flex>
      </StyledDialog>
    </Overlay>
  )
}
