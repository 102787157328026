import { Scene } from '@moonpig/renderer-scene-types'

export const TransformScene = (scene: Scene, zoom: number) => {
  const sceneOut: Scene = { ...scene }
  sceneOut.height *= zoom
  sceneOut.width *= zoom
  for (const sceneObject of sceneOut.objects) {
    if (sceneObject.type === 'TEXT') {
      sceneObject.lineSpacing *= zoom
      sceneObject.maxFontSize *= zoom
    }
    const { frame } = sceneObject
    frame.width *= zoom
    frame.height *= zoom
    frame.x *= zoom
    frame.y *= zoom
  }
  return sceneOut
}
