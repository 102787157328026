import { Flex, Text } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'
import { FC, PropsWithChildren } from 'react'
import { Document as OutlineDocumentIcon } from '@styled-icons/fluentui-system-regular'

const PreviewMissingContainer = styled(Flex)`
  border: 2px solid ${colorValue('colorBlack40')};
`

const TemplateImage = styled.img<{ isDisabled?: boolean }>`
  border: 2px solid ${colorValue('colorBlack40')};
  background-color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.colorBlack20 : theme.colors.colorBlack00};
`

const StyledTemplateImageContainer = styled(Flex)``

export const TemplateImportingPlaceholder: FC = () => (
  <StyledTemplateImageContainer>
    <TemplateImage
      as={Flex}
      height="93px"
      width="93px"
      justifyContent="center"
      alignItems="center"
      isDisabled={true}
    >
      <OutlineDocumentIcon height="45px" />
    </TemplateImage>
  </StyledTemplateImageContainer>
)

type TemplateButtonProps = {
  previewUrl: string
  id: string
  path: string
}

export const TemplateButton: FC<PropsWithChildren<TemplateButtonProps>> = ({
  id,
  previewUrl,
  path,
  children,
}) => {
  return (
    <StyledTemplateImageContainer>
      {previewUrl ? (
        <>
          <TemplateImage
            draggable={'false'}
            data-testid={`${id}-preview-image`}
            src={previewUrl}
            height="95px"
            loading="lazy"
            title={path}
            alt={path}
          />
          {children}
        </>
      ) : (
        <PreviewMissingContainer
          width="65px"
          height="95px"
          justifyContent="center"
          alignItems="center"
          title={path}
        >
          <Text fontSize="12px" textAlign="center">
            Preview not available
          </Text>
          {children}
        </PreviewMissingContainer>
      )}
    </StyledTemplateImageContainer>
  )
}
