import React, { FC } from 'react'
import { Flex } from '@moonpig/launchpad-components'
import { StudioNode } from '../../../../types'
import { styled } from '@moonpig/launchpad-utils'
import { AlphabetListEntries } from './AlphabetListEntries'

const StyledAlphabetList = styled.nav`
  order: 0;
  flex: 1 1 auto;
  width: 100%;
  align-self: auto;
  background: white;
  position: sticky;
  top: 0;
  padding: 8px;
`

export type AlphabetListViewProps = {
  nodes: StudioNode[]
  scrollableList: React.MutableRefObject<HTMLElement | null>
}

export const AlphabetList: FC<AlphabetListViewProps> = ({
  nodes,
  scrollableList,
}) => {
  return (
    <StyledAlphabetList>
      <Flex width={'100%'}>
        <AlphabetListEntries
          nodes={nodes}
          scrollableList={scrollableList}
        ></AlphabetListEntries>
      </Flex>
    </StyledAlphabetList>
  )
}
