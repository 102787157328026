import { gql } from '@apollo/client'

export const CREATE_TEMPLATE_IMPORT = gql`
  mutation CreateTemplateImport(
    $createdBy: String!
    $groupPath: String!
    $templateName: String!
  ) {
    createTemplateImport(
      createdBy: $createdBy
      groupPath: $groupPath
      templateName: $templateName
    ) {
      ... on StudioTemplateCreateImport {
        signedUrl
        templateId
        templateName
      }
      ... on StudioImportError {
        message
      }
    }
  }
`

export const UPLOAD_FONT = gql`
  mutation UploadFont($uploadedBy: String!, $fontName: String!) {
    uploadFont(uploadedBy: $uploadedBy, fontName: $fontName) {
      ... on StudioFontUpload {
        signedUrl
        fontName
      }
      ... on StudioFontUploadError {
        message
      }
    }
  }
`
export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: StudioCreateTemplateRequest!) {
    createTemplate(input: $input) {
      ... on StudioCreatedTemplate {
        id
        signedMaskUrls {
          elementIndex
          url
        }
        signedImageUrls {
          elementIndex
          url
        }
      }
      ... on StudioCreatedTemplateError {
        message
      }
      ... on StudioTemplateValidationErrorResult {
        errors {
          code
          elementIndex
          info {
            key
            value
          }
        }
      }
      ... on StudioDuplicateNameError {
        name
      }
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($input: StudioUpdateTemplateRequest!) {
    updateTemplate(input: $input) {
      ... on StudioUpdatedTemplate {
        id
        signedImageUrls {
          elementIndex
          url
        }
        signedMaskUrls {
          elementIndex
          url
        }
      }
      ... on StudioUpdatedTemplateError {
        message
      }
      ... on StudioTemplateValidationErrorResult {
        errors {
          code
          elementIndex
          info {
            key
            value
          }
        }
      }
    }
  }
`

export const CREATE_TEMPLATE_GROUP = gql`
  mutation CreateTemplateGroup($input: StudioCreateTemplateGroupRequest!) {
    createTemplateGroup(input: $input) {
      ... on StudioCreatedTemplateGroup {
        id
        __typename
      }
      ... on StudioDuplicatePathError {
        __typename
        path
      }
      ... on StudioCreateTemplateGroupError {
        __typename
        message
      }
    }
  }
`

export const RENAME_TEMPLATE = gql`
  mutation RenameTemplate($input: StudioRenameTemplateRequest!) {
    renameTemplate(input: $input) {
      ... on StudioRenamedTemplate {
        id
        name
        path
      }
      ... on StudioRenameTemplateError {
        message
      }
      ... on StudioDuplicateNameError {
        name
      }
      ... on StudioInvalidNameError {
        name
      }
      ... on StudioDuplicatePathError {
        path
      }
    }
  }
`

export const MOVE_TEMPLATE = gql`
  mutation MoveTemplate($input: StudioMoveTemplateRequest!) {
    moveTemplate(input: $input) {
      ... on StudioMovedTemplate {
        id
        path
      }
      ... on StudioDuplicatePathError {
        path
      }
      ... on StudioMoveTemplateError {
        message
      }
    }
  }
`

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($input: StudioDeleteTemplateRequest!) {
    deleteTemplate(input: $input) {
      ... on StudioDeletedTemplate {
        id
      }
      ... on StudioDeleteTemplateError {
        message
      }
      ... on StudioTemplateIsMappedError {
        message
        products
      }
    }
  }
`

export const DELETE_GROUP = gql`
  mutation DeleteGroup($input: StudioDeleteGroupRequest!) {
    deleteGroup(input: $input) {
      ... on StudioDeletedGroup {
        id
      }
      ... on StudioDeleteGroupError {
        message
      }
      ... on StudioDeleteNonEmptyGroupError {
        message
      }
    }
  }
`

export const RENAME_GROUP = gql`
  mutation RenameGroup($input: StudioRenameGroupInput!) {
    renameGroup(input: $input) {
      ... on StudioRenamedGroup {
        id
      }
      ... on StudioRenameGroupFailed {
        code
        message
      }
    }
  }
`

export const MOVE_GROUP = gql`
  mutation MoveGroup($input: StudioMoveGroupInput!) {
    moveGroup(input: $input) {
      ... on StudioMovedGroup {
        path
      }
      ... on StudioMoveGroupFailed {
        code
        message
      }
    }
  }
`

export const DUPLICATE_TEMPLATE = gql`
  mutation DuplicateTemplate($input: StudioDuplicateTemplateRequest!) {
    duplicateTemplate(input: $input) {
      ... on StudioDuplicatedTemplate {
        path
      }
      ... on StudioDuplicateTemplateError {
        message
      }
    }
  }
`
