import { TertiaryButton } from '@moonpig/launchpad-components'
import { useMachine } from '../../../state/xstate'

const QUERY_TEXT = `query GetTemplateQuery($id: ID!) {
  studioTemplateById(id: $id) {
    ... on StudioTemplate {
      __typename
      id
      version
      displayName
      elements {
        ... on StudioRectangleElement {
          __typename
          name
        }
        ... on StudioImageElement {
          __typename
          name
          mask {
            __typename
            key
            url
            contentType
            status
          }
          image {
            __typename
            key
            url
            contentType
            status
          }
        }
        ... on StudioTextElement {
          __typename
          name
          font {
            ... on StudioFont {
              id
              displayName
              urls {
                source
              }
            }
            ... on StudioFallbackFont {
              id
              displayName
              originalFont
              urls {
                source
              }
            }
          }
          fontSize
          color {
            name
            hex
          }
          shadow {
            color {
              name
              hex
            }
            offsetX
            offsetY
          }
          text {
            ... on StudioStaticTextPart {
              text
            }
            ... on StudioPlainTextPart {
              textTransform
              text
            }
            ... on StudioStyledTextPart {
              text
              allowBlank
              allowDefault
            }
            ... on StudioPlaceholderTextPart {
              textParts {
                ... on StudioStaticTextPart {
                  text
                }
                ... on StudioPlainTextPart {
                  textTransform
                  text
                }
              }
            }
          }
        }
      }
    }
  }
}`

export const PlaygroundLink: React.FC = () => {
  const {
    state: {
      context: { selectedTemplateId },
    },
  } = useMachine()

  const onClick = () => {
    // NB this is shortened compared to the one in the app due to
    // URL size restrictions
    const query = QUERY_TEXT
    const variables = JSON.stringify({ id: selectedTemplateId })
    const location = new URL(
      `${process.env.REACT_APP_GRAPHQL_GATEWAY_URL}/playground`,
    )
    location.searchParams.append(
      'tabs',
      JSON.stringify([
        {
          endpoint: location,
          name: 'TempalateById',
          query,
          variables,
        },
      ]),
    )

    window.open(location, '_blank', 'noreferrer')
  }

  return selectedTemplateId ? (
    <TertiaryButton margin="0 12px" onClick={onClick}>
      Query template in Apollo playground
    </TertiaryButton>
  ) : null
}
