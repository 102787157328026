import * as React from 'react'
import { SVGProps } from 'react'

export const IconSystemPlaceholderTextField = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4,1.7c-0.9,0-1.7,0.8-1.7,1.7v17.1c0,0.9,0.8,1.7,1.7,1.7h17.1  c0.9,0,1.7-0.8,1.7-1.7V3.4c0-0.9-0.8-1.7-1.7-1.7H3.4z M20.6,3.4H3.4v17.1h17.1V3.4z M16.1,10V8H7.9v2 M9.6,10V9.7h1.6v4.6h-0.4V16  h2.6v-1.7h-0.4V9.7h1.6V10"
    />
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9,19.3L19.9,19.3l0-4.8l0-9.9h-0.1h-1.6h-4.4v2c0,0.5,0.4,0.9,0.9,0.9   s0.9-0.4,0.9-0.9V6.3h2.6v11.3h-2.6v-0.2c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v2h6L19.9,19.3z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1,19.3L4.1,19.3l0-4.8l0-9.9h0.1h1.6h4.4v2c0,0.5-0.4,0.9-0.9,0.9   S8.3,7.1,8.3,6.6V6.3H5.7v11.3h2.6v-0.2c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v2h-6L4.1,19.3z"
      />
    </g>
  </svg>
)
