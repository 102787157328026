import React, { PropsWithChildren, useState } from 'react'
import { useInterpret } from '@xstate/react'
import { InterpreterFrom } from 'xstate'
import { useApolloClient } from '@apollo/client'
import { StudioUser } from '../../types'
import {
  createUploadFontsMachine,
  NotifyFontUploadedEvent,
  NotifyFontFailedEvent,
} from '../../machines/fonts/import-machine'
import { useAddNotification } from '../Notifications'

const FontUploadMachineContext = React.createContext({
  uploadFontMachineService: {} as InterpreterFrom<
    typeof createUploadFontsMachine
  >,
})

type FontUploadMachineProviderProps = {
  user: StudioUser
}

export const FontUploadMachineProvider: React.FC<
  PropsWithChildren<FontUploadMachineProviderProps>
> = ({ user, children }) => {
  const addNotification = useAddNotification()
  const client = useApolloClient()
  const [uploadFontMachine] = useState(() =>
    createUploadFontsMachine({
      uploads: [],
      client,
      user,
    }),
  )

  const uploadFontMachineService = useInterpret(uploadFontMachine, {
    devTools: true,
    actions: {
      notifySuccess: async (_, event: NotifyFontUploadedEvent) => {
        addNotification({
          id: `FONT_UPLOAD_${event.fileName}`,
          variant: 'success',
          text: `Uploaded ${event.fontName}`,
        })
      },
      notifyFailed: async (_, event: NotifyFontFailedEvent) => {
        addNotification({
          id: `FONT_UPLOAD_${event.fileName}`,
          variant: 'error',
          text: `Failed to upload ${event.fontName ?? event.fileName}`,
        })
      },
      notifyUploading: async (_, event: NotifyFontFailedEvent) => {
        addNotification({
          id: `FONT_UPLOAD_${event.fileName}`,
          variant: 'info',
          text: `Uploading ${event.fontName}`,
        })
      },
    },
  })

  return (
    <FontUploadMachineContext.Provider
      value={{
        uploadFontMachineService,
      }}
    >
      {children}
    </FontUploadMachineContext.Provider>
  )
}

export const useFontUploadMachine = () => {
  const context = React.useContext(FontUploadMachineContext)

  if (context === undefined) {
    throw new Error(
      'useFontUploadMachine must be used within a FontUploadMachineProvider',
    )
  }
  return context
}
