import { FC, useEffect, useMemo, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { RecentlyOpenedGroup, RecentlyOpenedNode } from '../../types'
import { StudioGroup, StudioNode } from '../../../../types'
import {
  StyledDialogContent,
  StyledTemplateContent,
  StyledTemplateContentContainer,
} from '../Content'
import { ListView } from '../ListView/ListView'
import { GridView } from '../GridView/GridView'
import { NoResults } from './NoResults'
import { useFetchTemplateGroupsByIds } from '../../../../services/FetchTemplateGroupsByIds'
import { Loading } from './Loading'
import { useIsMounted } from '../../../../hooks/useIsMounted'
import { getRecentlyOpenedFromStorage, sortTemplatesByDate } from '../../lib'
import {
  useTemplateExplorer,
  ViewByTypes,
} from '../../TemplateExplorerProvider'
import { Section } from '../AlphabetList/AlphabetListHelpers'

export const RecentlyOpened: FC<{
  group: RecentlyOpenedGroup
  setSelectedGroup: (group: StudioGroup, newWindow: boolean) => void
  onTemplateSelect: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}> = ({ onTemplateSelect, setSelectedGroup, onMoved }) => {
  const isMounted = useIsMounted()
  const templateExplorer = useTemplateExplorer()
  const fetchTemplateGroupsByIds = useFetchTemplateGroupsByIds()
  const [isLoading, setLoading] = useState<boolean>(true)

  const [templates, setTemplates] = useState<
    | Record<
        | 'recentlyOpenedThisWeek'
        | 'recentlyOpenedLastWeek'
        | 'recentlyOpenedLastMonth'
        | 'recentlyOpenedEverythingElse',
        RecentlyOpenedNode[]
      >
    | undefined
  >(undefined)

  useEffect(() => {
    const recentlyOpenedTemplates = getRecentlyOpenedFromStorage()

    if (recentlyOpenedTemplates.length <= 0) {
      isMounted() && setLoading(false)
      return
    }

    const ids = recentlyOpenedTemplates.map(item => item.templateId)

    fetchTemplateGroupsByIds(ids).then(response => {
      const items = response
        ?.map(item => {
          if (item === undefined) return undefined

          const index = recentlyOpenedTemplates.findIndex(
            r => r.templateId === item?.id,
          )

          return {
            ...item,
            lastOpened: recentlyOpenedTemplates[index].lastOpened,
          }
        })
        .filter(Boolean)

      if (isMounted()) {
        setTemplates(sortTemplatesByDate(items as RecentlyOpenedNode[]))
        setLoading(false)
      }
    })
  }, [fetchTemplateGroupsByIds, isMounted])

  if (isLoading) return <Loading />

  return (
    <StyledDialogContent>
      <StyledTemplateContentContainer>
        <StyledTemplateContent>
          {templates === undefined ? (
            <NoResults />
          ) : (
            <>
              {templates.recentlyOpenedThisWeek.length > 0 && (
                <RecentlyOpenedItems
                  listType={templateExplorer.state.viewBy}
                  items={templates.recentlyOpenedThisWeek}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  heading={`This Week (${templates.recentlyOpenedThisWeek.length})`}
                  onMoved={onMoved}
                />
              )}
              {templates.recentlyOpenedLastWeek.length > 0 && (
                <RecentlyOpenedItems
                  listType={templateExplorer.state.viewBy}
                  items={templates.recentlyOpenedLastWeek}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  heading={`Last Week (${templates.recentlyOpenedLastWeek.length})`}
                  onMoved={onMoved}
                />
              )}
              {templates.recentlyOpenedLastMonth.length > 0 && (
                <RecentlyOpenedItems
                  listType={templateExplorer.state.viewBy}
                  items={templates.recentlyOpenedLastMonth}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  heading={`Last Month (${templates.recentlyOpenedLastMonth.length})`}
                  onMoved={onMoved}
                />
              )}
              {templates.recentlyOpenedEverythingElse.length > 0 && (
                <RecentlyOpenedItems
                  listType={templateExplorer.state.viewBy}
                  items={templates.recentlyOpenedEverythingElse}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  heading={`Older (${templates.recentlyOpenedEverythingElse.length})`}
                  onMoved={onMoved}
                />
              )}
            </>
          )}
        </StyledTemplateContent>
      </StyledTemplateContentContainer>
    </StyledDialogContent>
  )
}

type RecentlyOpenedItemsProps = {
  items: RecentlyOpenedNode[]
  listType: ViewByTypes
  heading: React.ReactNode
  setSelectedGroup: (group: StudioGroup, newWindow: boolean) => void
  onTemplateSelect: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}

const RecentlyOpenedItems: React.FC<RecentlyOpenedItemsProps> = ({
  items,
  listType,
  heading,
  setSelectedGroup,
  onTemplateSelect,
  onMoved,
}) => {
  const sections: Section[] = useMemo(() => {
    return [
      {
        data: items.sort(
          (a, b) =>
            new Date(b.lastOpened).getTime() - new Date(a.lastOpened).getTime(),
        ),
        title: heading as string,
      },
    ]
  }, [heading, items])

  return (
    <Box>
      <>
        {listType === 'LIST' && (
          <ListView
            sections={sections}
            setSelectedGroup={setSelectedGroup}
            onTemplateSelect={onTemplateSelect}
            onMoved={onMoved}
          />
        )}
        {listType === 'GRID' && (
          <GridView
            sections={sections}
            setSelectedGroup={setSelectedGroup}
            onTemplateSelect={onTemplateSelect}
            onMoved={onMoved}
          />
        )}
      </>
    </Box>
  )
}
