import { filetypemime } from 'magic-bytes.js'

const checkAcceptString = async (
  accept: string,
  file: File,
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const fileReader = new FileReader()
    const accepts = accept.split(',')
    fileReader.onloadend = f => {
      const bytes = new Uint8Array(f.target!.result as ArrayBuffer)
      const fileTypes = filetypemime(bytes as any)
      for (const acc of accepts) {
        const entry = acc.trim()
        if (fileTypes.indexOf(entry) > -1) {
          resolve(true)
        }
      }
      resolve(false)
    }
    fileReader.onerror = e => {
      reject(e)
    }
    fileReader.readAsArrayBuffer(file)
  })
}

export { checkAcceptString }
