import { Box, Grid, Text } from '@moonpig/launchpad-components'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { FC } from 'react'
import { StudioElement, StudioImageElement } from '../../../types'

type ImageOptionsProps = {
  selectedElement: StudioImageElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const ImageOptions: FC<ImageOptionsProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  return (
    <Box paddingY={4}>
      <Text as="h4" typography="bodyBold" marginBottom={1}>
        Customisation
      </Text>
      <Grid gap={6} rowGap={4} style={{ position: 'relative' }}>
        <Box width={1 / 1}>
          <FormControlLabel
            name="editable-image"
            label="Editable"
            id="editable-image"
          >
            <Checkbox
              onChange={() => {
                if (selectedElement.editable) {
                  onUpdateElement({
                    required: false,
                    editable: !selectedElement.editable,
                  })
                } else {
                  onUpdateElement({
                    required: true,
                    editable: !selectedElement.editable,
                  })
                }
              }}
              checked={selectedElement.editable}
              value=""
            />
          </FormControlLabel>
          {selectedElement.editable && (
            <>
              <FormControlLabel
                name="edit-image-required"
                label="Edit required"
                id="edit-image-required"
              >
                <Checkbox
                  onChange={() => {
                    onUpdateElement({
                      required: !selectedElement.required,
                    })
                  }}
                  checked={selectedElement.required}
                  value=""
                />
              </FormControlLabel>
            </>
          )}
        </Box>
      </Grid>
    </Box>
  )
}
