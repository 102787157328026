import React, { FC, useCallback } from 'react'
import { StudioNode } from '../../../../types'
import { styled } from '@moonpig/launchpad-utils'
import { ALPHABET_LIST_CHARS, firstChar } from './AlphabetListHelpers'

type AlphabetListEntriesProps = {
  nodes: StudioNode[]
  scrollableList: React.MutableRefObject<HTMLElement | null>
}

const StyledAlphabetEntry = styled.div`
  flex: 1 1 auto;
  text-align: center;
`

const StyledAlphabetAnchor = styled.div<{ enabled: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 26px;
  height: 26px;
  font-size: 16px;
  padding-top: 1px;
  color: ${props => (props.enabled ? '#00204D' : '#E6E9ED')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};
  border-radius: 100px;
`

export const AlphabetListEntries: FC<AlphabetListEntriesProps> = ({
  nodes,
  scrollableList,
}) => {
  const onAlphabetEntryClicked = useCallback(
    (e: StudioNode) => {
      const charName = firstChar(e.name)
      const el = document.getElementById(`alpha_entry_${charName}`)
      if (el) {
        scrollableList.current!.scrollTop = el.offsetTop - 50
      }
    },
    [scrollableList],
  )

  const chars = React.useMemo(() => {
    const entries: { [name: string]: StudioNode[] & React.ReactNode } = {}
    nodes.forEach(node => {
      const entryChar = firstChar(node.name)
      if (!entries[entryChar]) entries[entryChar] = []
      entries[entryChar].push(node)
    })
    return ALPHABET_LIST_CHARS.map((char: string) => {
      const hasEntry: boolean = !!entries[char]
      return (
        <StyledAlphabetEntry key={`charAnchor_${char}`}>
          <StyledAlphabetAnchor
            data-testid={`charAnchor_${char}`}
            enabled={hasEntry}
            onClick={() => {
              onAlphabetEntryClicked(entries[char][0])
            }}
          >
            {char}
          </StyledAlphabetAnchor>
        </StyledAlphabetEntry>
      )
    })
  }, [nodes, onAlphabetEntryClicked])
  return <>{chars}</>
}
