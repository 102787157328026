import { Box } from '@moonpig/launchpad-components'
import { FC } from 'react'
import { StudioElement, StudioTextElement } from '../../../types'
import { PositionPanel } from '../PositionPanel'
import { FontEditing } from './FontEditing'
import { TextAlignment } from './TextAlignment'

type FormattingOptionsProps = {
  selectedElement: StudioTextElement
  selectedElementId: string
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const FormattingOptions: FC<FormattingOptionsProps> = ({
  selectedElement,
  selectedElementId,
  onUpdateElement,
}) => {
  return (
    <Box data-testid="text-formatting-options">
      <PositionPanel
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
      <FontEditing
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
        selectedElementId={selectedElementId}
      />
      <TextAlignment
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
    </Box>
  )
}
