import polly from 'polly-js'
import { StudioGroup } from '../../types'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { RENAME_GROUP } from '../../mutations'
import {
  RenameGroupMutation,
  RenameGroupMutationVariables,
} from '../../__graphql__/mutations'
import { StudioRenamedGroupResult } from '../../__graphql__/types'

export type RenameGroupService = (
  node: StudioGroup,
  name: string,
) => Promise<StudioRenamedGroupResult>

const shouldNotRetryErrors = ['StudioRenameGroupFailed']

const shouldRetry = (err: Error) => {
  return !shouldNotRetryErrors.includes(err.message)
}

const renameGroup = async (
  client: ApolloClient<object>,
  node: StudioGroup,
  name: string,
) => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      const { data } = await client.mutate<
        RenameGroupMutation,
        RenameGroupMutationVariables
      >({
        mutation: RENAME_GROUP,
        variables: {
          input: {
            groupId: node.id,
            name,
          },
        },
      })

      if (data?.renameGroup.__typename === 'StudioRenamedGroup') {
        return data.renameGroup
      }

      throw new Error(data?.renameGroup.code)
    })
}

export const useRenameGroup = (): RenameGroupService => {
  const client = useApolloClient()

  return async (node: StudioGroup, name: string) =>
    renameGroup(client, node, name)
}
