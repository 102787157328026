import { FC } from 'react'
import { Frame } from '@moonpig/renderer-scene-types'
import { rotate, toCSS } from 'transformation-matrix'
import { deg2rad } from '../../utils/matrix'
import { styled } from '@moonpig/launchpad-utils'
import { theme } from '@moonpig/launchpad-theme'

type InteractiveFrameProps = {
  selected: boolean
  frame: Frame
  onClick: () => void
  ariaLabel: string
}

const StyledInteractiveFrame = styled.button`
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  display: block;
  width: 100%;
  height: 100%;
`

export const InteractiveFrame: FC<InteractiveFrameProps> = ({
  frame,
  selected,
  onClick,
  ariaLabel,
}) => {
  const border = !selected
    ? 'rgba(206,51,86,0.4)'
    : theme.colors.colorInteractionTextLink

  const transform: string = toCSS(rotate(deg2rad(frame.rotation)))

  return (
    <StyledInteractiveFrame
      data-testid="std-interactive-frame"
      aria-label={ariaLabel}
      onClick={onClick}
      style={{
        transform,
        cursor: selected ? 'move' : 'pointer',
        border: `2px ${selected ? 'solid' : 'dotted'} ${border}`,
        pointerEvents: 'all',
      }}
    ></StyledInteractiveFrame>
  )
}
