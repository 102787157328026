import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import polly from 'polly-js'
import { MOVE_TEMPLATE } from '../../mutations'
import { fetchTemplate } from '../FetchTemplate'
import {
  MoveTemplateMutation,
  MoveTemplateMutationVariables,
} from '../../__graphql__/mutations'
import { StudioMoveTemplateResult } from '../../__graphql__/types'

export type MoveTemplateService = (
  templateId: string,
  path: string,
) => Promise<StudioMoveTemplateResult>

const shouldRetryAfterError = (err: Error) =>
  ['StudioMoveTemplateError', 'StudioMoveTemplateFetchDataError'].includes(
    err.message,
  )

export const useMoveTemplate = (): MoveTemplateService => {
  const client = useApolloClient()
  return useCallback(
    async (id, path) => {
      return polly()
        .handle(shouldRetryAfterError)
        .waitAndRetry(5)
        .executeForPromise(async () => {
          const templateData = await fetchTemplate(client, id).catch(() => {
            throw new Error('StudioMoveTemplateFetchDataError')
          })

          const { data } = await client.mutate<
            MoveTemplateMutation,
            MoveTemplateMutationVariables
          >({
            mutation: MOVE_TEMPLATE,
            variables: {
              input: {
                id,
                version: templateData.version,
                path,
              },
            },
          })

          if (data?.moveTemplate.__typename === 'StudioMovedTemplate') {
            return data.moveTemplate
          }

          throw new Error(data?.moveTemplate.__typename)
        })
    },
    [client],
  )
}
