import { StudioNode } from '../../../../types'

export const NUMBER_OF_ITEMS_ENABLING_ALPHABET = 30

const belongsInHashGroup = (initialNameCharacter: string) => {
  const charCodeAtStart = initialNameCharacter.toUpperCase().charCodeAt(0)
  return (
    !isNaN(parseInt(initialNameCharacter)) ||
    charCodeAtStart < 65 ||
    charCodeAtStart > 91
  )
}

export const ALPHABET_LIST_CHARS: string[] = ['#'].concat(
  Array.from(Array(26)).map((_e, i) => String.fromCharCode(i + 65)),
)

export const ALPHABET_LIST_CHARS_REVERSE: string[] = ['#'].concat(
  Array.from(Array(26)).map((_e, i) => String.fromCharCode(90 - i)),
)

export type Section = {
  title: string
  data: StudioNode[]
}

export const nodesToSectionsByAlphabet = (
  nodes: StudioNode[],
  sortBy: 'ASC' | 'DESC',
): Section[] => {
  const chars =
    sortBy === 'ASC' ? ALPHABET_LIST_CHARS : ALPHABET_LIST_CHARS_REVERSE

  return chars
    .map(char => {
      return {
        title: char,
        data: nodes.filter(node => firstChar(node.name) === char),
      }
    })
    .filter(value => value.data?.length > 0)
}

export const isHash = (char: string, initialNameCharacter: string): boolean => {
  return char === '#' && !belongsInHashGroup(initialNameCharacter)
}

export const firstChar = (name: string): string => {
  const initialNameCharacter = name.charAt(0).toUpperCase()
  if (initialNameCharacter) {
    if (belongsInHashGroup(initialNameCharacter)) {
      return '#'
    } else {
      return initialNameCharacter
    }
  }
  return '#'
}
