import { styled } from '@moonpig/launchpad-utils'
import { FC } from 'react'
import { FolderOpen } from 'styled-icons/material'
import { Flex } from '@moonpig/launchpad-components'

const FolderIcon = styled(({ ...props }) => <FolderOpen {...props} />)`
  color: ${({ theme }) => theme.colors.colorBlack100};
`

const StyledTemplateImageContainer = styled(Flex)`
  position: relative;
`

const StyledGroupContainer = styled(StyledTemplateImageContainer)``

export const FolderButton: FC = () => {
  return (
    <StyledGroupContainer>
      <FolderIcon size="60px" />
    </StyledGroupContainer>
  )
}
