import { IconSystemEyeClosed } from '@moonpig/launchpad-assets'
import { Flex, Text } from '@moonpig/launchpad-components'
import { styled, useTheme } from '@moonpig/launchpad-utils'

const StyledTemplateContentNoResults = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 16px 16px;
  flex: 1;
`
export const NoResults = () => {
  const theme = useTheme()

  return (
    <StyledTemplateContentNoResults>
      <Flex alignItems="center" flexDirection="column">
        <IconSystemEyeClosed color={theme.colors.colorBlack100} width="40px" />
        <Text as="p" typography="body" color="colorBlack100">
          Nothing to see here&hellip;
        </Text>
      </Flex>
    </StyledTemplateContentNoResults>
  )
}
