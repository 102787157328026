import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import {
  Box,
  Flex,
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { StyledHeading } from '../components/Text'
import { CrossIconButton } from '../../Buttons'
import { useTemplateElementsCollection } from '../../../data'
import { levenshtein } from '../../../utils/levenshtein'
import { StudioElement, StudioElementWithId, StudioFont } from '../../../types'
import { useDismissNotification } from '../../Notifications'
import { FontCombobox } from '../../Combobox/FontCombobox'
import { useFontProvider } from '../../../contexts/FontProvider'

type SelectAlternativeFontProps = {
  missingFontName: string
  onCancel: () => void
}

export const SelectAlternativeFont: FC<SelectAlternativeFontProps> = ({
  missingFontName,
  onCancel,
}) => {
  const fontProvider = useFontProvider()

  const dismissNotification = useDismissNotification()
  const { getElements, setElements } = useTemplateElementsCollection()
  const elements = getElements()

  const allFonts = useMemo(() => {
    return fontProvider.state.fonts.slice()
  }, [fontProvider.state.fonts])

  const [filterTerm] = useState<string>('')
  const [selectedFont, setSelectedFont] = useState<StudioFont>(allFonts[0])

  const findPatternedWord = (str: string) => {
    const pattern =
      /\b([A-Z][a-z0-9]*)(?=[A-Z]|\b)|[a-z0-9]+(?:-[a-z0-9]+)*|[a-z0-9]+(?:\s+[a-z0-9]+)*\b/g
    const match = str.match(pattern)
    return match ? match[0] : str
  }

  const sortFontsByClosestNameMatch = () => {
    const missingFontNameLowerCase =
      findPatternedWord(missingFontName).toLowerCase()
    const filterTermLowerCase = filterTerm.toLowerCase()

    // If filterTerm has length, filter first for performance
    const filteredFonts = filterTerm.length
      ? allFonts.filter(({ displayName }) =>
          displayName.toLowerCase().includes(filterTermLowerCase),
        )
      : allFonts

    return filteredFonts.sort(
      (a, b) =>
        levenshtein(
          missingFontNameLowerCase,
          findPatternedWord(a.displayName).toLowerCase(),
        ) -
        levenshtein(
          missingFontNameLowerCase,
          findPatternedWord(b.displayName).toLowerCase(),
        ),
    )
  }

  const filterResults = useMemo(sortFontsByClosestNameMatch, [
    allFonts,
    missingFontName,
    filterTerm,
  ])

  useEffect(() => {
    setSelectedFont(filterResults[0] || allFonts[0])
  }, [allFonts, missingFontName, filterResults])

  const handleSubmit = useCallback(() => {
    dismissNotification(`FONT_ERROR_${missingFontName}}`)
    const newElements = elements.map((element: StudioElement) => {
      const newEl: StudioElementWithId = { ...element } as StudioElementWithId
      if (
        newEl.__typename === 'StudioTextElement' &&
        newEl.font.__typename === 'StudioFallbackFont' &&
        newEl.font.originalFont === missingFontName
      ) {
        newEl.hasError = false
        newEl.font = selectedFont!
      }
      return newEl
    })
    setElements(newElements)
  }, [
    elements,
    missingFontName,
    selectedFont,
    setElements,
    dismissNotification,
  ])

  return (
    <>
      <OverrideReachModalPadding />
      <Modal label="Font Subsitutes" isOpen={true}>
        <Flex
          width="500px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <StyledHeading
            level="h3"
            marginBottom="24px"
            textAlign="center"
            fontWeight={400}
          >
            Font Substitutes for {missingFontName}
            <CrossIconButton
              data-test-id="save-as-modal-close-icon"
              onClick={onCancel}
            />
          </StyledHeading>
          <Box padding="16px">
            <div data-testid="select-alternative-font">
              <FontCombobox
                options={allFonts}
                selected={selectedFont}
                onSelect={function (fontName: string): void {
                  const newFont = allFonts.find(
                    (f: StudioFont) => f.displayName === fontName,
                  )
                  if (newFont) {
                    setSelectedFont(newFont)
                  }
                }}
              />
            </div>
          </Box>

          <Flex width="100%" padding="16px">
            <TertiaryButton
              onClick={onCancel}
              width="100%"
              marginRight="12px"
              type="button"
            >
              Cancel
            </TertiaryButton>
            <PrimaryButton
              width="100%"
              type="submit"
              onClick={handleSubmit}
              disabled={!selectedFont}
              data-testid="select-alternative-font-submit"
            >
              Substitute
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
