import { FormControlLabel } from '@moonpig/launchpad-forms'
import { styled } from '@moonpig/launchpad-utils'

export const Label = styled(FormControlLabel)`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Moonpig, sans-serif;
  font-weight: 700;
  height: auto;
`
