import { Box, Grid, Text } from '@moonpig/launchpad-components'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { StudioElement, StudioFont, StudioTextElement } from '../../../types'
import { StudioTextInput } from '../../StudioTextInput'
import { ColorEditing } from '../Color/ColorEditing'
import { getDefaultFontLineHeight } from '../../../utils/textMetricsCalculation'
import { FontCombobox } from '../../Combobox/FontCombobox'
import { styled } from '@moonpig/launchpad-utils'
import { useFontProvider } from '../../../contexts/FontProvider'

export const TextContainer = styled.div`
  display: flex;
  height: 28px;
  margin: auto 0 auto 0;
`

export const StyledErrorText = styled(Text)`
  opacity: 0.5;
  margin: auto 0 auto 0;
`

type FontEditingProps = {
  selectedElement: StudioTextElement
  selectedElementId: string
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const FontEditing: FC<FontEditingProps> = ({
  selectedElement,
  selectedElementId,
  onUpdateElement,
}) => {
  const fontProvider = useFontProvider()

  const [fontSize, setFontSize] = useState<number | string>(
    selectedElement.fontSize,
  )

  const allFonts = useMemo(() => {
    return fontProvider.state.fonts.slice()
  }, [fontProvider.state.fonts])

  useEffect(() => {
    setFontSize(selectedElement.fontSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElement.fontSize])

  return (
    <Box paddingY={4}>
      <Text as="h4" typography="bodyBold" marginBottom={1}>
        Formatting
      </Text>
      <Grid gap={6} rowGap={4}>
        <Box width={2 / 3} data-testid="font-options-combobox-input">
          <FontCombobox
            key={selectedElementId}
            options={allFonts}
            selected={selectedElement.font}
            onSelect={async (fontName: string) => {
              const newFont = allFonts.find(
                (f: StudioFont) => f.displayName === fontName,
              )
              if (newFont) {
                const newFontSize = Number(fontSize)
                const newLineHeight = await getDefaultFontLineHeight(
                  newFont,
                  newFontSize,
                )

                onUpdateElement({
                  fontSize: newFontSize,
                  lineSpacing: newLineHeight,
                  font: {
                    __typename: 'StudioFont',
                    id: newFont!.id,
                    displayName: newFont!.displayName,
                    urls: {
                      __typename: 'StudioFontUrls',
                      source: newFont!.urls.source,
                    },
                  },
                })
              }
            }}
          />
        </Box>

        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px'} typography={'typeBodyLabel'}>
                Size
              </Text>
            }
            name="font-size"
            value={`${fontSize}`}
            onChange={async e => {
              const newFontSize = e.target.value
              setFontSize(newFontSize === '' ? '' : Number(newFontSize))
              const newLineHeight = await getDefaultFontLineHeight(
                selectedElement.font,
                parseFloat(newFontSize),
              )
              onUpdateElement({
                fontSize: Number(e.target.value),
                lineSpacing: newLineHeight,
              })
            }}
            placeholder="Enter font size"
            step={1}
          />
        </Box>

        <Box width={2 / 3}>
          <ColorEditing
            hex={selectedElement.color.hex}
            onHexChange={hex => {
              onUpdateElement({
                color: { ...selectedElement.color, hex: hex, name: hex },
              })
            }}
            id={'font'}
          />
        </Box>
        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px'} typography={'typeBodyLabel'}>
                Line Spacing
              </Text>
            }
            name="element-line-spacing"
            value={`${selectedElement.lineSpacing}`}
            onChange={e => {
              onUpdateElement({
                lineSpacing: Number(e.target.value),
              })
            }}
            step={1}
            min={0}
          />
        </Box>
      </Grid>
    </Box>
  )
}
