import { measureTextInTextArea } from './textMetricsCalculation'
import { StudioTextElement } from '../types'
import {
  StudioHorizontalAlignment,
  StudioVerticalAlignment,
} from '../__graphql__/types'

export const studioAlignToTextAlign = (
  studioAlign: StudioHorizontalAlignment,
): any => {
  return studioAlign.toLowerCase()
}

export const studioVerticalAlignMap: Record<StudioVerticalAlignment, string> = {
  [StudioVerticalAlignment.BOTTOM]: 'end',
  [StudioVerticalAlignment.MIDDLE]: 'center',
  [StudioVerticalAlignment.TOP]: 'start',
}

const applyTextMetrics = (
  style: CSSStyleDeclaration,
  fontSize: number,
  lineHeight: number,
) => {
  style.fontSize = `${fontSize}px`
  style.lineHeight = `${lineHeight}px`
}

export const textAreaAdjust = (
  element: HTMLDivElement | HTMLTextAreaElement,
  textElementData: StudioTextElement,
  unitsPerPixel: number,
  fontSize: number,
  lineHeight: number,
) => {
  if (element) {
    const scaledWidth = textElementData!.width! / unitsPerPixel
    const scaledHeight = textElementData!.height! / unitsPerPixel
    const { style } = element
    style.padding = '0'
    applyTextMetrics(style, fontSize, lineHeight)
    // Horizontal scaling first
    let calculatedFontSize: number
    let calculatedLineSpacing: number
    let textMetrics = measureTextInTextArea(element)
    const scaleFactorX = Math.min(1, scaledWidth / textMetrics.width)
    calculatedFontSize = fontSize * scaleFactorX
    calculatedLineSpacing = lineHeight * scaleFactorX
    applyTextMetrics(style, calculatedFontSize, calculatedLineSpacing)
    // Now, measure again on the re-sized text area
    textMetrics = measureTextInTextArea(element)
    const scaleFactorY =
      scaleFactorX * Math.min(1, scaledHeight / textMetrics.height)
    calculatedFontSize = fontSize * scaleFactorY
    calculatedLineSpacing = lineHeight * scaleFactorY
    applyTextMetrics(style, calculatedFontSize, calculatedLineSpacing)
    const align = textElementData!.verticalAlignment
    let offsetY = 0
    if (align === 'MIDDLE') {
      offsetY = (scaledHeight - textMetrics.height) / 2
    } else if (align === 'BOTTOM') {
      offsetY = scaledHeight - textMetrics.height
    }
    style.paddingTop = `${offsetY}px`
  }
}
