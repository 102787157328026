import { assign, createMachine } from 'xstate'
import {
  FOLDER_NAME_VALIDATION_REGEX,
  TEMPLATE_NAME_VALIDATION_REGEX,
} from '../constants'
import {
  FOLDER_NAME_ERROR,
  TEMPLATE_FILENAME_ERROR,
} from '../hooks/useErrorMessages'
import { StudioNode } from '../types'

type RenameModalMachineContext = {
  node: StudioNode
  error?: RenameNodeError
}

type RenameShowingEvent = {
  type: 'RENAME_SHOWING'
  data?: { type: 'error'; message: RenameNodeErrorTypes }
}

type RenameConfirmedEvent = {
  type: 'RENAME_CONFIRMED'
  name: string
}

type RenameCancelEvent = { type: 'RENAME_CANCEL' }

type RenameModalMachineEvents =
  | RenameShowingEvent
  | RenameConfirmedEvent
  | RenameCancelEvent

type RenameModalMachineServices = {
  validate: { data: any }
  rename:
    | { data: { type: 'success' } }
    | { data: { type: 'error'; message: string } }
}

export const createRenameModalMachine = (
  context: Omit<RenameModalMachineContext, 'error'>,
) => {
  return createMachine(
    {
      id: 'rename-modal-machine',
      initial: 'showing',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as RenameModalMachineContext,
        events: {} as RenameModalMachineEvents,
        services: {} as RenameModalMachineServices,
      },
      context: {
        error: undefined,
        ...context,
      },
      states: {
        showing: {
          on: {
            RENAME_CONFIRMED: {
              target: 'validate',
              actions: ['clearError'],
            },
            RENAME_CANCEL: {
              target: 'close',
            },
          },
        },
        validate: {
          invoke: {
            src: 'validate',
            onDone: [{ target: 'confirmed' }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        confirmed: {
          invoke: {
            src: 'rename',
            onDone: [{ target: 'close', actions: ['notifySuccess'] }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        close: {},
      },
    },
    {
      actions: {
        clearError: context => (context.error = undefined),
        hasError: assign({
          error: (_, event: RenameShowingEvent) => {
            if (event.data) {
              if (event.data.message === 'StudioInvalidNameError') {
                return new RenameNodeError(
                  event.data.message,
                  context.node.__typename === 'StudioGroup'
                    ? FOLDER_NAME_ERROR
                    : TEMPLATE_FILENAME_ERROR,
                )
              }

              return new RenameNodeError(
                event.data.message,
                RenameNodeError.getDisplayMessage(event.data.message),
              )
            }

            return undefined
          },
        }),
      },
      services: {
        validate: (context, event) => {
          if (event.type === 'RENAME_CONFIRMED') {
            const isNameValid =
              context.node.__typename === 'StudioGroup'
                ? FOLDER_NAME_VALIDATION_REGEX.test(event.name)
                : TEMPLATE_NAME_VALIDATION_REGEX.test(event.name)

            if (!isNameValid) {
              return Promise.reject(new Error('StudioInvalidNameError'))
            }
          }

          return Promise.resolve()
        },
      },
    },
  )
}

export type TemplateNodeErrorTypes =
  | 'StudioRenameTemplateError'
  | 'StudioDuplicateNameError'
  | 'StudioInvalidNameError'
  | 'StudioVersionMismatchError'
  | 'StudioDuplicatePathError'

export type GroupRenameNodeErrorTypes =
  | 'GroupNotFound'
  | 'PathNotFound'
  | 'DeleteFailed'
  | 'CreateFailed'
  | 'MaxItemsExceeded'

export type RenameNodeErrorTypes =
  | TemplateNodeErrorTypes
  | GroupRenameNodeErrorTypes

export class RenameNodeError extends Error {
  public readonly name: RenameNodeErrorTypes

  constructor(name: RenameNodeErrorTypes, message: string) {
    super(message)

    this.name = name
  }

  static getDisplayMessage(
    type: Omit<RenameNodeErrorTypes, 'StudioInvalidNameError'>,
  ) {
    switch (type) {
      case 'StudioRenameTemplateError':
        return 'Something went wrong.'
      case 'StudioVersionMismatchError':
        return 'The version has changed and no longer matches. Please try again.'
      case 'StudioDuplicateNameError':
        return 'The name is already in use.'
      case 'StudioDuplicatePathError':
        return 'The path is already in use, choose another name.'
      case 'GroupNotFound':
      case 'PathNotFound':
        return 'The folder you are attempting to rename no longer exists.'
      case 'DeleteFailed':
        return 'We were unable to clean up after renaming the folder, please contact the support team.'
      case 'CreateFailed':
        return 'Failed to rename the folder to the new name.'
      case 'MaxItemsExceeded':
        return 'Folders containing more than 100 items cannot be renamed at this time.'
    }

    return "We've encountered an unknown error, please contact the support team."
  }
}
