export const truncateTextInMiddle = (
  text: string,
  threshold: number,
  truncationText: string = '...',
): string => {
  if (text.length <= threshold) {
    return text
  }

  const parts = [
    text.substring(0, Math.floor(threshold / 2)).trim(),
    text.substring(text.length - Math.floor(threshold / 2)).trim(),
  ]
  return parts.join(truncationText)
}
