import { Flex, Text } from '@moonpig/launchpad-components'
import { FormControlLabel, Toggle } from '@moonpig/launchpad-forms'
import { ChangeEvent, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { defaultFeatureToggles } from '../../../utils/featureToggles'

const toggles = Object.keys(defaultFeatureToggles).filter(
  toggle => toggle !== 'debug',
)

type Features = { [key: string]: string }

export const DebugFeatureToggles = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search).get('feature')?.split(',')

  const features: Features = params
    ? params.reduce((acc, curr) => {
        const [key, value] = curr.split(':')
        return {
          ...acc,
          [key]: value,
        }
      }, {})
    : {}

  const handleToggleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, toggle: string) => {
      const newLocation = window.location
        .toString()
        .replace(new RegExp('(\\w)$', 'g'), '$1,')
        .replace(new RegExp(`${toggle}:\\w+,?`, 'g'), '')
        .concat(`${toggle}:${e.target.checked ? 'enabled' : 'disabled'}`)
      window.location.replace(newLocation)
    },
    [],
  )

  return (
    <Flex paddingX="28px" paddingBottom="8px" alignItems="center">
      <Text paddingRight="12px">Feature toggles:</Text>
      {toggles.map(toggle => (
        <FormControlLabel
          key={toggle}
          name={toggle}
          label={toggle}
          labelPosition="left"
        >
          <Toggle
            checked={features[toggle] === 'enabled'}
            onChange={e => handleToggleChange(e, toggle)}
            value={toggle}
          />
        </FormControlLabel>
      ))}
    </Flex>
  )
}
