import React, { useEffect } from 'react'
import { SelectAlternativeFont } from './Dialogs/SelectAlternativeFont/SelectAlternativeFont'
import { isTextElement } from '../utils'
import { useAddNotification } from './Notifications'
import { useTemplateProvider } from '../contexts/TemplateProvider'
import { useSceneProvider } from '../contexts/SceneProvider'

export const AlternativeFontsPopup: React.FC<{ missingFonts: string[] }> = ({
  missingFonts,
}) => {
  const templateProvider = useTemplateProvider()
  const sceneProvider = useSceneProvider()
  const addNotification = useAddNotification()

  useEffect(() => {
    templateProvider.state.template?.elements.forEach(element => {
      if (
        isTextElement(element) &&
        element.font.__typename === 'StudioFallbackFont'
      ) {
        const { originalFont: fontId } = element.font
        addNotification({
          id: `FONT_ERROR_${fontId}}`,
          variant: 'error',
          text: (
            <>
              <p>
                There was an error loading font <b>{fontId}</b>.
              </p>
              <p>
                <span
                  data-test-id="show-alternative-fonts"
                  onClick={() => {
                    sceneProvider.dispatch({
                      type: 'SET_SHOW_FONT_ALTERNATIVES_POPUP',
                    })
                  }}
                  style={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                >
                  Click here to find replacement fonts
                </span>
              </p>
            </>
          ),
        })
      }
    })
  }, [
    addNotification,
    missingFonts,
    sceneProvider,
    templateProvider.state.template?.elements,
  ])

  return (
    sceneProvider.state.showFontAlternativesPopup && (
      <SelectAlternativeFont
        missingFontName={missingFonts[0]!}
        onCancel={() => {
          sceneProvider.dispatch({ type: 'SET_HIDE_FONT_ALTERNATIVES_POPUP' })
        }}
      />
    )
  )
}
