import { FC } from 'react'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { StudioElement, StudioRectangleElement } from '../../../types'
import { PositionPanel } from '../PositionPanel'

export const RectangleControls: FC<{
  selectedElementId: string
  selectedElement: StudioRectangleElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}> = ({ selectedElement, onUpdateElement }) => {
  return (
    <Flex flexDirection="column" padding="16px">
      <Text typography="bodyBold">Position</Text>
      <Box>
        <PositionPanel
          onUpdateElement={onUpdateElement}
          selectedElement={selectedElement}
        />
      </Box>
    </Flex>
  )
}
