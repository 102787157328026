import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_TEMPLATE_STATUS_BY_ID } from '../queries'
import {
  TemplateStatusByIdQuery,
  TemplateStatusByIdQueryVariables,
} from '../__graphql__/queries'

export type WaitForTemplateReadyStatus = (templateId: string) => Promise<void>

export const useWaitForTemplateReadyStatus = (): WaitForTemplateReadyStatus => {
  const client = useApolloClient()
  return useCallback(
    async (id: string) => {
      return new Promise((resolve, reject) => {
        const subscription = client
          .watchQuery<
            TemplateStatusByIdQuery,
            TemplateStatusByIdQueryVariables
          >({
            pollInterval: 250,
            errorPolicy: 'all',
            query: GET_TEMPLATE_STATUS_BY_ID,
            variables: { id },
            fetchPolicy: 'no-cache',
          })
          .subscribe({
            next: ({ data }) => {
              if (
                data.studioTemplateById.__typename === 'StudioTemplateError'
              ) {
                subscription.unsubscribe()
                reject()
                return
              }
              if (data.studioTemplateById.status === 'READY') {
                subscription.unsubscribe()
                resolve()
                return
              }
            },
            error: () => {
              subscription.unsubscribe()
              reject()
            },
          })
      })
    },
    [client],
  )
}
