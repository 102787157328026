import { Box, Heading } from '@moonpig/launchpad-components'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { FC, useEffect, useState } from 'react'
import { StudioColor, StudioElement, StudioTextElement } from '../../../types'
import { TextShadowing } from './TextShadowing'

const defaultShadowX = 0.25
const defaultShadowY = 0.25
const defaultShadowColour: StudioColor = {
  __typename: 'StudioColor',
  name: 'Black',
  hex: '#000000',
}

const defaultShadow: StudioTextElement['shadow'] = {
  __typename: 'StudioTextShadow' as const,
  color: defaultShadowColour,
  offsetY: defaultShadowX,
  offsetX: defaultShadowY,
}

const noShadow = null

type StylingOptionsProps = {
  selectedElement: StudioTextElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const StylingOptions: FC<StylingOptionsProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  const [isShadowEnabled, setIsShadowEnabled] = useState(false)

  useEffect(() => {
    const elementHasShadow =
      Math.abs(selectedElement.shadow?.offsetX ?? 0) > 0 ||
      Math.abs(selectedElement.shadow?.offsetY ?? 0) > 0 ||
      (selectedElement.shadow?.color.hex ?? '#000000') !== '#000000'

    setIsShadowEnabled(elementHasShadow)
  }, [selectedElement])

  return (
    <Box paddingY={6} data-testid="text-styling-options">
      <Heading level="h4" typography="typeBodyLabel" marginBottom={0}>
        Styling
      </Heading>

      <Box paddingY={0}>
        <FormControlLabel
          name={`styling-shadow-input`}
          label="Shadow"
          id={`styling-shadow-input`}
        >
          <Checkbox
            onChange={e => {
              const shadowEnabled = e.target.checked
              setIsShadowEnabled(shadowEnabled)
              if (shadowEnabled) {
                onUpdateElement({
                  shadow: defaultShadow,
                })
              } else {
                onUpdateElement({
                  shadow: noShadow,
                })
              }
            }}
            checked={isShadowEnabled}
            value=""
          />
        </FormControlLabel>
      </Box>

      {isShadowEnabled && (
        <TextShadowing
          onUpdateElement={onUpdateElement}
          selectedElement={selectedElement}
        />
      )}
    </Box>
  )
}
