import { StudioElement } from '../../types'
import { useTemplateProvider } from '../../contexts/TemplateProvider'
import { useMemo } from 'react'

type UseElementDataResult = {
  elementData: StudioElement | null
  updateElement: (elementProperties: Partial<StudioElement>) => void
}

export const useElementData = (elementId: string): UseElementDataResult => {
  const templateProvider = useTemplateProvider()

  const element = useMemo(() => {
    return templateProvider.state.template!.elements[Number(elementId)]
  }, [templateProvider.state.template, elementId])

  return {
    elementData: element,
    updateElement: (newElementProperties: Partial<StudioElement>) => {
      const elements = Array.from(
        templateProvider.state.template?.elements ?? [],
      )

      elements[Number(elementId)] = {
        ...element,
        ...newElementProperties,
      } as StudioElement

      templateProvider.dispatch({ type: 'SET_TEMPLATE_ELEMENTS', elements })
    },
  }
}
