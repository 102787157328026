import { styled } from '@moonpig/launchpad-utils'
import {
  sizingSpacing,
  SizingSpacingSystemProps,
  system as s,
} from '@moonpig/launchpad-system'
import {
  ColorsInterface,
  colorValue,
} from '@moonpig/launchpad-theme-mission-control'

type StyledIconButtonProps = SizingSpacingSystemProps & {
  contrastColor: keyof ColorsInterface
}

export const IconButton = styled.button<StyledIconButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  text-align: center;
  border: 2px solid;

  ${sizingSpacing};

  ${() =>
    s({
      borderRadius: 2,
      bgcolor: 'colorBlack00',
      borderColor: 'colorBlack40',
    })}

  &.is-active, &.is-active:disabled {
    color: ${props => colorValue(props.contrastColor)};
    border-color: ${props => colorValue(props.contrastColor)};
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      text-decoration: none;
      ${() =>
        s({
          bgcolor: 'colorBackgroundError',
        })}
    }

    &:active {
      ${() => s({ bgcolor: 'colorBackgroundError' })}
    }
  }

  &:disabled {
    color: ${colorValue('colorBlack100')};
  }
`
