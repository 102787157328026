import React, { FC, useEffect, useState } from 'react'
import { Frame } from '@moonpig/renderer-scene-types'
import { rotate, toCSS } from 'transformation-matrix'
import { deg2rad } from '../../utils/matrix'
import { StudioTextElement } from '../../types'
import { getContentFromTextElement } from '../../utils/getContentFromTextElement'
import { useElementData } from '../../data'
import { checkFontExists, waitForFont } from '../../utils/checkFontExists'
import { createFontFace } from '../../utils/createFontFace'
import { INLINE_TEXT_EDITING_FONT_SCALE } from '../../constants'
import {
  studioAlignToTextAlign,
  studioVerticalAlignMap,
  textAreaAdjust,
} from '../../utils/interactiveTextAreaUtils'
import { styled } from '@moonpig/launchpad-utils'
import { useTemplateProvider } from '../../contexts/TemplateProvider'
import { theme } from '@moonpig/launchpad-theme-common'

type InteractiveEditableTextFrameProps = {
  selected: boolean
  frame: Frame
  onClick: () => void
  ariaLabel: string
  elementDataId: string
  unitsPerPixel: number
  editingText: boolean
}

const StyledInteractiveFrame = styled.button`
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  display: block;
  width: 100%;
  height: 100%;
`

export const InteractiveEditableTextFrame: FC<
  InteractiveEditableTextFrameProps
> = ({
  frame,
  selected,
  onClick,
  ariaLabel,
  elementDataId,
  unitsPerPixel,
  editingText,
}) => {
  const templateProvider = useTemplateProvider()
  const { elementData, updateElement } = useElementData(elementDataId)
  const textElementData = elementData as StudioTextElement
  const context = getContentFromTextElement(textElementData)
  const [text, setText] = useState(context)
  const textAreaRef: React.RefObject<HTMLTextAreaElement> = React.createRef()

  useEffect(() => {
    setText(context)
  }, [textElementData, context])

  const border = !selected
    ? 'rgba(206,51,86,0.4)'
    : theme.colors.colorInteractionTextLink

  const transform: string = toCSS(rotate(deg2rad(frame.rotation)))
  const [err, setError] = useState(false)
  const { font } = textElementData
  const [fontLoaded, setFontLoaded] = useState(false)
  const [ctTagMode, setCtTagMode] = useState(false)

  useEffect(() => {
    if (!err) {
      if (checkFontExists(font.id)) {
        setFontLoaded(true)
      } else {
        createFontFace(font.id, font.urls.source!, setError)
        waitForFont(font.id).then(() => {
          setFontLoaded(true)
        })
      }
    }
  }, [err, font.id, font.urls.source])

  useEffect(() => {
    if (text.indexOf('[') > -1) {
      setCtTagMode(true)
    } else {
      setCtTagMode(false)
    }
  }, [text])

  useEffect(() => {
    if (editingText) {
      textAreaRef.current?.focus()
      textAreaRef.current?.select()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingText])

  const fontSizeScaleFactor: number = INLINE_TEXT_EDITING_FONT_SCALE
  const lineHeight: number =
    textElementData.lineSpacing / unitsPerPixel / fontSizeScaleFactor
  const fontSize =
    textElementData.fontSize / unitsPerPixel / fontSizeScaleFactor

  useEffect(() => {
    textAreaAdjust(
      textAreaRef.current!,
      textElementData,
      unitsPerPixel,
      fontSize,
      lineHeight,
    )
  })

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const targetDiv = e.target as HTMLTextAreaElement
    const innerText = targetDiv.value
    textAreaAdjust(
      targetDiv,
      textElementData,
      unitsPerPixel,
      fontSize,
      lineHeight,
    )
    setText(innerText)
    const newElementDataText = { ...textElementData.text }

    if (newElementDataText.__typename !== 'StudioPlaceholderTextPart') {
      newElementDataText.text = innerText
      updateElement({
        text: newElementDataText,
      })
    }
  }

  return (
    <StyledInteractiveFrame
      data-testid="std-interactive-frame"
      aria-label={ariaLabel}
      onClick={onClick}
      style={{
        transform,
        cursor: selected ? 'move' : 'pointer',
        border: `2px ${selected ? 'solid' : 'dotted'} ${border}`,
        pointerEvents: 'all',
      }}
    >
      {fontLoaded && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignContent: 'stretch',
            alignItems: 'flex-start',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
          }}
        >
          {editingText && (
            <textarea
              wrap={'hard'}
              data-testid="text-edit-textarea"
              ref={textAreaRef}
              rows={1}
              style={{
                order: 0,
                flex: '1 1 auto',
                alignSelf:
                  studioVerticalAlignMap[textElementData.verticalAlignment],
                overflowWrap: 'unset',
                wordBreak: 'keep-all',
                whiteSpace: 'nowrap',
                border: 'transparent',
                resize: 'none',
                background: ctTagMode ? 'white' : 'transparent',
                caretColor: 'black',
                overflow: 'hidden',
                fontFamily: textElementData.font.id,
                color: ctTagMode ? 'black' : 'transparent',
                fontSize: `${fontSize}px`,
                lineHeight: `${lineHeight}px`,
                height: `${textElementData.height / unitsPerPixel}px`,
                outline: ctTagMode ? '2px dotted red' : 0,
                textAlign: studioAlignToTextAlign(
                  textElementData.horizontalAlignment,
                ),
              }}
              onChange={handleTextAreaChange}
              onFocus={() => templateProvider.startBatch()}
              onBlur={() => templateProvider.endBatch()}
              defaultValue={text}
            ></textarea>
          )}
        </div>
      )}
    </StyledInteractiveFrame>
  )
}
