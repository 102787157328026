import { StudioTextElement } from '../types'

export const getContentFromTextElement = (
  selectedElement: StudioTextElement | null,
): string => {
  if (!selectedElement) return ''
  let content: string
  if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
    content = selectedElement.text.textParts
      .map(textPart =>
        textPart.__typename === 'StudioPlainTextPart'
          ? `[${textPart.text}]`
          : textPart.text,
      )
      .join('')
  } else {
    content = selectedElement.text.text
  }
  return content
}
