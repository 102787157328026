import { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Heading } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme'
import { useTemplateExplorer } from '../TemplateExplorerProvider'
import { useMachine } from '../../../state/xstate'
import { StudioGroup, StudioNode } from '../../../types'
import { PillButton } from './PillButton'
import { useNavigate } from 'react-router-dom'

const StyledDialogNav = styled.nav`
  flex-shrink: 0;
  width: 300px;
  padding: 16px 8px 16px 8px;
  background-color: ${colorValue('colorBackground02')};
  height: 100%;
`
const StyledDialogNavItems = styled.ul`
  padding-top: 16px;
  padding-bottom: 50px;
  height: 100%;
  overflow: scroll;
`

type SideNavProps = {
  recentlyOpenedGroup: StudioGroup
  onGroupSelect: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}

export const SideNav: FC<SideNavProps> = ({
  onGroupSelect,
  recentlyOpenedGroup,
  onMoved,
}) => {
  const navigate = useNavigate()
  const templateExplorer = useTemplateExplorer()
  const { state } = useMachine()

  const handlePillButtonClick = () => {
    navigate({
      pathname: `/${recentlyOpenedGroup.path}`,
      search: window.location.search,
    })
    if (state.matches({ TEMPLATE_EXPLORER: 'GROUP_SELECTION' })) {
      templateExplorer.dispatch({
        type: 'SET_CURRENT_GROUP',
        payload: { group: recentlyOpenedGroup },
      })
    }
  }

  const handleRootPathSelected = (node: StudioNode) => {
    onGroupSelect(node, false)
  }

  return (
    <StyledDialogNav>
      <Heading
        level="h2"
        typography="typeDisplay06"
        mb={0}
        pl={4}
        color="colorBlack100"
      >
        Templates
      </Heading>
      <StyledDialogNavItems>
        <Flex key={`recently-opened`} as="li" mb={3}>
          <PillButton
            onClick={handlePillButtonClick}
            onMoved={onMoved}
            childRootGroup={recentlyOpenedGroup}
            selectedGroup={
              templateExplorer.state.currentGroup.path ===
              recentlyOpenedGroup.path
            }
          />
        </Flex>
        {templateExplorer.state.rootGroup &&
          templateExplorer.state.rootGroup.children &&
          templateExplorer.state.rootGroup.children
            .filter(
              (childRootGroup): childRootGroup is StudioGroup =>
                childRootGroup.__typename === 'StudioGroup',
            )
            .map((childRootGroup, index) => {
              const isLegacyRootPath = !childRootGroup.path.startsWith('root')

              const path = isLegacyRootPath
                ? childRootGroup.path
                : childRootGroup.path.split('>').slice(1).join('>')

              const selectedGroup =
                templateExplorer.state.currentGroup.path.startsWith(path)

              return (
                <Flex key={`${childRootGroup.id}-${index}`} as="li" mb={3}>
                  <PillButton
                    onClick={handleRootPathSelected}
                    onMoved={onMoved}
                    childRootGroup={childRootGroup}
                    selectedGroup={selectedGroup}
                  />
                </Flex>
              )
            })}
        {state.matches({ TEMPLATE_EXPLORER: 'LOADING_GROUPS' }) && (
          <li>Loading&hellip;</li>
        )}
      </StyledDialogNavItems>
    </StyledDialogNav>
  )
}
