import {
  MAX_RECENTLY_OPENED,
  RECENTLY_OPENED_KEY,
  RECENTLY_OPENED_KEY_V2,
} from '../../../constants'
import { localStore } from '../../../utils/localStorage'
import {
  RecentlyOpenedGroup,
  RecentlyOpenedNode,
  RecentlyOpenedTemplate,
} from '../types'

export const getRecentlyOpenedGroup = (): RecentlyOpenedGroup => {
  return {
    __typename: 'StudioGroup',
    id: 'recently-opened',
    name: 'Recently Opened',
    path: 'recently-opened',
    parentPath: '',
    children: [],
  }
}

const migrateLegacyStorage = () => {
  const legacy = localStore.getItem<RecentlyOpenedGroup>(RECENTLY_OPENED_KEY)

  if (legacy) {
    const migrated = legacy.children.map(item => ({
      templateId: item.id,
      lastOpened: item.lastOpened,
    }))

    localStore.setItem(RECENTLY_OPENED_KEY_V2, migrated)
    localStore.removeItem(RECENTLY_OPENED_KEY)
  }
}

export const getRecentlyOpenedFromStorage = () => {
  migrateLegacyStorage()

  let recentlyOpenedTemplates =
    localStore.getItem<RecentlyOpenedTemplate[]>(RECENTLY_OPENED_KEY_V2) ?? []

  return recentlyOpenedTemplates
}

export const setRecentlyOpened = (templateId: string) => {
  let recentlyOpenedTemplates = getRecentlyOpenedFromStorage()

  const recentlyOpenedTemplateIndex = recentlyOpenedTemplates.findIndex(
    item => item.templateId === templateId,
  )

  const item = {
    templateId,
    lastOpened: new Date().toISOString(),
  }

  if (recentlyOpenedTemplateIndex >= 0) {
    recentlyOpenedTemplates[recentlyOpenedTemplateIndex] = item
  } else {
    recentlyOpenedTemplates.push(item)
  }

  recentlyOpenedTemplates.sort(
    (a, b) => Date.parse(b.lastOpened) - Date.parse(a.lastOpened),
  )

  if (recentlyOpenedTemplates.length > MAX_RECENTLY_OPENED) {
    recentlyOpenedTemplates = recentlyOpenedTemplates.slice(0, -1)
  }

  localStore.setItem(RECENTLY_OPENED_KEY_V2, recentlyOpenedTemplates)
}

export const deletedRecentlyOpened = (templateId: string) => {
  let recentlyOpenedTemplates = getRecentlyOpenedFromStorage()

  const recentlyOpenedTemplateIndex = recentlyOpenedTemplates.findIndex(
    item => item.templateId === templateId,
  )

  if (recentlyOpenedTemplateIndex >= 0) {
    recentlyOpenedTemplates.splice(recentlyOpenedTemplateIndex, 1)
  }

  localStore.setItem(RECENTLY_OPENED_KEY_V2, recentlyOpenedTemplates)
}

export const sortTemplatesByDate = (recentlyOpened: RecentlyOpenedNode[]) => {
  const now = new Date()

  const recentlyOpenedThisWeek: RecentlyOpenedNode[] = []
  const recentlyOpenedLastWeek: RecentlyOpenedNode[] = []
  const recentlyOpenedLastMonth: RecentlyOpenedNode[] = []
  const recentlyOpenedEverythingElse: RecentlyOpenedNode[] = []

  if (recentlyOpened.length) {
    const startOfThisWeek = new Date(new Date().setDate(now.getDate() - 7))
    const startOfLastWeek = new Date(new Date().setDate(now.getDate() - 14))
    const lastMonth = new Date(new Date().setMonth(now.getMonth() - 1))

    recentlyOpened.forEach(child => {
      const lastOpened = new Date(child.lastOpened)

      if (lastOpened >= startOfThisWeek) {
        recentlyOpenedThisWeek.unshift(child)
        return
      }

      if (lastOpened >= startOfLastWeek) {
        recentlyOpenedLastWeek.unshift(child)
        return
      }

      if (lastOpened <= startOfThisWeek && lastOpened >= lastMonth) {
        recentlyOpenedLastMonth.unshift(child)
        return
      }

      if (lastOpened <= lastMonth) {
        recentlyOpenedEverythingElse.unshift(child)
        return
      }
    })
  }

  return {
    recentlyOpenedThisWeek,
    recentlyOpenedLastWeek,
    recentlyOpenedLastMonth,
    recentlyOpenedEverythingElse,
  }
}
