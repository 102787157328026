import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_BY_NAME } from '../queries'
import { StudioGroup } from '../types'
import { GetByNameQuery, GetByNameQueryVariables } from '../__graphql__/queries'

export type SearchTemplateService = (name: string) => Promise<StudioGroup>

export const useSearchTemplate = (): SearchTemplateService => {
  const client = useApolloClient()

  return useCallback(
    async (name: string) => {
      const { data } = await client.query<
        GetByNameQuery,
        GetByNameQueryVariables
      >({
        errorPolicy: 'all',
        query: GET_BY_NAME,
        variables: { name },
      })

      if (data.searchByName.__typename === 'StudioGroup') {
        return data.searchByName
      }

      throw new Error(data.searchByName.__typename)
    },
    [client],
  )
}
