import { Box, Text } from '@moonpig/launchpad-components'
import { FC } from 'react'
import { StudioTextInput } from '../../StudioTextInput'
import { debounce } from '@moonpig/launchpad-utils'

type ColorEditingProps = {
  onHexChange: (hex: string) => void
  hex: string
  id: string
}

export const ColorEditing: FC<ColorEditingProps> = ({
  onHexChange,
  hex,
  id,
}) => {
  const onUpdate = debounce(onHexChange)

  return (
    <>
      <Box width={1 / 1} paddingBottom={0}>
        <StudioTextInput
          type="color"
          id={`${id}-color-picker`}
          label={
            <Text fontSize={'14px'} typography={'typeBodyLabel'}>
              Colour
            </Text>
          }
          name={`${id}-element-color`}
          value={hex}
          onChange={e => {
            const newColor = e.target.value
            onUpdate(newColor)
          }}
        />
      </Box>
    </>
  )
}
