import { assign, createMachine, DoneInvokeEvent, sendParent } from 'xstate'
import {
  ImportStatusError,
  StatusHandler,
  StatusService,
} from '../../services/Import/Status'
import {
  ImportUploadError,
  UploadHandler,
  UploadService,
} from '../../services/Import/Upload'
import { StudioGroup } from '../../types'

export type UploadMachineContext = {
  uploadService: UploadService
  statusService: StatusService
  upload: { file: File; currentGroup: StudioGroup }
  templateId?: string
  errors: Array<ImportUploadError | ImportStatusError>
}

type UploadDoneEvent = DoneInvokeEvent<{} & Awaited<ReturnType<UploadHandler>>>
type UploadErrorEvent = { data: ImportUploadError }

type UploadMachineServices = {
  upload: { data: Awaited<ReturnType<UploadHandler>> }
  status: { data: Awaited<ReturnType<StatusHandler>> }
}

export type UploadState<T> = {
  value: T
  context: UploadMachineContext
}

export type UploadStates =
  | UploadState<'COMPLETED'>
  | UploadState<'FAILED'>
  | UploadState<'UPLOADING'>
  | UploadState<'WAITING'>
  | UploadState<'VALIDATING'>

export const createUploadMachine = (
  context: Omit<UploadMachineContext, 'errors'>,
) => {
  return createMachine(
    {
      id: 'uploading-machine',
      initial: 'VALIDATING',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as UploadMachineContext,
        services: {} as UploadMachineServices,
      },
      context: {
        ...context,
        errors: [],
      },
      states: {
        VALIDATING: {
          invoke: {
            id: 'VALIDATING',
            src: 'validate',
            onDone: {
              target: 'UPLOADING',
              actions: ['checkImportStatus'],
            },
            onError: {
              target: 'FAILED',
              actions: ['failed', 'checkImportStatus'],
            },
          },
        },
        UPLOADING: {
          invoke: {
            id: 'UPLOADING',
            src: 'upload',
            onDone: {
              target: 'WAITING',
              actions: ['uploaded', 'refreshGroup'],
            },
            onError: {
              target: 'FAILED',
              actions: ['failed', 'refreshGroup', 'checkImportStatus'],
            },
          },
        },
        WAITING: {
          invoke: {
            id: 'WAITING',
            src: 'status',
            onDone: {
              actions: ['refreshGroup', 'checkImportStatus'],
              target: 'COMPLETED',
            },
            onError: {
              target: 'FAILED',
              actions: ['failed', 'refreshGroup', 'checkImportStatus'],
            },
          },
        },
        FAILED: {
          type: 'final',
        },
        COMPLETED: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        uploaded: assign({
          templateId: (_, event) => (event as UploadDoneEvent).data.templateId,
        }),
        failed: assign({
          errors: (context, event) =>
            context.errors.concat((event as unknown as UploadErrorEvent).data),
        }),
        refreshGroup: sendParent((context, _event) => ({
          type: 'REFRESH_GROUP',
          currentGroup: context.upload.currentGroup,
        })),
        checkImportStatus: sendParent((context, _event) => ({
          type: 'CHECK_IMPORT_STATUS',
          currentGroup: context.upload.currentGroup,
        })),
      },
      services: {
        validate: async context => {
          return context.uploadService.validate(context.upload.file)
        },
        upload: async context => {
          return context.uploadService.upload(
            context.upload.file,
            context.upload.currentGroup,
          )
        },
        status: async context => {
          return context.statusService.status(context.templateId!)
        },
      },
    },
  )
}
