import { Box, Grid, Text } from '@moonpig/launchpad-components'
import {
  Checkbox,
  FormControlLabel,
  RadioButton,
  RadioGroup,
} from '@moonpig/launchpad-forms'
import { styled } from '@moonpig/launchpad-utils'
import { FC } from 'react'
import { useMachine } from '../../../state/xstate'
import {
  StudioElement,
  StudioPlainTextPart,
  StudioStaticTextPart,
  StudioStyledTextPart,
  StudioTextElement,
} from '../../../types'
import {
  isPlaceholderTextPart,
  isPlainTextPart,
  isStaticTextPart,
  isStyledTextPart,
} from '../../../utils'
import { MaxCharactersOption } from './Options/MaxCharactersOption'
import { PlaceholderTextOptions } from './PlaceholderTextOptions'
import { StudioTextTransform } from '../../../__graphql__/types'

type CustomisationOptionsProps = {
  selectedElement: StudioTextElement
  selectedTextPartIndex: number | null
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type StyledTextOptionsProps = {
  textPart: StudioStyledTextPart
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type PlainTextOptionsProps = {
  textPart: StudioPlainTextPart
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type EditableRadioGroupProps = {
  textPart: StudioStyledTextPart | StudioStaticTextPart | StudioPlainTextPart
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

const StyledRadioGroup = styled(RadioGroup)`
  legend {
    display: none;
  }
`

const EditableRadioGroup: FC<EditableRadioGroupProps> = ({
  textPart,
  onUpdateElement,
}) => {
  const { state } = useMachine()
  const selectedElementId = state.context.selectedElementId!

  return (
    <Box paddingY={0}>
      <FormControlLabel label="Editable" id="editable-checkbox">
        <Checkbox
          checked={textPart.__typename !== 'StudioStaticTextPart'}
          onChange={e => {
            if (e.target.checked) {
              onUpdateElement({
                text: {
                  __typename: 'StudioPlainTextPart',
                  text: textPart.text,
                  allowBlank: true,
                  allowDefault: true,
                  textTransform: StudioTextTransform.NONE,
                  maxCharacters: null,
                  // Custom number may not be needed
                  customNo: Number(selectedElementId),
                },
              })
            } else {
              onUpdateElement({
                text: {
                  __typename: 'StudioStaticTextPart',
                  text: textPart.text,
                },
              })
            }
          }}
          value="StudioStaticTextPart"
        />
      </FormControlLabel>

      {textPart.__typename !== 'StudioStaticTextPart' && (
        <Box marginLeft={9}>
          <StyledRadioGroup name="editable-text-parts-radio" legend="">
            <>
              <FormControlLabel label="Text only" id="editable-text-only">
                <RadioButton
                  checked={textPart.__typename === 'StudioPlainTextPart'}
                  onChange={() =>
                    onUpdateElement({
                      text: {
                        __typename: 'StudioPlainTextPart',
                        text: textPart.text,
                        allowBlank: true,
                        allowDefault: true,
                        textTransform: StudioTextTransform.NONE,
                        maxCharacters: null,
                        // Custom number may nnot be needed
                        customNo: Number(selectedElementId),
                      },
                    })
                  }
                  value="StudioPlainTextPart"
                />
              </FormControlLabel>

              <FormControlLabel
                label="Text & formatting"
                id="editable-text-and-style"
              >
                <RadioButton
                  checked={textPart.__typename === 'StudioStyledTextPart'}
                  onChange={() =>
                    onUpdateElement({
                      text: {
                        __typename: 'StudioStyledTextPart',
                        text: textPart.text,
                        allowBlank: true,
                        allowDefault: true,
                      },
                    })
                  }
                  value="StudioStyledTextPart"
                />
              </FormControlLabel>
            </>
          </StyledRadioGroup>
        </Box>
      )}
    </Box>
  )
}

const StyledTextOptions: FC<StyledTextOptionsProps> = ({
  textPart,
  onUpdateElement,
}) => {
  return (
    <Box paddingY={0}>
      <EditableRadioGroup
        onUpdateElement={onUpdateElement}
        textPart={textPart}
      />
      <FormControlLabel
        name={`styled-allow-default`}
        label="Edit required"
        id={`styled-allow-default`}
      >
        <Checkbox
          onChange={() =>
            onUpdateElement({
              text: {
                ...textPart,
                allowDefault: !textPart.allowDefault,
              },
            })
          }
          checked={!textPart.allowDefault}
          value=""
        />
      </FormControlLabel>

      <FormControlLabel
        name={`styled-allow-blank`}
        label="Allow blank"
        id={`styled-allow-blank`}
      >
        <Checkbox
          onChange={() =>
            onUpdateElement({
              text: {
                ...textPart,
                allowBlank: !textPart.allowBlank,
              },
            })
          }
          checked={textPart.allowBlank}
          value=""
        />
      </FormControlLabel>
    </Box>
  )
}

const PlainTextOptions: FC<PlainTextOptionsProps> = ({
  textPart,
  onUpdateElement,
}) => {
  const updateMaxCharacters = (enabled: boolean) => {
    if (!enabled) {
      onUpdateElement({
        text: {
          ...textPart,
          maxCharacters: null,
        },
      })
      return
    }

    if (!textPart.maxCharacters) {
      onUpdateElement({
        text: {
          ...textPart,
          maxCharacters: textPart.text.length,
        },
      })
      return
    }
  }

  return (
    <>
      <EditableRadioGroup
        onUpdateElement={onUpdateElement}
        textPart={textPart}
      />
      <FormControlLabel
        name="plain-allow-default"
        label="Edit required"
        id="plain-allow-default"
      >
        <Checkbox
          onChange={() =>
            onUpdateElement({
              text: {
                ...textPart,
                allowDefault: !textPart.allowDefault,
              },
            })
          }
          checked={!textPart.allowDefault}
          value=""
        />
      </FormControlLabel>

      <FormControlLabel
        name="plain-allow-blank"
        label="Allow blank"
        id="plain-allow-blank"
      >
        <Checkbox
          onChange={() =>
            onUpdateElement({
              text: {
                ...textPart,
                allowBlank: !textPart.allowBlank,
              },
            })
          }
          checked={textPart.allowBlank}
          value=""
        />
      </FormControlLabel>

      <FormControlLabel
        name="plain-capitalise"
        label="Capitalise"
        id="plain-capitalise"
      >
        <Checkbox
          onChange={() =>
            onUpdateElement({
              text: {
                ...textPart,
                textTransform:
                  textPart.textTransform === StudioTextTransform.UPPERCASE
                    ? StudioTextTransform.NONE
                    : StudioTextTransform.UPPERCASE,
              },
            })
          }
          checked={textPart.textTransform === 'UPPERCASE'}
          value=""
        />
      </FormControlLabel>

      <MaxCharactersOption
        checked={
          textPart.maxCharacters !== null &&
          textPart.maxCharacters !== undefined &&
          textPart.maxCharacters > 0
        }
        value={textPart.maxCharacters ?? 0}
        onUpdateCheckedState={value => updateMaxCharacters(value)}
        onUpdateValue={value =>
          onUpdateElement({
            text: {
              ...textPart,
              maxCharacters: value,
            },
          })
        }
      />
    </>
  )
}

export const CustomisationOptions: FC<CustomisationOptionsProps> = ({
  selectedElement,
  onUpdateElement,
  selectedTextPartIndex,
}) => {
  return (
    <Box paddingY={4}>
      <Text as="h4" typography="bodyBold" marginBottom={1}>
        Customisation
      </Text>
      <Grid gap={6} rowGap={4} style={{ position: 'relative' }}>
        <Box width={1}>
          {isStaticTextPart(selectedElement.text) &&
            !isPlaceholderTextPart(selectedElement.text) && (
              <EditableRadioGroup
                onUpdateElement={onUpdateElement}
                textPart={selectedElement.text}
              />
            )}

          {isStyledTextPart(selectedElement.text) && (
            <StyledTextOptions
              onUpdateElement={onUpdateElement}
              textPart={selectedElement.text}
            />
          )}

          {isPlainTextPart(selectedElement.text) && (
            <PlainTextOptions
              onUpdateElement={onUpdateElement}
              textPart={selectedElement.text}
            />
          )}

          {isPlaceholderTextPart(selectedElement.text) && (
            <PlaceholderTextOptions
              selectedTextPartIndex={selectedTextPartIndex}
              onUpdateElement={onUpdateElement}
              textParts={selectedElement.text.textParts}
            />
          )}
        </Box>
      </Grid>
    </Box>
  )
}
