import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import polly from 'polly-js'

import { CREATE_TEMPLATE } from '../mutations'
import { StudioTemplate, StudioUser } from '../types'
import { mapTemplate } from '../utils/mapTemplateForApi'
import {
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
} from '../__graphql__/mutations'
import { StudioCreateTemplateResult } from '../__graphql__/types'

export type CreateTemplateService = (
  template: StudioTemplate,
  user: StudioUser,
  groupPath: string,
  name: string,
) => Promise<StudioCreateTemplateResult>

const shouldRetryError = (err: Error) => {
  return ['StudioDuplicateNameError'].includes(err.message) === false
}

export const useCreateTemplate = (): CreateTemplateService => {
  const client = useApolloClient()

  return useCallback(
    async (template, createdBy, groupPath, name) => {
      return polly()
        .handle(shouldRetryError)
        .waitAndRetry(5)
        .executeForPromise(async () => {
          const { id, version, ...clonedTemplate } = await mapTemplate(template)
          const newTemplate = {
            ...clonedTemplate,
            displayName: name,
          }
          const { data } = await client.mutate<
            CreateTemplateMutation,
            CreateTemplateMutationVariables
          >({
            errorPolicy: 'all',
            mutation: CREATE_TEMPLATE,
            variables: {
              input: {
                template: newTemplate,
                createdBy: createdBy.email,
                groupPath,
              },
            },
          })

          if (data?.createTemplate?.__typename === 'StudioCreatedTemplate') {
            return data.createTemplate
          }

          if (
            data?.createTemplate?.__typename ===
            'StudioTemplateValidationErrorResult'
          ) {
            return data.createTemplate
          }

          if (
            data?.createTemplate?.__typename === 'StudioCreatedTemplateError'
          ) {
            throw new Error(data?.createTemplate?.__typename)
          }

          if (data?.createTemplate?.__typename === 'StudioDuplicateNameError') {
            throw new Error(data?.createTemplate?.__typename)
          }

          throw new Error('StudioCreatedTemplateUnknownError')
        })
    },
    [client],
  )
}
