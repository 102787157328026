import { Box, Grid } from '@moonpig/launchpad-components'
import { FormControlLabel } from '@moonpig/launchpad-forms'
import { FC, PropsWithChildren } from 'react'
import { StudioElement, StudioTextElement } from '../../../types'
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from '@styled-icons/material'
import { styled } from '@moonpig/launchpad-utils'
import {
  StudioHorizontalAlignment,
  StudioVerticalAlignment,
} from '../../../__graphql__/types'

type TextAlignmentProps = {
  selectedElement: StudioTextElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type RadioButtonProps = {
  id: string
  name: string
  onSelect(): void
  checked: boolean
}

const StyledRadioButton = styled.input`
  appearance: none;
  position: absolute;
`

const StyledRadioLabel = styled.label`
  position: relative;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.colorBlack20};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.colorBlack100};
  input:checked + & {
    color: ${({ theme }) => theme.colors.colorBlack100};
    border: 2px solid ${({ theme }) => theme.colors.colorBlack100};
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 0.875rem;
  font-weight: 700;
  height: 2rem;
`

const horizontalAlignment = [
  {
    position: 'LEFT' as const,
    icon: <FormatAlignLeft width="24px" height="24px" />,
  },
  {
    position: 'CENTER' as const,
    icon: <FormatAlignCenter width="24px" height="24px" />,
  },
  {
    position: 'RIGHT' as const,
    icon: <FormatAlignRight width="24px" height="24px" />,
  },
  {
    position: 'JUSTIFY' as const,
    icon: <FormatAlignJustify width="24px" height="24px" />,
  },
]

const verticalAlignment = [
  {
    position: 'TOP' as const,
    icon: <VerticalAlignTop width="24px" height="24px" />,
  },
  {
    position: 'MIDDLE' as const,
    icon: <VerticalAlignCenter width="24px" height="24px" />,
  },
  {
    position: 'BOTTOM' as const,
    icon: <VerticalAlignBottom width="24px" height="24px" />,
  },
]

export const RadioButton: FC<PropsWithChildren<RadioButtonProps>> = ({
  id,
  name,
  onSelect,
  checked,
  children,
}) => {
  return (
    <Box position="relative" width="40px" height="40px">
      <StyledRadioButton
        id={id}
        data-testid={`${id}-radio`}
        type="radio"
        name={name}
        onChange={() => onSelect()}
        checked={checked}
      />
      <StyledRadioLabel htmlFor={id} data-testid={id}>
        {children}
      </StyledRadioLabel>
    </Box>
  )
}

export const TextAlignment: FC<TextAlignmentProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  return (
    <Box>
      <Box marginBottom={4}>
        <StyledFormControlLabel label="Alignment" />
        <Grid gap={6} rowGap={4}>
          {horizontalAlignment.map(align => {
            return (
              <Box width={'44px'} key={`${align.position}`}>
                <RadioButton
                  id={`align-${align.position}`}
                  name={align.position}
                  checked={
                    selectedElement.horizontalAlignment === align.position
                  }
                  onSelect={() => {
                    onUpdateElement({
                      horizontalAlignment:
                        StudioHorizontalAlignment[align.position],
                    })
                  }}
                >
                  {align.icon}
                </RadioButton>
              </Box>
            )
          })}
        </Grid>
      </Box>
      <Box>
        <StyledFormControlLabel label="Text Position" />
        <Grid gap={6} rowGap={4}>
          {verticalAlignment.map(align => {
            return (
              <Box width={'44px'} key={`${align.position}`}>
                <RadioButton
                  id={`align-${align.position}`}
                  name={align.position}
                  checked={selectedElement.verticalAlignment === align.position}
                  onSelect={() => {
                    onUpdateElement({
                      verticalAlignment:
                        StudioVerticalAlignment[align.position],
                    })
                  }}
                >
                  {align.icon}
                </RadioButton>
              </Box>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}
