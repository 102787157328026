import { ApolloClient } from '@apollo/client'
import * as opentype from 'opentype.js'
import polly from 'polly-js'
import { UPLOAD_FONT } from '../../mutations'
import { StudioUser } from '../../types'
import {
  UploadFontMutation,
  UploadFontMutationVariables,
} from '../../__graphql__/mutations'
import { StudioFontUpload } from '../../__graphql__/types'

export type FontUploadService = {
  parse: ParseFileHandler
  upload: FontUploadHandler
}

export type CreateFontUploadService = (
  client: ApolloClient<object>,
  user: StudioUser,
) => FontUploadService

export type ParseFileHandler = (file: File) => Promise<string>

export type FontUploadHandler = (
  file: File,
  fontName: string,
) => Promise<StudioFontUpload>

const readFileBytes = (file: File) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onloadend = f => {
      const bytes = f.target!.result as ArrayBuffer
      resolve(bytes)
    }
    fileReader.onerror = e => {
      reject(e)
    }
    fileReader.readAsArrayBuffer(file)
  })

export const createFontUploadService: CreateFontUploadService = (
  client,
  user,
) => {
  const parse: ParseFileHandler = async file => {
    if (file.name.endsWith('.ttf') === false) {
      throw new FontUploadError(
        'StudioUnsupportFontError',
        'Only TTF files are currently supported.',
      )
    }

    const content = await readFileBytes(file)
    const font = opentype.parse(content)
    const fontName = font.names.fullName.en
    return fontName
  }

  const upload: FontUploadHandler = async (file, fontName) => {
    const data = await polly()
      .waitAndRetry(3)
      .executeForPromise(async () => {
        const { data } = await client.mutate<
          UploadFontMutation,
          UploadFontMutationVariables
        >({
          errorPolicy: 'all',
          mutation: UPLOAD_FONT,
          variables: {
            fontName,
            uploadedBy: user.email,
          },
        })

        if (data && data.uploadFont.__typename === 'StudioFontUpload') {
          return data.uploadFont
        }

        if (data && data.uploadFont.__typename === 'StudioFontUploadError') {
          throw new FontUploadError(
            'StudioFontUploadError',
            data.uploadFont.message,
          )
        }

        throw new FontUploadError(
          'StudioFontUploadError',
          'Something has gone wrong',
        )
      })

    await polly()
      .waitAndRetry(3)
      .executeForPromise(async () => {
        const { signedUrl } = data
        const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'font/ttf' },
          body: file,
        })

        if (response.status > 200) {
          throw new Error(`${response.status}: ${response.statusText}`)
        }
      })

    return data
  }

  return { parse, upload }
}

export class FontUploadError extends Error {
  public readonly error: string | undefined

  constructor(
    name: 'StudioFontUploadError' | 'StudioUnsupportFontError',
    message: string,
    error?: string | undefined,
  ) {
    super(message)

    this.name = name
    this.error = error
  }
}
