export const reorderElements = <T>(
  elements: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from(elements)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
