import {
  Alert,
  Box,
  Flex,
  Heading,
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { useMachine } from '@xstate/react'
import React, { FC } from 'react'
import { StudioGroup } from '../../../__graphql__/types'
import { createMoveModalMachine } from '../../../machines/move-modal-machine'
import { useMoveGroup } from '../../../services/Move/Group'
import { useMoveTemplate } from '../../../services/Move/Template'
import { StudioNode } from '../../../types'
import { CrossIconButton } from '../../Buttons'
import { PathCombobox } from '../../Combobox/PathCombobox'
import { useAddNotification } from '../../Notifications'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import { StyledHeading } from '../components/Text'

type SearchGroupComboBoxProps = {
  onSelected: (node: StudioNode) => void
  onDeselected: () => void
}

export const SearchGroupComboBox: React.FC<SearchGroupComboBoxProps> = ({
  onSelected,
  onDeselected,
}) => {
  return (
    <PathCombobox
      options={[]}
      onSelect={(record: StudioGroup | undefined) => {
        if (!record) {
          onDeselected()
          return
        }

        onSelected(record)
      }}
    />
  )
}

type MoveDialogProps = {
  node: StudioNode
  onMoved: () => void
  onClose: () => void
}

export const MoveDialog: FC<MoveDialogProps> = ({ node, onMoved, onClose }) => {
  const moveTemplateService = useMoveTemplate()
  const moveGroupService = useMoveGroup()
  const addNotification = useAddNotification()

  const machine = React.useMemo(() => createMoveModalMachine({ node }), [node])
  const [state, send] = useMachine(machine, {
    actions: {
      notifySuccess: context => {
        const type =
          context.node.__typename === 'StudioGroup' ? 'Folder' : 'Template'

        addNotification({
          id: 'MOVING',
          text: `${type} was moved successfully to ${context.destination!.path.replace(
            />/g,
            ' > ',
          )}.`,
          variant: 'success',
        })

        onMoved()
      },
      notifyError: context => {
        addNotification({
          id: 'MOVING',
          text: context.error?.message,
          variant: 'error',
        })

        onClose()
      },
    },
    services: {
      move: async context => {
        if (context.destination === undefined) {
          throw new Error('StudioMoveMissingDestination')
        }

        if (node.parentPath === context.destination.path) {
          throw new Error('StudioMoveSameDestination')
        }

        if (node.__typename === 'StudioGroupTemplate') {
          await moveTemplateService(node.id, context.destination.path)
        }

        if (node.__typename === 'StudioGroup') {
          await moveGroupService(node.id, context.destination?.path)
        }
      },
    },
  })

  const handleSelected = (node: StudioNode) => {
    send({ type: 'MOVE_SET_DESTINATION', destination: node })
  }

  const handleDeselected = () => {
    send({ type: 'MOVE_SET_DESTINATION', destination: undefined })
  }

  const type = node.__typename === 'StudioGroup' ? 'folder' : 'template'

  const shouldShowError =
    state.context.error?.name === 'StudioMoveTemplateError'

  return (
    <>
      <OverrideReachModalPadding />
      <Modal
        testId={`move-${type}-dialog`}
        label={`Move ${type}`}
        isOpen={true}
      >
        <Flex
          width="400px"
          flexDirection="column"
          justifyContent="space-between"
          as="form"
          onSubmit={e => {
            e.preventDefault()
            send({ type: 'MOVE_CONFIRMED' })
          }}
        >
          <StyledHeading
            level="h2"
            textAlign="center"
            marginBottom="24px"
            fontWeight={400}
          >
            Move {type}
            <CrossIconButton
              data-testid="context-menu-close-icon"
              onClick={onClose}
            />
          </StyledHeading>

          {shouldShowError && (
            <Box paddingX={6} paddingTop={6}>
              <Alert variant="error">
                <Heading level="h4" mb={0}>
                  Oops!
                </Heading>
                <span>{state.context.error?.message}</span>
              </Alert>
            </Box>
          )}

          <Box padding="16px">
            <SearchGroupComboBox
              onSelected={handleSelected}
              onDeselected={handleDeselected}
            />
          </Box>

          <Flex width="100%" padding="16px">
            <TertiaryButton
              width="100%"
              marginRight="12px"
              onClick={onClose}
              disabled={false}
              type="button"
            >
              Cancel
            </TertiaryButton>
            <PrimaryButton
              width="100%"
              disabled={state.context.destination === undefined}
              loading={state.matches('confirmed')}
              type="submit"
            >
              Move
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
