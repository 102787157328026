import { colorValue, theme } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import { Text } from '@moonpig/launchpad-components'
import { Folder, FolderOpen } from '@styled-icons/material'
import { FC, useState } from 'react'
import { StudioGroup, StudioNode } from '../../../types'
import { useHandleDropMove } from '../hooks/dragAndDropHandler'


export const FolderIcon = styled(({ ...props }) => <Folder {...props} />)`
  color: ${colorValue('colorBlack100')};
`

const FolderOpenIcon = styled(({ ...props }) => <FolderOpen {...props} />)`
  color: ${({ theme }) => theme.colors.colorBlack100}; // Deprecated
`

const StyledPillButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 8px;

  &:hover,
  &:focus,
  &.is-active {
    background-color: ${colorValue('colorBackgroundInformation')};
  }
`

type handlePillButtonClick = () => void
type handleRootPathSelected = (node: StudioNode) => void

type PillButtonProps = {
  childRootGroup: StudioGroup
  selectedGroup: boolean
  onClick: handlePillButtonClick | handleRootPathSelected
  onMoved: () => void
}

export const PillButton: FC<PillButtonProps> = ({
  childRootGroup,
  selectedGroup,
  onClick,
  onMoved,
}) => {
  const { handleDrop } = useHandleDropMove()

  const [destinationGroup, setDestinationGroup] = useState<StudioGroup>()

  return (
    <StyledPillButton
      onDragEnter={e => {
        e.preventDefault()
        e.stopPropagation()
        setDestinationGroup(childRootGroup)
      }}
      onDragOver={e => {
        e.preventDefault()
        e.stopPropagation()
        e.currentTarget.style.backgroundColor =
          theme.colors.colorBackgroundInformation
      }}
      onDrop={e => {
        e.currentTarget.style.backgroundColor = ''
        if (destinationGroup) handleDrop(e, destinationGroup.name, onMoved)
      }}
      onDragLeave={e => (e.currentTarget.style.backgroundColor = '')}
      className={selectedGroup ? 'is-active' : ''}
      onClick={() => onClick(childRootGroup)}
    >
      {selectedGroup ? (
        <FolderIcon size="25px" />
      ) : (
        <FolderOpenIcon size="25px" />
      )}
      <Text
        ml={4}
        typography={selectedGroup ? 'typeBodyLabel' : 'typeBodyText'}
        textAlign="left"
        color="colorBlack100"
      >
        {childRootGroup.name}
      </Text>
    </StyledPillButton>
  )
}
