import { Size } from '@moonpig/renderer-react'
import { styled } from '@moonpig/launchpad-utils'
import { useElementData } from '../../data'
import { CSSProperties } from 'react'
import { Position } from './types'
import PositionableContainer from './PositionableContainer'
import {
  RESIZE_HANDLE_LOCATIONS,
  RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT,
} from './utils/misc'
import { useMachine } from '../../state/xstate'
import { useTemplateProvider } from '../../contexts/TemplateProvider'

export const TransformToolSurface = ({
  size,
  selectedElementId,
  unitsPerPixel,
  style,
  onDoubleClick,
}: {
  size: Size
  selectedElementId: string
  unitsPerPixel: number
  style: CSSProperties
  onDoubleClick: any
}) => {
  const templateProvider = useTemplateProvider()
  const { elementData, updateElement } = useElementData(selectedElementId)
  const { state } = useMachine()

  if (!elementData) {
    return <></>
  }

  const position = {
    left: (elementData!.x / unitsPerPixel).toString() + 'px',
    top: (elementData!.y / unitsPerPixel).toString() + 'px',
    width: (elementData!.width / unitsPerPixel).toString() + 'px',
    height: (elementData!.height / unitsPerPixel).toString() + 'px',
    rotation: elementData!.rotation + 'deg',
  }

  const handleInteractionStart = () => {
    templateProvider.startBatch()
  }

  const handleInteractionEnd = () => {
    templateProvider.endBatch()
  }

  const applyPrecision = (input: string): number => {
    return parseFloat((parseFloat(input) * unitsPerPixel).toFixed(2))
  }

  const handleUpdate = (position: Position) => {
    updateElement({
      x: applyPrecision(position.left),
      y: applyPrecision(position.top),
      width: applyPrecision(position.width),
      height: applyPrecision(position.height),
      rotation: parseFloat(parseFloat(position.rotation).toFixed(2)),
    })
  }

  const handleMove = (position: Position) => {
    updateElement({
      x: applyPrecision(position.left),
      y: applyPrecision(position.top),
    })
  }

  const handleResize = (position: Position) => {
    updateElement({
      x: applyPrecision(position.left),
      y: applyPrecision(position.top),
      width: applyPrecision(position.width),
      height: applyPrecision(position.height),
    })
  }

  const handleRotate = (position: Position) => {
    updateElement({
      rotation: parseFloat(parseFloat(position.rotation).toFixed(2)),
    })
  }

  const aspectRatioLocked: boolean =
    elementData?.__typename !== 'StudioTextElement'

  state.context.preserveAspectRatio = aspectRatioLocked

  /**
   * Make sure any active input elements that might interfere with the arrow buttons are blurred
   */
  const blurInputElements = () => {
    const activeElement = document.activeElement
    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      (activeElement instanceof HTMLDivElement && activeElement.contentEditable)
    ) {
      activeElement.blur()
    }
  }

  return (
    <Surface size={size} style={style}>
      <PositionableContainer
        onDoubleClick={onDoubleClick}
        onClick={blurInputElements}
        unitsPerPixel={unitsPerPixel}
        aspectRatioLock={aspectRatioLocked}
        movable
        resizable={
          aspectRatioLocked
            ? RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT
            : RESIZE_HANDLE_LOCATIONS
        }
        rotatable
        position={position}
        onUpdate={handleUpdate}
        onMove={handleMove}
        onResize={handleResize}
        onRotate={handleRotate}
        onInteractionStart={handleInteractionStart}
        onInteractionEnd={handleInteractionEnd}
      />
    </Surface>
  )
}

const Surface = styled.div<{ size: Size }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ size }) => size.width};
  height: ${({ size }) => size.height};
`
