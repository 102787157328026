import { FC } from 'react'
import { PlaygroundLink } from '../PlaygroundLink'
import { TemplateId } from '../TemplateId'
import { Flex } from '@moonpig/launchpad-components'
import { DebugFeatureToggles } from '../DebugFeatureToggles'

export const DebugToolbar: FC = () => {
  return (
    <>
      <Flex px={6}>
        <PlaygroundLink />
        <DebugFeatureToggles />
      </Flex>
      <Flex>
        <TemplateId />
      </Flex>
    </>
  )
}
