const checkFontExists = (id: string) => {
  return Array.from(document.fonts)
    .map(f => f.family)
    .includes(id)
}

const waitForFont = async (id: string): Promise<void> => {
  return new Promise<void>(resolve => {
    const eventListener = () => {
      if (checkFontExists(id)) {
        resolve()
        document.fonts.removeEventListener('loadingdone', eventListener)
        return true
      }
    }
    if (!eventListener()) {
      document.fonts.addEventListener('loadingdone', eventListener)
    }
  })
}

export { checkFontExists, waitForFont }
