import {
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type Notification = {
  id: string
  variant: 'error' | 'warning' | 'success' | 'info'
  text: string | ReactNode
  heading?: string
  icon?: 'loading'
  dismissible?: boolean
}

const NOTIFICATION_DISMISS_TIMEOUT = 2000

type Unsubscribe = () => void
type NotificationsHandler = (notifications: Notification[]) => void

export type NotificationStore = {
  getNotifications(): Notification[]
  subscribeToNotifications(handler: NotificationsHandler): Unsubscribe
  addNotification(notification: Notification): void
  dismissNotification(id: string): void
  dismissAllNotifications(): void
  dismissAllErrorNotifications(): void
  disposeTimeouts(): void
}

const createNotificationsStore = (): NotificationStore => {
  let handlers: NotificationsHandler[] = []
  let notifications: Notification[] = []
  const notificationTimeouts: number[] = []

  const setNotifications = (newNotifications: Notification[]) => {
    notifications = newNotifications
    handlers.forEach(handler => {
      handler(notifications)
    })
  }

  return {
    getNotifications: () => notifications,
    subscribeToNotifications: handler => {
      handlers.push(handler)
      return () => {
        handlers = handlers.filter(otherHandler => handler !== otherHandler)
      }
    },
    addNotification: newNotification => {
      const newNotifications = [...notifications]
      const notificationIndex = newNotifications.findIndex(
        existingNotification => {
          return existingNotification.id === newNotification.id
        },
      )

      if (newNotification.variant === 'success') {
        notificationTimeouts.push(
          window.setTimeout(() => {
            setNotifications(
              notifications.filter(
                notification => notification.id !== newNotification.id,
              ),
            )
          }, NOTIFICATION_DISMISS_TIMEOUT),
        )
      }

      if (notificationIndex >= 0) {
        newNotifications[notificationIndex] = newNotification
        setNotifications(newNotifications)

        return
      }

      setNotifications([...newNotifications, newNotification])
    },
    disposeTimeouts: () => {
      notificationTimeouts.map(notificationTimeout =>
        clearTimeout(notificationTimeout),
      )
      notificationTimeouts.splice(0, notificationTimeouts.length)
    },
    dismissNotification: id => {
      setNotifications(
        notifications.filter(notification => notification.id !== id),
      )
    },
    dismissAllNotifications: () => {
      setNotifications([])
    },
    dismissAllErrorNotifications: () => {
      setNotifications(
        notifications.filter(notification => notification.variant !== 'error'),
      )
    },
  }
}

const notificationsContext = createContext<NotificationStore>(
  createNotificationsStore(),
)

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useMemo(() => {
    return createNotificationsStore()
  }, [])
  return (
    <notificationsContext.Provider value={value}>
      {children}
    </notificationsContext.Provider>
  )
}

export const useNotifications = (): Notification[] => {
  const notificationStore = useContext(notificationsContext)
  const [notifications, setNotifications] = useState<Notification[]>(
    notificationStore.getNotifications(),
  )

  useEffect(() => {
    return notificationStore.subscribeToNotifications(newNotifications => {
      setNotifications(newNotifications)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
    return () => notificationStore.disposeTimeouts()
  }, [notificationStore])

  return notifications
}

export const useAddNotification = (): NotificationStore['addNotification'] => {
  const notificationStore = useContext(notificationsContext)
  return notificationStore.addNotification
}

export const useDismissNotification = (): ((id: string) => void) => {
  const notificationStore = useContext(notificationsContext)
  return notificationStore.dismissNotification
}

export const useDismissAllNotifications =
  (): NotificationStore['dismissAllNotifications'] => {
    const notificationStore = useContext(notificationsContext)
    return notificationStore.dismissAllNotifications
  }

export const useDismissAllErrorNotifications =
  (): NotificationStore['dismissAllErrorNotifications'] => {
    const notificationStore = useContext(notificationsContext)
    return notificationStore.dismissAllErrorNotifications
  }
