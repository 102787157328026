import { assign, createMachine } from 'xstate'
import { StudioNode } from '../types'

type MoveModalMachineContext = {
  node: StudioNode
  destination?: StudioNode
  error?: MoveNodeError
}

type MoveShowingEvent = {
  type: 'MOVE_SHOWING'
  data?: { type: 'error'; message: MoveNodeErrorTypes }
}

type MoveConfirmedEvent = {
  type: 'MOVE_CONFIRMED'
}

type MoveSetDestinationEvent = {
  type: 'MOVE_SET_DESTINATION'
  destination: StudioNode | undefined
}

type MoveCancelEvent = { type: 'MOVE_CANCEL' }

type MoveModalMachineEvents =
  | MoveShowingEvent
  | MoveConfirmedEvent
  | MoveCancelEvent
  | MoveSetDestinationEvent

type MoveModalMachineServices = {
  move:
    | { data: { type: 'success' } }
    | { data: { type: 'error'; message: MoveNodeErrorTypes } }
}

export const createMoveModalMachine = (
  context: Omit<MoveModalMachineContext, 'error' | 'destination'>,
) => {
  return createMachine(
    {
      id: 'move-modal-machine',
      initial: 'showing',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as MoveModalMachineContext,
        events: {} as MoveModalMachineEvents,
        services: {} as MoveModalMachineServices,
      },
      context: {
        error: undefined,
        destination: undefined,
        ...context,
      },
      on: {
        MOVE_SET_DESTINATION: {
          actions: [
            assign<MoveModalMachineContext, MoveSetDestinationEvent>({
              destination: (_, event) => event.destination,
            }),
          ],
        },
      },
      states: {
        showing: {
          on: {
            MOVE_CONFIRMED: {
              target: 'confirmed',
              actions: ['clearError'],
            },
            MOVE_CANCEL: {
              target: 'close',
            },
          },
        },
        confirmed: {
          invoke: {
            src: 'move',
            onDone: [{ target: 'close', actions: ['notifySuccess'] }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        close: {},
      },
    },
    {
      actions: {
        clearError: context => (context.error = undefined),
        hasError: assign({
          error: (_, event: MoveShowingEvent) => {
            if (event.data) {
              return new MoveNodeError(
                event.data.message,
                MoveNodeError.getDisplayMessage(event.data.message),
              )
            }

            return undefined
          },
        }),
      },
    },
  )
}

export type GenericMoveNodeErrorType =
  | 'StudioMoveSameDestination'
  | 'StudioMoveMissingDestination'

export type TemplateMoveNodeErrorTypes =
  | 'StudioDuplicatePathError'
  | 'StudioVersionMismatchError'
  | 'StudioMoveTemplateError'
  | 'StudioMoveTemplateFetchDataError'
  | 'StudioMoveTemplateUnknownError'
  | 'StudioMoveTemplateNoFolder'

export type GroupMoveNodeErrorTypes =
  | 'GroupNotFound'
  | 'PathNotFound'
  | 'DeleteFailed'
  | 'CreateFailed'
  | 'MaxItemsExceeded'
  | 'StudioMoveGroupUnknownError'

export type MoveNodeErrorTypes =
  | GenericMoveNodeErrorType
  | TemplateMoveNodeErrorTypes
  | GroupMoveNodeErrorTypes

export class MoveNodeError extends Error {
  public readonly products: string[] = []
  public readonly name: MoveNodeErrorTypes

  constructor(name: MoveNodeErrorTypes, message: string) {
    super(message)

    this.name = name
  }

  static getDisplayMessage(
    type: Omit<MoveNodeErrorTypes, 'StudioMoveGroupFailed'>,
  ) {
    switch (type) {
      case 'StudioMoveMissingDestination':
        return 'No destination folder has been selected.'
      case 'StudioMoveSameDestination':
        return 'Destination folder is the same as its current location.'
      case 'StudioVersionMismatchError':
        return 'Mismatch client and server versions of the template. Please, try again.'
      case 'StudioDuplicatePathError':
        return 'Destination path contains a record with the same name.'
      case 'StudioMoveTemplateNoFolder':
        return 'Failed to move template, no destination folder has been selected.'
      case 'StudioMoveTemplateFetchDataError':
        return 'Failed to fetch the template.'
      case 'GroupNotFound':
      case 'PathNotFound':
        return 'The folder you are attempting to move no longer exists.'
      case 'DeleteFailed':
        return 'We were unable to clean up after moving the folder, please contact the support team.'
      case 'CreateFailed':
        return 'Failed to move the folder to the new location.'
      case 'MaxItemsExceeded':
        return 'Folders containing more than 100 items cannot be moved at this time.'
    }

    return "We've encountered an unknown error, please contact the support team."
  }
}
