import { RefObject, useLayoutEffect, useState } from 'react'

export type Size = {
  width: number
  height: number
}

export const useElementSize = (
  elementRef: RefObject<HTMLElement>,
): Size | null => {
  const [size, setSize] = useState<Size | null>(null)

  useLayoutEffect(() => {
    const element = elementRef.current!

    const resizeObserver = new ResizeObserver(([{ contentRect }]) => {
      setSize(contentRect)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.unobserve(element)
  }, [elementRef])

  return size
}
