import { ApolloClient } from '@apollo/client'
import {
  ActorRefWithDeprecatedState,
  AnyEventObject,
  assign,
  createMachine,
  spawn,
} from 'xstate'
import { createFontUploadService } from '../../services/Font/Upload'
import { StudioUser } from '../../types'
import {
  createFontUploadMachine,
  FontUploadMachineContext,
  UploadStates,
} from './upload-machine'

export type FontUploadRef = ActorRefWithDeprecatedState<
  FontUploadMachineContext,
  AnyEventObject,
  UploadStates
>

export type FontUpload = {
  upload: File
  ref: FontUploadRef
}

export type UploadFontsMachineContext = {
  client: ApolloClient<object> | undefined
  user: StudioUser | undefined
  uploads: FontUpload[]
}

export type NewFontUploadEvent = {
  type: 'NEW_FONT_UPLOAD'
  file: File
}

export type ClearFontUploadsEvent = {
  type: 'CLEAR_FONTS'
}

export type NotifyFontUploadingEvent = {
  type: 'NOTIFY_UPLOADING'
  fontName?: string
  fileName: string
}

export type NotifyFontUploadedEvent = {
  type: 'NOTIFY_SUCCESS'
  fontName?: string
  fileName: string
}

export type NotifyFontFailedEvent = {
  type: 'NOTIFY_FAILED'
  fontName?: string
  fileName: string
}

type UploadFontMachineEvents =
  | NewFontUploadEvent
  | ClearFontUploadsEvent
  | NotifyFontUploadingEvent
  | NotifyFontUploadedEvent
  | NotifyFontFailedEvent

type UploadFontMachineServices = {}

export const createUploadFontsMachine = (
  context: UploadFontsMachineContext,
) => {
  return createMachine({
    id: 'upload-fonts-machine',
    initial: 'IDLE',
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as UploadFontsMachineContext,
      events: {} as UploadFontMachineEvents,
      services: {} as UploadFontMachineServices,
    },
    context: {
      ...context,
      uploads: [],
    },
    states: {
      IDLE: {},
      IMPORTING: {},
    },
    on: {
      NEW_FONT_UPLOAD: {
        target: 'IMPORTING',
        actions: assign<UploadFontsMachineContext, NewFontUploadEvent>({
          uploads: (context, event) => {
            const uploadService = createFontUploadService(
              context.client!,
              context.user!,
            )

            return [
              ...context.uploads,
              {
                upload: event.file,
                ref: spawn(
                  createFontUploadMachine({
                    uploadService,
                    upload: {
                      file: event.file,
                    },
                    fileName: event.file.name,
                    done: false,
                  }),
                  `upload-${event.file.name}`,
                ),
              },
            ]
          },
        }),
      },
      NOTIFY_SUCCESS: {
        actions: ['notifySuccess'],
      },
      NOTIFY_FAILED: {
        actions: ['notifyFailed'],
      },
      NOTIFY_UPLOADING: {
        actions: ['notifyUploading'],
      },
    },
  })
}
