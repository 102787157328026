import { FC, useEffect, useMemo, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { StudioTextElement, StudioElement } from '../../../types'
import { StudioDropdown as Dropdown } from './CustomDropdown'
import { useIntentProvider } from '../../../contexts/IntentProvider'

type IntentOptionsProps = {
  selectedElement: StudioTextElement
  selectedTextPartIndex: number | null
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const IntentOptions: FC<IntentOptionsProps> = ({
  selectedTextPartIndex,
  selectedElement,
  onUpdateElement,
}) => {
  const intentProvider = useIntentProvider()

  const [selectedFormat, setSelectedFormat] = useState<string | undefined>(
    undefined,
  )

  useEffect(() => {
    if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
      if (selectedTextPartIndex !== null) {
        const textpartIntent =
          selectedElement.text.textParts[selectedTextPartIndex].textIntent?.[0]
        if (textpartIntent) {
          setSelectedFormat(textpartIntent.format || undefined)
        }
      }
    } else {
      const textIntent = selectedElement.text.textIntent?.[0]
      if (textIntent) {
        setSelectedFormat(textIntent.format || undefined)
      }
    }
  }, [selectedElement, selectedTextPartIndex])

  const selectedOption = useMemo<string | undefined>(() => {
    if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
      if (selectedTextPartIndex === null) {
        return undefined
      }
      const textpartIntent =
        selectedElement.text.textParts[selectedTextPartIndex].textIntent?.[0]
          ?.intent
      return textpartIntent ?? undefined
    }

    return selectedElement.text.textIntent?.[0]?.intent
  }, [selectedElement, selectedTextPartIndex])

  const handleChange = (value: string) => {
    if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
      if (selectedTextPartIndex === null) {
        return
      }

      const textParts = [...selectedElement.text.textParts]
      const selectedTextPart = { ...textParts[selectedTextPartIndex] }

      selectedTextPart.textIntent = [
        {
          __typename: 'StudioTextIntent',
          intent: value,
        },
      ]

      textParts[selectedTextPartIndex] = selectedTextPart

      onUpdateElement({
        text: {
          __typename: 'StudioPlaceholderTextPart',
          textParts,
        },
      })

      return
    }

    onUpdateElement({
      text: {
        ...selectedElement.text,
        textIntent: [
          {
            __typename: 'StudioTextIntent',
            intent: value,
          },
        ],
      },
    })
  }

  const handleFormatChange = (value: string) => {
    onUpdateElement({
      text: {
        ...(selectedElement.text.__typename === 'StudioPlaceholderTextPart'
          ? {
              __typename: 'StudioPlaceholderTextPart',
              textParts: selectedElement.text.textParts.map((textPart, index) =>
                index === selectedTextPartIndex
                  ? {
                      ...textPart,
                      textIntent: [
                        {
                          __typename: 'StudioTextIntent',
                          intent: selectedOption!,
                          format: value,
                        },
                      ],
                    }
                  : textPart,
              ),
            }
          : {
              ...selectedElement.text,
              textIntent: [
                {
                  __typename: 'StudioTextIntent',
                  intent: selectedOption!,
                  format: value,
                },
              ],
            }),
      },
    })
  }

  const intentOptions = useMemo(() => {
    return (
      intentProvider.state.intents?.map(intent => ({
        value: intent.key,
        label: intent.value,
        disabled: intent.disabled,
        formats: intent.formats,
      })) || []
    )
  }, [intentProvider.state.intents])

  const formatOptions = useMemo(() => {
    const selectedIntent = intentProvider.state.intents?.find(
      intent => intent.key === selectedOption,
    )
    return (
      selectedIntent?.formats?.map(format => ({
        label: format?.value,
        value: format?.key,
      })) ?? []
    )
  }, [intentProvider.state.intents, selectedOption])

  const isReady = intentOptions.length > 0

  return (
    <>
      {isReady && (
        <Box paddingY={4}>
          <Dropdown
            label="Text Intent"
            name="mainOption"
            value={selectedOption || ''}
            onChange={handleChange}
            options={[
              { label: 'Select an intent...', value: '' },
              ...intentOptions,
            ]}
          />
          {formatOptions.length > 0 && (
            <Dropdown
              key={`${selectedOption}_formats`}
              label="Format"
              name="formatOption"
              value={selectedFormat || ''}
              onChange={handleFormatChange}
              options={[
                { label: 'Select a format...', value: '' },
                ...formatOptions,
              ]}
            />
          )}
        </Box>
      )}
    </>
  )
}
