import React, { FC, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Link, Text, Heading } from '@moonpig/launchpad-components'
import { colorValue, theme } from '@moonpig/launchpad-theme-mission-control'
import { system as s } from '@moonpig/launchpad-system'
import {
  IconInformationError,
  IconSystemEye as OpenEyeIcon,
  IconSystemEyeClosed as ClosedEyeIcon,
  IconSystemLoader,
} from '@moonpig/launchpad-assets'
import { CheckCircleOutline, Close as CloseIcon } from '@styled-icons/material'
import { useActor } from '@xstate/react'
import {
  ImportMachineContext,
  ImportUpload,
} from '../../../../machines/templates/import-machine'

const CheckCircleOutlineIcon = styled(({ ...props }) => (
  <CheckCircleOutline {...props} />
))`
  color: ${colorValue('colorFeedbackSuccess')};
`

const ErrorOutlineIcon = styled(IconInformationError)`
  color: ${colorValue('colorFeedbackError')};
`

const StyledUploadDialog = styled.div`
  width: 300px;
  background-color: white;
  border: 1px solid ${colorValue('colorBlack20')};

  ${s({ boxShadow: 2 })}
`

const StyledUploadDialogHeader = styled.header`
  padding: 16px;
  background-color: ${colorValue('colorBorder01')};
  color: ${colorValue('colorBackground05')};
`

const StyledUploadDialogContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 16px;
  color: ${colorValue('colorBlack100')};
`

const StyledUploadItem = styled(Flex)<{ isPending?: boolean }>`
  opacity: ${props => (props.isPending ? 0.5 : 1)};
`

const StyledText = styled(Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`

const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.colorBlack100};
  text-decoration: none;
  font-size: 16px;

  &:visited {
    color: ${({ theme }) => theme.colors.colorBlack100};
  }
`

const StyledCollapseButton = styled.button`
  height: 24px;
`

// n.b. Launchpad doesn't allow overriding icon width, height or color.
export const LoadingIndicator = styled(IconSystemLoader)`
  width: 24px;
  height: 24px;

  ${s({ color: 'colorBlack100' })}
  animation: loader-rotation infinite 2000ms linear;
  @keyframes loader-rotation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`
type UploadDialogProps = {
  uploads: ImportMachineContext['uploads']
  isImporting: boolean
  onClose: () => void
}

export const UploadDialog: FC<UploadDialogProps> = ({
  uploads,
  isImporting,
  onClose,
}) => {
  const [isContracted, handleContract] = useState(false)
  return (
    <div style={{ position: 'absolute', top: '2px', right: '8px', zIndex: 10 }}>
      <StyledUploadDialog data-testid="upload-dialog">
        <StyledUploadDialogHeader>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            minHeight="28px"
          >
            <Flex>
              <Heading
                level="h2"
                mb={0}
                paddingRight={4}
                typography="typeButtonLabel"
              >
                {isImporting ? 'Uploading' : 'Upload summary'}
              </Heading>
            </Flex>
            <Flex alignItems="center">
              <StyledCollapseButton
                type="button"
                title="collapse"
                onClick={() => handleContract(!isContracted)}
              >
                {isContracted ? (
                  <OpenEyeIcon width="24px" height="24px" />
                ) : (
                  <ClosedEyeIcon
                    color={theme.colors.colorPrimary03}
                    width="24px"
                    height="24px"
                  />
                )}
              </StyledCollapseButton>
              {!isImporting && (
                <button type="button" title="close" onClick={onClose}>
                  <CloseIcon color={theme.colors.colorPrimary03} size="28px" />
                </button>
              )}
            </Flex>
          </Flex>
        </StyledUploadDialogHeader>
        <StyledUploadDialogContent hidden={isContracted}>
          <Flex flexDirection={'column'}>
            {uploads.map(upload => (
              <UploadItem key={upload.upload.name} upload={upload} />
            ))}
          </Flex>
        </StyledUploadDialogContent>
      </StyledUploadDialog>
    </div>
  )
}

type UploadItemProps = {
  upload: ImportUpload
}

export const UploadItem: React.FC<UploadItemProps> = ({ upload }) => {
  const [state] = useActor(upload.ref)

  const icon: JSX.Element = React.useMemo(() => {
    const icons: Record<string, JSX.Element> = {
      COMPLETED: <CheckCircleOutlineIcon size="24px" />,
      FAILED: <ErrorOutlineIcon width="24px" height="24px" />,
    }

    if (icons[state.value as string]) {
      return icons[state.value as string]
    }

    return <LoadingIndicator />
  }, [state.value])

  const isPending =
    state.matches('VALIDATING') ||
    state.matches('UPLOADING') ||
    state.matches('WAITING')

  const name = state.context.upload.file.name.endsWith('.psd')
    ? state.context.upload.file.name.substring(
        0,
        state.context.upload.file.name.indexOf('.psd'),
      )
    : state.context.upload.file.name

  const templateUrl = window.location.pathname.concat('/', name)

  return (
    <StyledUploadItem
      marginY="8px"
      justifyContent="space-between"
      alignItems="center"
      isPending={isPending}
      data-testid={`import-template-${name}-container`}
    >
      <Flex>
        <Flex
          width={'18px'}
          height={'18px'}
          data-testid={`import-template-${name}-icon`}
        >
          {icon}
        </Flex>

        <Flex flexDirection="column" marginLeft={'6px'}>
          {state.matches('COMPLETED') ? (
            <StyledLink
              href={templateUrl}
              target="_blank"
              data-testid={`import-template-${name}-text`}
            >
              {name}
            </StyledLink>
          ) : (
            <StyledText
              typography="body"
              data-testid={`import-template-${name}-text`}
            >
              {name}
            </StyledText>
          )}

          {state.context.errors.length === 1 && (
            <Text
              fontSize={'12px'}
              color="colorFeedbackError"
              data-testid={`import-template-${name}-error`}
            >
              {state.context.errors[0].message}
            </Text>
          )}
        </Flex>
      </Flex>
    </StyledUploadItem>
  )
}
