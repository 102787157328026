import { ApolloClient } from '@apollo/client'
import { GET_IMPORT_STATUS_BY_ID } from '../../queries'
import {
  TemplateImportStatusByIdQuery,
  TemplateImportStatusByIdQueryVariables,
} from '../../__graphql__/queries'
import {
  StudioImportError,
  StudioTemplateImportError,
} from '../../__graphql__/types'

export type ImportStatusService = (templateId: string) => Promise<void>

export type StatusHandler = (templateId: string) => Promise<unknown>

export type StatusService = {
  status: StatusHandler
}

export type CreateStatusService = (
  client: ApolloClient<object>,
) => StatusService

export const createStatusService: CreateStatusService = client => {
  const status: StatusHandler = async (templateId: string) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          const status = await checkImportStatus(client, templateId)

          if (status) {
            clearInterval(interval)
            resolve(status)
          }
        } catch (e: unknown) {
          clearInterval(interval)
          reject(e)
        }
      }, 1000)
    }).catch(error => {
      throw error
    })
  }

  return { status }
}

export class ImportStatusError extends Error {
  public readonly errors: Array<StudioTemplateImportError | StudioImportError> =
    []

  constructor(
    name: 'StudioImportError' | 'StudioImportFailedError',
    message: string,
    errors: Array<StudioTemplateImportError | StudioImportError> = [],
  ) {
    super(message)

    this.name = name
    this.errors = errors
  }
}

const getTemplateImportStatus = async (
  client: ApolloClient<object>,
  variables: TemplateImportStatusByIdQueryVariables,
) => {
  return client.query<
    TemplateImportStatusByIdQuery,
    TemplateImportStatusByIdQueryVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    query: GET_IMPORT_STATUS_BY_ID,
    variables,
  })
}

const checkImportStatus = async (
  client: ApolloClient<object>,
  templateId: string,
) => {
  const { data } = await getTemplateImportStatus(client, { templateId })

  if (data.templateImportStatusById.__typename === 'StudioImportError') {
    throw new ImportStatusError(
      data.templateImportStatusById.__typename,
      data.templateImportStatusById.message,
    )
  }

  if (
    data.templateImportStatusById.__typename === 'StudioTemplateImportStatus'
  ) {
    if (data.templateImportStatusById.status === 'FAILED') {
      if (data.templateImportStatusById.errors) {
        const message =
          data.templateImportStatusById.errors[0].message ?? 'Unknown Error'

        throw new ImportStatusError(
          'StudioImportError',
          message,
          data.templateImportStatusById.errors,
        )
      }

      throw new ImportStatusError('StudioImportError', 'Unknown Error')
    }

    if (data.templateImportStatusById.status === 'COMPLETE') {
      return true
    }
  }

  return false
}
