import { assign, createMachine } from 'xstate'
import { StudioNode } from '../types'

type DeleteModalMachineContext = {
  node: StudioNode
  error?: DeleteNodeError
}

type DeleteShowingEvent = {
  type: 'DELETE_SHOWING'
  data?: { type: 'error'; message: string }
}
type DeleteConfirmedEvent = {
  type: 'DELETE_CONFIRMED'
}
type DeleteCancelEvent = { type: 'DELETE_CANCEL' }

type DeleteModalMachineEvents =
  | DeleteShowingEvent
  | DeleteConfirmedEvent
  | DeleteCancelEvent

type DeleteModalMachineServices = {
  delete:
    | { data: { type: 'success' } }
    | { data: { type: 'error'; message: string } }
}

export const createDeleteModalMachine = (
  context: DeleteModalMachineContext,
) => {
  return createMachine(
    {
      id: 'delete-modal-machine',
      initial: 'showing',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as DeleteModalMachineContext,
        events: {} as DeleteModalMachineEvents,
        services: {} as DeleteModalMachineServices,
      },
      context: {
        error: undefined,
        ...context,
      },
      states: {
        showing: {
          on: {
            DELETE_CONFIRMED: {
              target: 'confirmed',
              actions: ['clearError'],
            },
            DELETE_CANCEL: {
              target: 'close',
            },
          },
        },
        confirmed: {
          invoke: {
            src: 'delete',
            onDone: [{ target: 'close', actions: ['notifySuccess'] }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        close: {},
      },
    },
    {
      actions: {
        clearError: context => (context.error = undefined),
        hasError: assign({
          error: (_, event) =>
            (event as DeleteShowingEvent).data as unknown as DeleteNodeError,
        }),
      },
    },
  )
}

export type DeleteNodeErrorTypes =
  | 'StudioDeleteTemplateError'
  | 'StudioDeleteGroupError'
  | 'StudioDeleteNonEmptyGroupError'
  | 'StudioTemplateIsMappedError'

export class DeleteNodeError extends Error {
  public readonly products: string[] = []
  public readonly name: DeleteNodeErrorTypes

  constructor(
    name: DeleteNodeErrorTypes,
    message: string,
    products: string[] = [],
  ) {
    super(message)

    this.name = name
    this.products = products
  }
}
