import { useAddNotification } from '../components/Notifications'
import { SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_MB } from '../constants'

export const TEMPLATE_FILENAME_ERROR =
  "Template name contains invalid characters. Accepted characters: A-Z a-z 0-9 . _ - () & ' !."

export const FOLDER_NAME_ERROR =
  "The folder name contains invalid characters. Accepted characters: A-Z a-z 0-9 . _ - () & ' !."

export const useErrorMessages = () => {
  const addNotification = useAddNotification()

  const showFileTypeIncorrectError = (failAcceptMessage: string) => {
    addNotification({
      variant: 'error',
      id: 'FILE_UPLOAD',
      text: failAcceptMessage,
    })
  }

  const showImageSizeError = () => {
    addNotification({
      variant: 'error',
      id: 'FILE_UPLOAD',
      text: `The selected image file is too large. Files should be ${SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_MB} MB or less.`,
    })
  }

  const showFilenameError = () => {
    addNotification({
      variant: 'error',
      id: 'FILE_UPLOAD',
      text: TEMPLATE_FILENAME_ERROR,
    })
  }

  const showFolderNameError = () => {
    addNotification({
      variant: 'error',
      id: 'NEW FOLDER',
      text: FOLDER_NAME_ERROR,
    })
  }

  return {
    showImageSizeError,
    showFilenameError,
    showFolderNameError,
    showFileTypeIncorrectError,
  }
}
