import React from 'react'

type DropdownMenuContainerProps = {
  triggerRef?: React.RefObject<HTMLButtonElement>
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur' | 'onContextMenu'
>

export const DropdownMenuContainer: React.FC<DropdownMenuContainerProps> = ({
  children,
  triggerRef,
  ...rest
}) => {
  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (triggerRef?.current) {
      triggerRef.current.dispatchEvent(
        new PointerEvent('pointerdown', { bubbles: true }),
      )
    }
  }

  const handleMouseEnter = () => {
    if (triggerRef?.current) {
      triggerRef.current.style.display = 'block'
      triggerRef.current.classList.add('selected')
    }
  }

  const handleMouseLeave = () => {
    if (triggerRef?.current) {
      if (triggerRef.current.getAttribute('aria-expanded') === 'true') {
        triggerRef.current.dispatchEvent(
          new PointerEvent('pointerdown', { bubbles: true }),
        )
      }

      triggerRef.current.style.display = 'none'
      triggerRef.current.classList.remove('selected')
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Handle showing/hiding the chevron
    // when tabbing through the elements.

    const target = e.target as HTMLElement
    const relatedTarget = e.nativeEvent.relatedTarget as HTMLElement

    // Forward tabbing to next element
    if (triggerRef?.current && target?.id.includes('menu-button')) {
      triggerRef.current.style.display = 'none'
    }

    // Reverse tabbing to previous element
    if (
      triggerRef?.current &&
      target?.title.includes('Reorder') &&
      relatedTarget?.title?.includes('Select')
    ) {
      triggerRef.current.style.display = 'none'
    }
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleBlur}
      onContextMenu={handleContextMenu}
      {...rest}
    >
      {children}
    </div>
  )
}
