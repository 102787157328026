import * as React from 'react'

import { Positionable } from './Positionable'
import TransformBox from './TransformBox'
import { Position, ResizeHandleLocation } from './types'
import {
  RESIZE_HANDLE_LOCATIONS,
  RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT,
} from './utils/misc'
import { rotate, toCSS } from 'transformation-matrix'
import { deg2rad } from '../../utils/matrix'

export interface PositionableContainerProps {
  /**
   * Conversion of template units to pixels
   */
  unitsPerPixel: number
  /**
   * Lock aspect ratio
   */
  aspectRatioLock?: boolean
  className?: string

  /**
   * Should all functionality be disabled? This property takes
   * precedence over `movable`, `resizable`, and `rotatable`.
   */
  disabled?: boolean

  /** Component or HTML element to use for the container. */
  Element?: React.ComponentType<any>

  /**
   * By default, if `movable` is `true`, both mouse and keyboard movement
   * are enabled. This prop allows keyboard-based movement to be disabled.
   */
  disableKeyboardMovement?: boolean
  /**
   * Members of the same group will respond
   * to each other's drag and drop events.
   */
  group?: string

  /** Should moving be enabled? */
  movable?: boolean

  /**
   * Click event handler. If a `dnd` ref exists, it will used to track
   * the click events. Otherwise, the `container` ref will be used. This
   * is a native DOM event, not a React synthetic event.
   */
  onClick?: (e: MouseEvent) => void

  /**
   * Double click event handler. Returns number of clicks (>2)
   * @param e
   */
  onDoubleClick?: (e: number) => void

  /** Callback to notify when Positioning has changed */
  onUpdate?: (position: Position) => void

  /** Callback to notify when Positioning has changed */
  onMove?: (position: Position) => void

  /** Callback to notify when Size has changed */
  onResize?: (position: Position) => void

  /** Callback to notify when Rotation has changed */
  onRotate?: (position: Position) => void

  /** Callback to notify interaction has started */
  onInteractionStart?: () => void

  /** Callback to notify interaction has started */
  onInteractionEnd?: () => void

  /** Current Positioning (left, top, width, height, rotation) */
  position: Position

  /** Render Prop alternative to using `children` */
  render?: () => JSX.Element

  /**
   * Either an array of directions (ie. `['n', 'e', 'se']`) or
   * `true` if you want enable all directions.
   */
  resizable?: ResizeHandleLocation[]

  /** Should rotation be enabled? */
  rotatable?: boolean

  /** Snap drag and resize to pixels of this interval. */
  snapTo?: number

  /**
   * Snap horizontal drag and resize to pixels of this interval
   * (overwrites snapTo for horizontal values). Setting this value
   * to `0` disables horizontal changes.
   */
  snapXTo?: number

  /**
   * Snap vertical drag and resize to pixels of this interval
   * (overwrites snapTo for vertical values). Setting this value
   * to `0` disables vertical changes.
   */
  snapYTo?: number

  style?: React.CSSProperties
}

export const PositionableContainer: React.FC<
  React.PropsWithChildren<PositionableContainerProps>
> = ({
  unitsPerPixel,
  children,
  disabled,
  disableKeyboardMovement,
  Element = 'div',
  group,
  movable,
  onClick,
  onDoubleClick,
  onUpdate,
  onMove,
  onResize,
  onRotate,
  position,
  render,
  resizable,
  rotatable,
  snapTo,
  snapXTo,
  snapYTo,
  style,
  aspectRatioLock,
  onInteractionStart,
  onInteractionEnd,
  ...rest
}) => (
  <Positionable
    unitsPerPixel={unitsPerPixel}
    aspectRatioLock={aspectRatioLock}
    disabled={disabled}
    disableKeyboardMovement={disableKeyboardMovement}
    group={group}
    movable={movable}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    onUpdate={onUpdate}
    onInteractionStart={onInteractionStart}
    onInteractionEnd={onInteractionEnd}
    onMove={onMove}
    onResize={onResize}
    onRotate={onRotate}
    position={position}
    resizable={!!resizable}
    rotatable={rotatable}
    snapTo={snapTo}
    snapXTo={snapXTo}
    snapYTo={snapYTo}
    render={({ renderedPosition, refHandlers }) => {
      const transform: string = toCSS(
        rotate(deg2rad(parseFloat(renderedPosition.rotation))),
      )

      return (
        <React.Fragment>
          <Element
            ref={refHandlers.container}
            style={{
              ...style,
              height: `${renderedPosition.height}`,
              left: `${renderedPosition.left}`,
              position: 'absolute',
              top: `${renderedPosition.top}`,
              transform,
              width: `${renderedPosition.width}`,
            }}
            {...rest}
          >
            {render && render()}
          </Element>

          {!disabled && (resizable || rotatable || movable) && (
            <TransformBox
              unitsPerPixel={unitsPerPixel}
              position={renderedPosition}
              refHandlers={refHandlers}
              resizable={
                aspectRatioLock
                  ? RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT
                  : RESIZE_HANDLE_LOCATIONS
              }
              rotatable={rotatable}
            />
          )}
        </React.Fragment>
      )
    }}
  />
)

export default PositionableContainer
