import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_GROUP_TEMPLATE_BY_ID_QUERY } from '../queries'
import { StudioGroupTemplate } from '../types'
import {
  GetGroupTemplateQuery,
  GetGroupTemplateQueryVariables,
} from '../__graphql__/queries'

export type FetchGroupTemplateService = (
  id: string,
) => Promise<StudioGroupTemplate>

export const useFetchGroupTemplate = (): FetchGroupTemplateService => {
  const client = useApolloClient()

  return useCallback(
    async (id: string) => {
      const { data } = await client.query<
        GetGroupTemplateQuery,
        GetGroupTemplateQueryVariables
      >({
        errorPolicy: 'all',
        query: GET_GROUP_TEMPLATE_BY_ID_QUERY,
        fetchPolicy: 'no-cache',
        variables: { id },
      })

      if (data.studioTemplateById.__typename === 'StudioTemplate') {
        return data.studioTemplateById.groupMapping
      }

      throw new Error(data.studioTemplateById.__typename)
    },
    [client],
  )
}
