import {
  Alert,
  Box,
  Flex,
  Heading,
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import React, { FC, useMemo, useState } from 'react'
import { StudioNode } from '../../../types'
import { StudioTextInput } from '../../StudioTextInput'
import { CrossIconButton } from '../../Buttons'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import { StyledHeading } from '../components/Text'
import { createRenameModalMachine } from '../../../machines/rename-modal-machine'
import { useMachine } from '@xstate/react'
import { useAddNotification } from '../../Notifications'
import { useRenameTemplate } from '../../../services/Rename/Template'
import { useRenameGroup } from '../../../services/Rename/Group'

type RenameDialogProps = {
  node: StudioNode
  onRenamed: () => void
  onClose: () => void
}

export const RenameDialog: FC<RenameDialogProps> = ({
  node,
  onRenamed,
  onClose,
}) => {
  const addNotification = useAddNotification()
  const renameTemplateService = useRenameTemplate()
  const renameGroupService = useRenameGroup()

  const [inputValue, setInputValue] = useState(() => node?.name ?? '')

  const machine = React.useMemo(
    () => createRenameModalMachine({ node }),
    [node],
  )
  const [state, send] = useMachine(machine, {
    actions: {
      notifySuccess: context => {
        const type =
          context.node.__typename === 'StudioGroup' ? 'Folder' : 'Template'

        addNotification({
          id: 'RENAMING',
          text: `${type} renamed successfully.`,
          variant: 'success',
        })

        onRenamed()
      },
      notifyError: context => {
        if (context.error) {
          if (['StudioInvalidNameError'].includes(context.error.name)) {
            return
          }

          addNotification({
            id: 'RENAMING',
            text: context.error?.message,
            variant: 'error',
          })

          onClose()
        }
      },
    },
    services: {
      rename: async () => {
        if (node.__typename === 'StudioGroupTemplate') {
          await renameTemplateService(node, inputValue)
        }

        if (node.__typename === 'StudioGroup') {
          await renameGroupService(node, inputValue)
        }
      },
    },
  })

  const onRename = () => {
    send({ type: 'RENAME_CONFIRMED', name: inputValue })
  }

  const nodeType = useMemo(() => {
    switch (node.__typename) {
      case 'StudioGroup':
        return 'folder'
      case 'StudioGroupTemplate':
        return 'template'
    }
  }, [node])

  const isRenameDisabled = useMemo(
    () => node.name === inputValue,
    [node, inputValue],
  )

  const shouldShowError = state.context.error?.name === 'StudioInvalidNameError'

  return (
    <>
      <OverrideReachModalPadding />
      <Modal
        testId={`rename-${nodeType}-dialog`}
        label={`Rename ${nodeType}`}
        isOpen={true}
      >
        <Flex
          width="400px"
          flexDirection="column"
          justifyContent="space-between"
          as="form"
          onSubmit={e => {
            e.preventDefault()
            onRename()
          }}
        >
          <StyledHeading width={'100%'} level="h2" fontWeight={400}>
            Rename {nodeType}
            <CrossIconButton
              data-testid="context-menu-close-icon"
              onClick={onClose}
            />
          </StyledHeading>

          {shouldShowError && (
            <Box paddingX={6} paddingTop={6}>
              <Alert variant="error">
                <Heading level="h4" mb={0}>
                  Oops!
                </Heading>
                <span>{state.context.error?.message}</span>
              </Alert>
            </Box>
          )}

          <Box paddingX={6} paddingY={6}>
            <StudioTextInput
              width="100%"
              label={`New ${nodeType} name`}
              name="name"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <Flex marginTop={7} width="100%" justifyContent="space-between">
              <TertiaryButton
                width="100%"
                marginRight="6px"
                onClick={onClose}
                type="button"
              >
                Cancel
              </TertiaryButton>
              <PrimaryButton
                disabled={isRenameDisabled || state.matches('confirmed')}
                loading={state.matches('confirmed')}
                width="100%"
                marginLeft="6px"
                type="submit"
              >
                Rename
              </PrimaryButton>
            </Flex>
          </Box>
        </Flex>
      </Modal>
    </>
  )
}
