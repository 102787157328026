import { assign, createMachine, DoneInvokeEvent } from 'xstate'
import { TEMPLATE_NAME_VALIDATION_REGEX } from '../constants'
import { TEMPLATE_FILENAME_ERROR } from '../hooks/useErrorMessages'
import { DuplicateTemplateService } from '../services/DuplicateTemplate'

type DuplicateModalMachineContext = {
  id: string
  parentPath: string
  error?: DuplicateNodeError
}

type DuplicateShowingEvent = {
  type: 'DUPLICATE_SHOWING'
  data?: { type: 'error'; message: string }
}

type DuplicateConfirmedEvent = {
  type: 'DUPLICATE_CONFIRMED'
  name: string
}

type DuplicateCancelEvent = { type: 'DUPLICATE_CANCEL' }

export type DuplicateDoneEvent = DoneInvokeEvent<
  {} & Awaited<ReturnType<DuplicateTemplateService>>
>

type DuplicateModalMachineEvents =
  | DuplicateShowingEvent
  | DuplicateConfirmedEvent
  | DuplicateCancelEvent

type DuplicateModalMachineServices = {
  move:
    | { data: { type: 'success' } }
    | { data: { type: 'error'; message: string } }
}

export const createDuplicateModalMachine = (
  context: Omit<DuplicateModalMachineContext, 'error'>,
) => {
  return createMachine(
    {
      id: 'duplicate-modal-machine',
      initial: 'showing',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as DuplicateModalMachineContext,
        events: {} as DuplicateModalMachineEvents,
        services: {} as DuplicateModalMachineServices,
      },
      context: {
        error: undefined,
        ...context,
      },
      states: {
        showing: {
          on: {
            DUPLICATE_CONFIRMED: {
              target: 'validate',
              actions: ['clearError'],
            },
            DUPLICATE_CANCEL: {
              target: 'close',
            },
          },
        },
        validate: {
          invoke: {
            src: 'validate',
            onDone: [{ target: 'confirmed' }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        confirmed: {
          invoke: {
            src: 'duplicate',
            onDone: [{ target: 'close', actions: ['notifySuccess'] }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        close: {},
      },
    },
    {
      actions: {
        clearError: context => (context.error = undefined),
        hasError: assign({
          error: (_, event: DuplicateShowingEvent) => {
            if (event.data) {
              if (event.data.message === 'StudioInvalidNameError') {
                return new DuplicateNodeError(
                  event.data.message,
                  TEMPLATE_FILENAME_ERROR,
                )
              }

              const error = event.data.message.split('#')

              return new DuplicateNodeError(error[0], error[1])
            }

            return undefined
          },
        }),
      },
      services: {
        validate: (_, event) => {
          if (event.type === 'DUPLICATE_CONFIRMED') {
            const isNameValid = TEMPLATE_NAME_VALIDATION_REGEX.test(event.name)

            if (!isNameValid) {
              return Promise.reject(new Error('StudioInvalidNameError'))
            }
          }

          return Promise.resolve()
        },
      },
    },
  )
}

export class DuplicateNodeError extends Error {
  public readonly name: string

  constructor(name: string, message: string) {
    super(message)

    this.name = name
  }
}
