import { ChangeEvent, FC, RefObject, useCallback } from 'react'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import { checkAcceptString } from '../utils/checkMimeTypeInFileBytes'
import { useErrorMessages } from '../hooks'

const StyledFileInput = styled.input`
  ${screenReaderOnly}
`

const StyledImportInput = styled.div`
  display: block;
  user-select: none;
`

type ImportInputProps = {
  inputRef: RefObject<HTMLInputElement>
  id: string
  onChange(fileList: FileList | null): void
  accept: string
  failAcceptMessage: string
  label?: React.ReactNode
  multiple?: boolean
  validate?: boolean
}

export const ImportInputButton: FC<ImportInputProps> = ({
  inputRef,
  id,
  accept,
  failAcceptMessage,
  onChange,
  multiple = false,
  validate = false,
}) => {
  const { showFileTypeIncorrectError } = useErrorMessages()

  const handleInputChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (validate) {
        e.target.removeAttribute('file-checked')

        const fileTypeCheckPromises = Array.from(
          e.target.files as FileList,
        ).map((file: File) =>
          checkAcceptString(accept, file)
            .then(checkOk => {
              if (!checkOk) {
                return Promise.reject()
              }
            })
            .catch(() => {
              return Promise.reject()
            }),
        )

        await Promise.all(fileTypeCheckPromises)
          .then(() => {
            onChange(inputRef.current!.files)
          })
          .catch(() => {
            showFileTypeIncorrectError(failAcceptMessage)
          })
          .finally(() => {
            e.target.setAttribute('file-checked', 'true')
            e.target.value = ''
          })
      } else {
        try {
          onChange(inputRef.current!.files)
        } finally {
          e.target.value = ''
        }
      }
    },
    [
      onChange,
      showFileTypeIncorrectError,
      accept,
      failAcceptMessage,
      validate,
      inputRef,
    ],
  )

  return (
    <StyledImportInput>
      <StyledFileInput
        multiple={multiple}
        aria-hidden
        tabIndex={-1}
        ref={inputRef}
        id={id}
        type="file"
        data-testid={id}
        accept={accept}
        onChange={handleInputChange}
      />
    </StyledImportInput>
  )
}
