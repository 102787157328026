import { FC } from 'react'
import { Alert, Heading } from '@moonpig/launchpad-components'
import { useNotifications } from './NotificationsProvider'
import { useDismissNotification } from '.'
import { IconSystemLoader } from '@moonpig/launchpad-assets'
import * as Portal from '@radix-ui/react-portal'
import { styled } from '@moonpig/launchpad-utils'

export const StyledIconLoader = styled(IconSystemLoader)`
  width: 28px;
  height: 28px;

  animation: loader-rotation infinite 2000ms linear;
  @keyframes loader-rotation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`

export const Notifications: FC = () => {
  const notifications = useNotifications()
  const dismissNotification = useDismissNotification()

  const icon = (icon?: 'loading') => {
    if (icon === 'loading') {
      return StyledIconLoader
    }

    return undefined
  }

  return (
    <Portal.Root>
      {notifications.length > 0 && (
        <div
          style={{
            position: 'absolute',
            top: 210,
            left: 0,
            right: 0,
            zIndex: 11,
            pointerEvents: 'none',
          }}
        >
          <div
            style={{
              marginLeft: 450,
              marginRight: 450,
              pointerEvents: 'all',
            }}
          >
            {notifications.map(notification => (
              <Alert
                key={notification.id}
                icon={icon(notification.icon)}
                variant={notification.variant}
                onClickClose={
                  notification?.dismissible ?? true
                    ? () => dismissNotification(notification.id)
                    : undefined
                }
                mb={4}
                maxWidth={500}
                marginX="auto"
              >
                {notification.heading && (
                  <Heading level="h4" mb={0}>
                    {notification.heading}
                  </Heading>
                )}
                <span>{notification.text}</span>
              </Alert>
            ))}
          </div>
        </div>
      )}
    </Portal.Root>
  )
}
