import { Box, Flex } from '@moonpig/launchpad-components'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { FC } from 'react'
import { StudioTextInput } from '../../../StudioTextInput'

type MaxCharactersOptionProps = {
  index?: number
  checked: boolean
  value: number
  onUpdateCheckedState: (value: boolean) => void
  onUpdateValue: (value: number) => void
}

export const MaxCharactersOption: FC<MaxCharactersOptionProps> = ({
  index = 0,
  checked,
  value,
  onUpdateCheckedState,
  onUpdateValue,
}) => {
  return (
    <Flex justifyContent="space-between">
      <FormControlLabel
        name={`enable-max-characters-placeholder-checkbox-${index}`}
        label="Max characters"
        id={`enable-max-characters-placeholder-checkbox-${index}`}
        data-testid={`enable-max-characters-placeholder-checkbox-${index}`}
      >
        <Checkbox
          onChange={() => {
            onUpdateCheckedState(!checked)
          }}
          checked={checked}
          value="max-character-enable"
        />
      </FormControlLabel>

      {checked && (
        <Box maxWidth="100px">
          <StudioTextInput
            data-testid={`max-characters-placeholder-${index}`}
            marginX={4}
            marginY={2}
            type="number"
            name={`element-max-characters`}
            value={value.toString()}
            onChange={e => {
              onUpdateValue(Number(e.target.value))
            }}
            min={0}
            step={1}
          />
        </Box>
      )}
    </Flex>
  )
}
