import { useCallback } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import polly from 'polly-js'
import { DUPLICATE_TEMPLATE } from '../mutations'
import {
  DuplicateTemplateMutation,
  DuplicateTemplateMutationVariables,
} from '../__graphql__/mutations'
import { StudioDuplicatedTemplate } from '../__graphql__/types'

const duplicateTemplate = async (
  client: ApolloClient<object>,
  input: {
    id: string
    name: string
    parentPath: string
  },
) => {
  const { data } = await client.mutate<
    DuplicateTemplateMutation,
    DuplicateTemplateMutationVariables
  >({
    errorPolicy: 'all',
    mutation: DUPLICATE_TEMPLATE,
    variables: {
      input,
    },
  })

  if (data?.duplicateTemplate.__typename === 'StudioDuplicatedTemplate') {
    return data.duplicateTemplate
  }

  if (data?.duplicateTemplate.__typename === 'StudioDuplicateTemplateError') {
    throw new Error(
      [
        data?.duplicateTemplate.__typename,
        'A template with the specified name already exists.',
      ].join('#'),
    )
  }

  throw new Error(
    'StudioDuplicateTemplateError#There was a problem saving the new template.',
  )
}

export type DuplicateTemplateService = (
  id: string,
  name: string,
  parentPath: string,
) => Promise<StudioDuplicatedTemplate>

export const useDuplicateTemplate = (): DuplicateTemplateService => {
  const client = useApolloClient()

  return useCallback(
    async (id, name, parentPath) => {
      return polly()
        .handle(err => err.message !== 'StudioDuplicateTemplateError')
        .waitAndRetry(5)
        .executeForPromise(async () =>
          duplicateTemplate(client, { id, name, parentPath }),
        )
    },
    [client],
  )
}
