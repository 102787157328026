import {
  StudioHorizontalAlignment,
  StudioVerticalAlignment,
} from './__graphql__/types'
import { StudioTextElement } from './types'

export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
export const OKTA_REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URL
export const GRAPHQL_GATEWAY_URL = process.env.REACT_APP_GRAPHQL_GATEWAY_URL

export const RECENTLY_OPENED_KEY = 'studio.recently-opened'
export const RECENTLY_OPENED_KEY_V2 = 'studio.recently-opened.v2'
export const MAX_RECENTLY_OPENED = 50

export const CLICK_TARGET_MINIMUM = 40
export const STUDIO_BG_GREY = '#F8F8FB'

export const INLINE_TEXT_EDITING_FONT_SCALE = 2.83

export const SUPPORTED_UPLOAD_IMAGE_MIME_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
]

export const SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_MB = 15
export const SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES =
  1024 * 1024 * SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_MB

export const UNSET_ASSET_KEY = ''

export const PHOTOSHOP_MIME_TYPES: string[] = [
  'image/vnd.adobe.photoshop',
  'application/x-photoshop',
]

export const LAYER_IMAGE_ACCEPTED_EXTENSIONS: string[] = [
  '.jpeg',
  '.png',
].concat(SUPPORTED_UPLOAD_IMAGE_MIME_TYPES)

export const IMPORTER_ACCEPTED_EXTENSIONS: string[] = ['.psd'].concat(
  PHOTOSHOP_MIME_TYPES,
)

export const TEMPLATE_NAME_VALIDATION_REGEX = /^([\w\s\d.—–-])+(?<! )$/i
export const FOLDER_NAME_VALIDATION_REGEX = /^([\w\s\d.—–()&'!-])+$/i

export const CREATE_PSD_IMPORT_ATTEMPTS = 5
export const UPLOAD_PSD_ATTEMPTS = 5
export const UPDATE_TEMPLATE_ATTEMPTS = 5
export const UPLOAD_IMAGE_ATTEMPTS = 5

export const DEFAULT_FONT_URLS: {
  [key: string]: string
} = {
  dev: 'https://font.studio.dev.moonpig.net/Work+Sans+Regular_278d8b42-dc9c-b5f9-4a14-e54d625a5214.source',
  uat: 'https://font.studio.uat.moonpig.net/Work+Sans+Regular_6003a7be-cd8e-9cf7-1570-44561b5981b2.source',
  prod: 'https://font.studio.prod.moonpig.net/Work+Sans_c7ddfacd-a59a-4f79-87c3-55cbff92069f.source',
}

export const DEFAULT_FONT_NAMES: {
  [key: string]: string
} = {
  dev: 'Work Sans Regular',
  uat: 'Work Sans Regular',
  prod: 'Work Sans',
}

export const DEFAULT_DRAGGABLE_TEXT_TITLE = 'Text'

export const UNDO_LIMIT = 500

export const MIN_ZOOM = 1
export const MAX_ZOOM = 4
export const ZOOM_STEP = 0.1
export const ZOOM_SLIDER_STEP = 0.01

export const DEFAULT_TEXT_ELEMENT: StudioTextElement = {
  __typename: 'StudioTextElement',
  name: DEFAULT_DRAGGABLE_TEXT_TITLE,
  x: 0,
  y: 0,
  width: 300,
  height: 300,
  rotation: 0,
  lineSpacing: 20,
  visible: true,
  text: {
    __typename: 'StudioStaticTextPart',
    text: DEFAULT_DRAGGABLE_TEXT_TITLE,
  },
  horizontalAlignment: StudioHorizontalAlignment.CENTER,
  verticalAlignment: StudioVerticalAlignment.MIDDLE,
  shadow: {
    __typename: 'StudioTextShadow',
    color: {
      __typename: 'StudioColor',
      name: 'Black',
      hex: '#000000',
    },
    offsetY: 0,
    offsetX: 0,
  },
  font: {
    id: DEFAULT_FONT_NAMES[process.env.REACT_APP_MOONPIG_ENV || 'dev'],
    displayName: DEFAULT_FONT_NAMES[process.env.REACT_APP_MOONPIG_ENV || 'dev'],
    urls: {
      source: DEFAULT_FONT_URLS[process.env.REACT_APP_MOONPIG_ENV || 'dev'],
      __typename: 'StudioFontUrls',
    },
    __typename: 'StudioFont',
  },
  fontSize: 18,
  color: {
    __typename: 'StudioColor',
    name: 'Black',
    hex: '#000000',
  },
}
