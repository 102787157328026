import React, { createContext, FC, PropsWithChildren, useContext } from 'react'

type SetShowCustomisationsAction = {
  type: 'SET_SHOW_CUSTOMISATIONS'
}

type SetHideCustomisationsAction = {
  type: 'SET_HIDE_CUSTOMISATIONS'
}

type SetZoomAction = {
  type: 'SET_ZOOM'
  value: number
}

type SetShowFontAlternativesPopup = {
  type: 'SET_SHOW_FONT_ALTERNATIVES_POPUP'
}

type SetHideFontAlternativesPopup = {
  type: 'SET_HIDE_FONT_ALTERNATIVES_POPUP'
}

type Action =
  | SetShowCustomisationsAction
  | SetHideCustomisationsAction
  | SetShowFontAlternativesPopup
  | SetHideFontAlternativesPopup
  | SetZoomAction

type ActionDispatch = (action: Action) => void

type State = {
  customisationsVisible: boolean
  zoom: number
  showFontAlternativesPopup: boolean
}

type Context =
  | {
      state: State
      dispatch: ActionDispatch
    }
  | undefined

const SceneContext = createContext<Context>(undefined)

const reducer = (state: State, action: Action): State => {
  if (action.type === 'SET_SHOW_CUSTOMISATIONS') {
    return {
      ...state,
      customisationsVisible: true,
    }
  } else if (action.type === 'SET_HIDE_CUSTOMISATIONS') {
    return {
      ...state,
      customisationsVisible: false,
    }
  } else if (action.type === 'SET_ZOOM') {
    return {
      ...state,
      zoom: action.value,
    }
  } else if (action.type === 'SET_SHOW_FONT_ALTERNATIVES_POPUP') {
    return {
      ...state,
      showFontAlternativesPopup: true,
    }
  } else if (action.type === 'SET_HIDE_FONT_ALTERNATIVES_POPUP') {
    return {
      ...state,
      showFontAlternativesPopup: false,
    }
  }

  return state
}

export const SceneProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    customisationsVisible: false,
    zoom: 1,
    showFontAlternativesPopup: false,
  })

  return (
    <SceneContext.Provider value={{ state, dispatch }}>
      {children}
    </SceneContext.Provider>
  )
}

export const useSceneProvider = () => {
  const context = useContext(SceneContext)

  if (context === undefined) {
    throw new Error('useSceneProvider must be used within a SceneProvider')
  }

  return context
}
