import { FC, RefObject, useMemo, useRef } from 'react'
import { useMachine } from '../../../state/xstate'
import { styled } from '@moonpig/launchpad-utils'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { Flex, PrimaryButton, Text } from '@moonpig/launchpad-components'
import { SortAsc, SortDesc } from '@styled-icons/octicons'
import { Close, ViewList } from '@styled-icons/material'
import { GridView } from '@styled-icons/material-sharp'
import { Breadcrumbs } from './Breadcrumbs'
import { SearchInput } from './SearchInput'
import { IconButton } from './Buttons'
import { ImportInputButton } from '../../ImportInputButton'
import {
  SortByTypes,
  useTemplateExplorer,
  ViewByTypes,
} from '../TemplateExplorerProvider'
import { IMPORTER_ACCEPTED_EXTENSIONS } from '../../../constants'
import { PSD_UPLOAD_FAILED_ACCEPT_MESSAGE } from '../../../strings'
import { pathToURI } from '../../../utils/path'
import { useImportMachine } from '../UploadMachineProvider'
import { NewUploadEvent } from '../../../machines/templates/import-machine'
import { UploadDialog } from './Upload/UploadDialog'
import { useActor } from '@xstate/react'
import { StudioNode } from '../../../types'
import { useFontUploadMachine } from '../UploadFontMachineProvider'
import { NewFontUploadEvent } from '../../../machines/fonts/import-machine'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '../../Dropdown/Dropdown'
import { useNavigate } from 'react-router-dom'
import { useTemplateProvider } from '../../../contexts/TemplateProvider'

const TEMPLATE_FILE_INPUT_ID = 'std-import-file-input'
const FONT_FILE_INPUT_ID = 'std-font-file-input'

type Actions = {
  createNewFolder: boolean
  uploadTemplate: boolean
  uploadFont: boolean
}

const CloseIcon = styled(({ ...props }) => <Close {...props} />)`
  color: ${colorValue('colorBlack100')};
`

const StyledDialogHeader = styled.header`
  border-bottom: 1px solid ${colorValue('colorBlack20')};
  background-color: ${colorValue('colorBackground02')};
`

const StyledHeaderButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.colorBlack100};

  &.is-active {
    color: ${({ theme }) => theme.colors.outlinedCtaTwoContrast};
  }
`

const StyledDialogHeaderContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`

const UploadFontsButton: FC<{
  inputRef: RefObject<HTMLInputElement>
}> = props => {
  const { uploadFontMachineService } = useFontUploadMachine()
  const handleUploadFonts = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      const events: NewFontUploadEvent[] = Array.from(fileList).map(file => {
        return {
          type: 'NEW_FONT_UPLOAD',
          file,
        }
      })

      uploadFontMachineService.send(events)
    }
  }

  return (
    <ImportInputButton
      inputRef={props.inputRef}
      id={FONT_FILE_INPUT_ID}
      accept={'.ttf'}
      failAcceptMessage={'Only .ttf files are accepted'}
      onChange={files => handleUploadFonts(files)}
      validate={false}
      multiple
    />
  )
}

const UploadTemplatesButton: FC<{
  inputRef: RefObject<HTMLInputElement>
}> = props => {
  const templateExplorer = useTemplateExplorer()
  const { importMachineService } = useImportMachine()

  const handleUploadTemplate = (fileList: FileList | null) => {
    if (fileList && fileList.length > 0) {
      const events: NewUploadEvent[] = Array.from(fileList).map(file => {
        return {
          type: 'NEW_UPLOAD',
          file,
          currentGroup: templateExplorer.state.currentGroup,
        }
      })

      importMachineService.send(events)
    }
  }

  return (
    <ImportInputButton
      inputRef={props.inputRef}
      id={TEMPLATE_FILE_INPUT_ID}
      accept={IMPORTER_ACCEPTED_EXTENSIONS.join()}
      failAcceptMessage={PSD_UPLOAD_FAILED_ACCEPT_MESSAGE}
      onChange={files => handleUploadTemplate(files)}
      multiple
    />
  )
}

const HeaderDropdownMenu: FC<{ actions: Actions }> = ({ actions }) => {
  const templatesInputRef = useRef<HTMLInputElement>(null)
  const fontsInputRef = useRef<HTMLInputElement>(null)

  const templateExplorer = useTemplateExplorer()

  const showMenu = useMemo(
    () => Object.values(actions).some(action => action),
    [actions],
  )

  const handleNewFolder = () => {
    templateExplorer.dispatch({
      type: 'SET_ACTIONABLE_NODE',
      payload: {
        action: 'create',
        node: templateExplorer.state.currentGroup,
      },
    })
  }

  const handleUploadTemplates = () => {
    if (templatesInputRef.current) {
      templatesInputRef.current.click()
    }
  }

  const handleUploadFonts = () => {
    if (fontsInputRef.current) {
      fontsInputRef.current.click()
    }
  }

  return (
    <>
      <UploadTemplatesButton inputRef={templatesInputRef} />
      <UploadFontsButton inputRef={fontsInputRef} />

      {showMenu && (
        <DropdownMenu
          trigger={({ isExpanded }) => (
            <PrimaryButton>
              <Flex
                paddingLeft={'8px'}
                paddingRight={'8px'}
                justifyContent="space-between"
                alignItems="center"
              >
                <Text typography={'typeBodyText'}>New</Text>
                <Flex ml={8}>
                  {isExpanded ? (
                    <IconSystemChevronUp width={'25px'} height={'25px'} />
                  ) : (
                    <IconSystemChevronDown width={'25px'} height={'25px'} />
                  )}
                </Flex>
              </Flex>
            </PrimaryButton>
          )}
        >
          {actions.createNewFolder && (
            <>
              <DropdownMenuItem onClick={handleNewFolder}>
                New Folder
              </DropdownMenuItem>

              <DropdownMenuSeparator />
            </>
          )}

          {actions.uploadTemplate && (
            <DropdownMenuItem onSelect={handleUploadTemplates}>
              Upload Templates
            </DropdownMenuItem>
          )}
          {actions.uploadFont && (
            <DropdownMenuItem onSelect={handleUploadFonts}>
              Upload Fonts
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      )}
    </>
  )
}

const MultipleUploadsDialog = () => {
  const { importMachineService } = useImportMachine()
  const [state, send] = useActor(importMachineService)

  return !state.matches('IDLE') ? (
    <UploadDialog
      uploads={state.context.uploads}
      isImporting={state.matches('IMPORTING')}
      onClose={() => send('CLEAR_IMPORTS')}
    />
  ) : null
}

export const Header: FC<{
  onGroupSelect: (node: StudioNode, newWindow: boolean) => void
  search: {
    onEnterDown: (searchString: string) => void
    onChange: (searchString: string) => void
  }
  actions: Actions
  onMoved: () => void
}> = ({ search, onGroupSelect, actions, onMoved }) => {
  const navigate = useNavigate()
  const { send } = useMachine()
  const templateExplorer = useTemplateExplorer()
  const templateProvider = useTemplateProvider()

  const previousPath = useMemo(() => {
    if (templateProvider.state.template) {
      return templateProvider.state.template.groupMapping.path
    }

    return undefined
  }, [templateProvider.state.template])

  const shouldDisplayCloseButton = useMemo(() => {
    return Boolean(previousPath)
  }, [previousPath])

  const onClose = () => {
    const path = previousPath ? pathToURI(previousPath) : undefined
    path &&
      navigate({
        pathname: `/${path}`,
        search: window.location.search,
      })
    send({ type: 'CLOSE_TEMPLATE_EXPLORER' })
  }

  const onViewBy = (viewBy: ViewByTypes) => {
    templateExplorer.dispatch({
      type: 'SET_VIEW_BY',
      payload: {
        viewBy,
      },
    })
  }

  const onSortBy = (sortBy: SortByTypes) => {
    templateExplorer.dispatch({
      type: 'SET_SORT_BY',
      payload: {
        sortBy,
      },
    })
  }

  return (
    <StyledDialogHeader>
      <StyledDialogHeaderContent>
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <Flex flex={1}>
            <HeaderDropdownMenu actions={actions} />
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <Flex alignItems="center" mr={4}>
              <IconButton
                disabled={templateExplorer.state.sortBy === 'DESC'}
                className={
                  templateExplorer.state.sortBy === 'DESC'
                    ? 'is-active'
                    : undefined
                }
                contrastColor="colorPrimary02"
                onClick={() => onSortBy('DESC')}
                aria-label="Sort descending"
              >
                <SortDesc size="30px" />
              </IconButton>
              <IconButton
                ml={3}
                disabled={templateExplorer.state.sortBy === 'ASC'}
                className={
                  templateExplorer.state.sortBy === 'ASC'
                    ? 'is-active'
                    : undefined
                }
                contrastColor="colorPrimary02"
                onClick={() => onSortBy('ASC')}
                aria-label="Sort ascending"
              >
                <SortAsc size="30px" />
              </IconButton>
              <IconButton
                ml={6}
                disabled={templateExplorer.state.viewBy === 'GRID'}
                className={
                  templateExplorer.state.viewBy === 'GRID'
                    ? 'is-active'
                    : undefined
                }
                contrastColor="colorPrimary02"
                onClick={() => onViewBy('GRID')}
                aria-label="View as grid"
              >
                <GridView size="30px" />
              </IconButton>
              <IconButton
                ml={3}
                disabled={templateExplorer.state.viewBy === 'LIST'}
                className={
                  templateExplorer.state.viewBy === 'LIST'
                    ? 'is-active'
                    : undefined
                }
                contrastColor="colorPrimary02"
                onClick={() => onViewBy('LIST')}
                aria-label="View as list"
              >
                <ViewList size="30px" />
              </IconButton>
            </Flex>
            <SearchInput
              onChange={async e => {
                search.onChange(e.target.value)
              }}
              onEnterDown={search.onEnterDown}
            />
            <Flex ml={4}>
              <StyledHeaderButton
                disabled={!shouldDisplayCloseButton}
                onClick={onClose}
                style={{
                  visibility: shouldDisplayCloseButton ? 'visible' : 'hidden',
                }}
                aria-label="Close"
              >
                <CloseIcon size="30px" />
              </StyledHeaderButton>
            </Flex>
          </Flex>
        </Flex>
      </StyledDialogHeaderContent>
      <Flex
        p={6}
        justifyContent="space-between"
        position="relative"
        /* Bug with Launchpad */
        {...{ style: { backgroundColor: 'white' } }}
      >
        {templateExplorer.state.currentGroup.id === 'recently-opened' ? (
          <Text
            typography={'typeBodyLabel'}
            color="colorBlack100"
            data-testid="recently-opened-breadcrumb"
          >
            Recently Opened
          </Text>
        ) : (
          <Breadcrumbs
            type="folder"
            path={templateExplorer.state.currentGroup.path}
            onClick={newGroupPath => {
              onGroupSelect(
                {
                  __typename: 'StudioGroup',
                  path: newGroupPath,
                  id: '',
                  name: '',
                  parentPath: '',
                },
                false,
              )
            }}
            onMoved={onMoved}
          />
        )}
        <MultipleUploadsDialog />
      </Flex>
    </StyledDialogHeader>
  )
}
