import React from 'react'
import { styled } from '@moonpig/launchpad-utils'

type Maybe<T> = T | null | undefined

interface CustomOption {
  value: string
  label: string
  disabled?: Maybe<boolean>
}

interface CustomDropdownProps {
  label: string
  name: string
  value: string
  onChange: (value: string) => void
  options: CustomOption[]
  placeholder?: string
  id?: string
  disabled?: boolean
  required?: boolean
  helperText?: string
  descriptorText?: string
  error?: boolean
  testId?: string
  style?: React.CSSProperties
  className?: string
}

const Container = styled.div`
  margin-bottom: 16px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`

const Select = styled.select<{ disabled?: boolean }>`
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : 'white')};
  color: ${({ disabled }) => (disabled ? '#ccc' : 'black')};
  font-family: inherit;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`

const Option = styled.option<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#999' : 'black')};
  background-color: ${({ disabled }) => (disabled ? '#f3f3f3' : 'white')};
`

export const StudioDropdown: React.FC<CustomDropdownProps> = ({
  label,
  name,
  value,
  onChange,
  options,
  id,
  disabled,
  required,
  style,
  className,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value)
  }

  return (
    <Container style={style} className={className}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        name={name}
        id={id || name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={required}
      >
        {options.map(option => (
          <Option
            key={option.value}
            value={option.value}
            disabled={option.disabled === null ? undefined : option.disabled}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Container>
  )
}
