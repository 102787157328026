import React, { FC } from 'react'
import { useMachine } from '../../state/xstate'
import { InteractiveEditableTextFrame } from '../InteractiveEditableTextFrame'
import { StudioTextElement } from '../../types'
import { isPlaceholderTextPart, isTextElement } from '../../utils'
import { InteractivePlaceholderTextFrame } from '../InteractivePlaceholderTextFrame/InteractivePlaceholderTextFrame'

type InteractiveEditableTextProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
  elementData: StudioTextElement
}

export const InteractiveEditableText: FC<InteractiveEditableTextProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
  unitsPerPixel,
  elementData,
}) => {
  const { state, send } = useMachine()
  const selectedElementId = state.context.selectedElementId!
  const isSelected = id === selectedElementId

  const editingText =
    state.matches({ STUDIO: { ELEMENT_SELECTED: 'EDITING_TEXT' } }) &&
    isSelected

  const handleClick = () => {
    if (!editingText) {
      send({ type: 'SELECT_ELEMENT', id })
    }
  }

  const commonProps = {
    editingText,
    unitsPerPixel,
    ariaLabel: 'Edit Text',
    selected: isSelected,
    onClick: handleClick,
    elementDataId: id,
    frame: { x, y, width, height, rotation },
  }

  if (!isTextElement(elementData)) {
    return null
  }

  return isPlaceholderTextPart(elementData.text) ? (
    <InteractivePlaceholderTextFrame key={id} {...commonProps} />
  ) : (
    <InteractiveEditableTextFrame key={id} {...commonProps} />
  )
}
