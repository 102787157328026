/**
 * Extends the LoginCallback component
 * provided by okta-react in order
 * to handle cases where the user
 * is authenticated but is not assigned
 * to the Studio application.
 * This extension is the approach
 * Okta suggests, see https://github.com/okta/okta-react#logincallback.
 */
import * as React from 'react'
import { styled, ThemeProvider } from '@moonpig/launchpad-utils'
import { Link, Text } from '@moonpig/launchpad-components'
import { useOktaAuth } from '@okta/okta-react'
import { studioTheme } from '../theme'

const TECHBAR_URL = 'https://moonpigsupport.zendesk.com'

interface LoginCallbackProps {
  onAuthResume?: () => void
  loadingElement?: React.ReactElement
}

const StyledErrorMessage = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: ${({ theme }) => theme.colors.colorBlack100};
  text-align: center;
`

const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  color: ${({ theme }) => theme.colors.colorBlack100};
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.colors.colorBlack100};
  }
`

const LoginCallbackErrorMessage: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <StyledErrorMessage>
      <Text typography="typeDisplay04">
        {error.message.includes('not assigned') ? (
          <>You haven't been assigned to the Studio app in Okta yet. </>
        ) : (
          <>We weren't able to authenticate you. </>
        )}
        Please get in touch with
        <StyledLink href={TECHBAR_URL}> Techbar</StyledLink> for assistance.
      </Text>

      <Text typography="typeBodyText">
        {error.name && error.message ? (
          <span>
            {error.name}: {error.message}
          </span>
        ) : (
          <span>Error: {error.toString()}</span>
        )}
      </Text>
    </StyledErrorMessage>
  )
}

const CustomLoginCallback: React.FC<LoginCallbackProps> = ({
  loadingElement = null,
  onAuthResume,
}) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [callbackError, setCallbackError] = React.useState(null)
  const oauthError = authState?.error

  React.useEffect(() => {
    if (onAuthResume && oktaAuth.idx.isInteractionRequired?.()) {
      onAuthResume()
      return
    }
    oktaAuth
      .handleLoginRedirect()
      .then(() => {
        // In `<Security>` component service was not started in case of login redirect.
        // Start it now after `restoreOriginalUri` has been called and route changed.
        oktaAuth.start()
      })
      .catch((e: any) => {
        setCallbackError(e)
      })
  }, [oktaAuth, onAuthResume])

  const error = oauthError || callbackError

  if (error) {
    return <LoginCallbackErrorMessage error={error} />
  }

  return loadingElement
}

const ThemedCustomLoginCallback: React.FC = () => (
  <ThemeProvider theme={studioTheme}>
    <CustomLoginCallback />
  </ThemeProvider>
)

export default ThemedCustomLoginCallback
