import { assign, createMachine, DoneInvokeEvent, sendParent } from 'xstate'
import {
  FontUploadError,
  FontUploadHandler,
  FontUploadService,
  ParseFileHandler,
} from '../../services/Font/Upload'

export type FontUploadMachineContext = {
  uploadService: FontUploadService
  upload: { file: File }
  fontName?: string
  fileName: string
  done: boolean
  errors: Array<FontUploadError>
}

type FontUploadDoneEvent = DoneInvokeEvent<
  {} & Awaited<ReturnType<FontUploadHandler>>
>

type ParsedEvent = DoneInvokeEvent<{} & Awaited<ReturnType<ParseFileHandler>>>

type FontUploadErrorEvent = { data: FontUploadError }

type FontUploadMachineServices = {
  upload: { data: Awaited<ReturnType<FontUploadHandler>> }
}

export type FontUploadState<T> = {
  value: T
  context: FontUploadMachineContext
}

export type UploadStates =
  | FontUploadState<'COMPLETED'>
  | FontUploadState<'FAILED'>
  | FontUploadState<'UPLOADING'>
  | FontUploadState<'PARSING'>

export const createFontUploadMachine = (
  context: Omit<FontUploadMachineContext, 'errors'>,
) => {
  return createMachine(
    {
      id: 'font-upload-machine',
      initial: 'PARSING',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as FontUploadMachineContext,
        services: {} as FontUploadMachineServices,
      },
      context: {
        ...context,
        errors: [],
      },
      states: {
        PARSING: {
          invoke: {
            id: 'PARSING',
            src: 'parse',
            onDone: {
              target: 'UPLOADING',
              actions: ['parsed', 'notifyUploading'],
            },
            onError: {
              target: 'FAILED',
              actions: ['notifyFailure', 'failed'],
            },
          },
        },
        UPLOADING: {
          invoke: {
            id: 'UPLOADING',
            src: 'upload',
            onDone: {
              target: 'COMPLETED',
              actions: ['notifySuccess', 'uploaded'],
            },
            onError: {
              target: 'FAILED',
              actions: ['notifyFailure', 'failed'],
            },
          },
        },
        FAILED: {
          type: 'final',
        },
        COMPLETED: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        parsed: assign({
          fontName: (_, event) => (event as ParsedEvent).data,
        }),
        uploaded: assign({
          fontName: (_, event) => (event as FontUploadDoneEvent).data.fontName,
          done: true,
        }),
        failed: assign({
          errors: (context, event) =>
            context.errors.concat(
              (event as unknown as FontUploadErrorEvent).data,
            ),
          done: true,
        }),
        notifyUploading: sendParent((context, _event) => ({
          type: 'NOTIFY_UPLOADING',
          fontName: context?.fontName,
          fileName: context.fileName,
        })),
        notifySuccess: sendParent((context, _event) => ({
          type: 'NOTIFY_SUCCESS',
          fontName: context?.fontName,
          fileName: context.fileName,
        })),
        notifyFailure: sendParent((context, _event) => ({
          type: 'NOTIFY_FAILED',
          fontName: context?.fontName,
          fileName: context.fileName,
        })),
      },
      services: {
        parse: async context => {
          return context.uploadService.parse(context.upload.file)
        },
        upload: async context => {
          return context.uploadService.upload(
            context.upload.file,
            context.fontName!,
          )
        },
      },
    },
  )
}
