export type Matrix = [number, number, number, number, number, number]

export const translate = (x: number, y: number): Matrix => [1, 0, 0, 1, x, y]

export const scale = (x: number, y: number = x): Matrix => [x, 0, 0, y, 0, 0]

export const rotate = (angleInDegrees: number): Matrix => {
  const angleInRadians = deg2rad(angleInDegrees)
  const c = Math.cos(angleInRadians)
  const s = Math.sin(angleInRadians)
  return [c, s, -s, c, 0, 0]
}

export const deg2rad = (angleInDegrees: number): number => {
  return (angleInDegrees * Math.PI) / 180
}

export const rotateAboutCenter = (
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
): Matrix[] => {
  if (rotation === 0) {
    return [translate(x, y)]
  }

  const cx = width * 0.5
  const cy = height * 0.5

  return [translate(x + cx, y + cy), rotate(rotation), translate(-cx, -cy)]
}
