import { FC } from 'react'
import { OptionProps, components } from 'react-select'
import { Select } from '../Form/Combobox'
import { StudioFont } from '../../types'
import React from 'react'
import { StudioFontOrFallbackFont } from '../../__graphql__/types'
import { Label } from '../Form/Label'
import { useElementInView } from '../../hooks'
import { checkFontExists } from '../../utils/checkFontExists'
import { createFontFace } from '../../utils/createFontFace'

export type TFontComboboxOption = {
  label: string
  value: string
  family: string
  font: StudioFontOrFallbackFont
}

export const FontComboboxOption: FC<
  OptionProps<TFontComboboxOption>
> = props => {
  const { containerRef, inView } = useElementInView({
    rootMargin: '10%',
    threshold: 0.0,
  })

  React.useEffect(() => {
    if (inView && !checkFontExists(props.data.font.id)) {
      createFontFace(props.data.font.id, props.data.font.urls.source!, () => {})
    }
  }, [props.data.font.id, props.data.font.urls.source, inView])

  return (
    <div ref={containerRef}>
      <components.Option
        {...props}
        innerProps={{
          ...props.innerProps,
          style: {
            ...props.innerProps.style,
            fontFamily: props?.data?.family || 'Moonpig, sans-serif',
          },
        }}
      >
        {props.children}
      </components.Option>
    </div>
  )
}

type FontSelectProps = {
  options: StudioFont[]
  selected?: StudioFontOrFallbackFont
  onSelect: (fontName: string) => void
}

export const FontCombobox: FC<FontSelectProps> = props => {
  const current = React.useMemo(() => {
    if (!props.selected) return undefined

    return {
      label: props.selected.displayName,
      value: props.selected.displayName,
      family: props.selected.id,
      font: props.selected,
    }
  }, [props.selected])

  return (
    <>
      <Label name="font" label="Font" id="font" />
      <Select<TFontComboboxOption>
        aria-label="Font"
        id="font"
        name="font"
        components={{ Option: FontComboboxOption }}
        options={props.options.map(f => ({
          label: f.displayName,
          value: f.displayName,
          family: f.id,
          font: f,
        }))}
        onChange={newValue => {
          const option: TFontComboboxOption = Array.isArray(newValue)
            ? newValue[0]
            : newValue

          props.onSelect(option.value ?? undefined)
        }}
        value={current}
      />
    </>
  )
}
