import { useLazyQuery } from '@apollo/client'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import { Flex, Text, LoadingIndicator } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'
import { useEffect, useState } from 'react'
import { GET_NODE_META_DATA } from '../../../../queries'
import { StudioNode } from '../../../../types'
import { useAddNotification } from '../../../Notifications'
import {
  ActionableNodeTypes,
  useTemplateExplorer,
} from '../../TemplateExplorerProvider'
import {
  GetNodeMetaDataQuery,
  GetNodeMetaDataQueryVariables,
} from '../../../../__graphql__/queries'
import { DropdownMenu, DropdownMenuItem } from '../../../Dropdown/Dropdown'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 14px;
  height: 14px;
  color: ${colorValue('colorBorder01')};
  margin-right: 4px;
`

const StyledDropdownMenuTrigger = styled(Flex)`
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: rgba(0, 32, 77, 0.15) 0px 3px 8px 0px;
  width: 20px;
  height: 20px;
`

const StyledDropdownMenuItemText = styled(Text)<{ disabled: boolean }>`
  color: ${colorValue('colorPrimary01')};
  font-weight: 400;
  font-size: 14px;

  opacity: ${props => (props.disabled ? '.3' : '1')};

  :hover {
    color: ${colorValue('colorPrimary01')};
  }
`

type TemplateExplorerContextMenuProps = {
  node: StudioNode
  triggerRef: React.RefObject<HTMLButtonElement>
  onOpen: (node: StudioNode, newWindow: boolean) => void
}

export const TemplateExplorerContextMenu: React.FC<
  TemplateExplorerContextMenuProps
> = ({ node, triggerRef, onOpen }) => {
  const templateExplorer = useTemplateExplorer()
  const addNotification = useAddNotification()

  const onCopyName = (node: StudioNode) => {
    addNotification({
      id: 'COPYING',
      text:
        node.__typename === 'StudioGroup'
          ? 'Folder name copied'
          : 'Template name copied',
      variant: 'success',
      dismissible: true,
    })

    navigator?.clipboard && navigator.clipboard.writeText(node.name)
  }

  const onActionableNode = (action: ActionableNodeTypes, node: StudioNode) => {
    templateExplorer.dispatch({
      type: 'SET_ACTIONABLE_NODE',
      payload: {
        action,
        node,
      },
    })
  }

  return (
    <>
      <DropdownMenu
        hiddenByDefault
        data-testid={node.id}
        triggerRef={triggerRef}
        trigger={({ isExpanded }) => (
          <Flex justifyContent="space-between" alignItems="center">
            <StyledDropdownMenuTrigger>
              {isExpanded ? (
                <IconSystemChevronUp width={'12px'} height={'12px'} />
              ) : (
                <IconSystemChevronDown width={'12px'} height={'12px'} />
              )}
            </StyledDropdownMenuTrigger>
          </Flex>
        )}
      >
        <DropdownMenuItem onSelect={() => onOpen(node, true)}>
          Open In New Tab
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onCopyName(node)}>
          Copy Name
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onActionableNode('rename', node)}>
          Rename
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => onActionableNode('move', node)}>
          Move
        </DropdownMenuItem>
        <DeleteContextMenuAction
          node={node}
          onSelect={() => onActionableNode('delete', node)}
        />
      </DropdownMenu>
    </>
  )
}

type DeleteContextMenuActionProps = {
  node: StudioNode
  onSelect: () => void
}

const DeleteContextMenuAction: React.FC<DeleteContextMenuActionProps> = ({
  node,
  onSelect,
}) => {
  const [isDisabled, setIsDisabled] = useState(
    node.__typename === 'StudioGroupTemplate' ? false : true,
  )

  const [getNodeMetaData, { data, loading }] = useLazyQuery<
    GetNodeMetaDataQuery,
    GetNodeMetaDataQueryVariables
  >(GET_NODE_META_DATA, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (node.__typename === 'StudioGroup') {
      getNodeMetaData({
        variables: {
          input: {
            path: node.path,
          },
        },
      })
    }
  }, [getNodeMetaData, node])

  useEffect(() => {
    if (data?.studioNodeMetaData.__typename === 'StudioNodeMetaData') {
      setIsDisabled(data.studioNodeMetaData.actions?.canDeleteNode === false)
    }
  }, [data])

  return (
    <DropdownMenuItem disabled={isDisabled} onSelect={onSelect}>
      <StyledDropdownMenuItemText
        disabled={isDisabled}
        typography="typeBodyText"
      >
        {loading ? (
          <Flex alignItems="center">
            <StyledLoadingIndicator label="Checking if can be deleted" />
            Checking
          </Flex>
        ) : (
          'Delete'
        )}
      </StyledDropdownMenuItemText>
    </DropdownMenuItem>
  )
}
