import { styled } from '@moonpig/launchpad-utils'
import { FC, ReactNode } from 'react'
import * as Portal from '@radix-ui/react-portal'

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
`

type DialogOverlayProps = {
  show: boolean
  children: ReactNode
}

export const Overlay: FC<DialogOverlayProps> = ({ show, children }) => {
  return (
    <>
      {show && (
        <Portal.Root>
          <StyledOverlay role="dialog" aria-label="Template Explorer">
            {children}
          </StyledOverlay>
        </Portal.Root>
      )}
    </>
  )
}
