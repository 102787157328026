import { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { useMachine } from '../../state/xstate'
import { useElementData } from '../../data'
import { TextControls } from './Text'
import { ImageControls } from './Image'
import { RectangleEditControls } from './Rectangle'
import { isImageElement, isRectangleElement, isTextElement } from '../../utils'
import { StudioElement, StudioTextElement } from '../../types'

export const StyledSideMenuHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
`

export const StyledTabsContainer = styled.div`
  max-height: calc(100% - 81px);
  height: 100%;
`

export const SceneElementEditControls: FC = () => {
  const { state } = useMachine()
  const selectedElementId = state.context.selectedElementId!
  const { elementData, updateElement } = useElementData(selectedElementId)

  const onUpdateElement = async (elementProperties: Partial<StudioElement>) => {
    updateElement(elementProperties)
  }

  return (
    <div
      style={{ height: '100%', overflowY: 'auto' }}
      data-testid="std-element-edit-controls"
    >
      <StyledTabsContainer>
        {isTextElement(elementData) && (
          <TextControls
            onUpdateElement={onUpdateElement}
            selectedElementId={selectedElementId}
            selectedElement={elementData as StudioTextElement}
          />
        )}

        {isImageElement(elementData) && (
          <ImageControls
            onUpdateElement={onUpdateElement}
            selectedElementId={selectedElementId}
            selectedElement={elementData}
          />
        )}

        {isRectangleElement(elementData) && (
          <RectangleEditControls
            onUpdateElement={onUpdateElement}
            selectedElementId={selectedElementId}
            selectedElement={elementData}
          />
        )}
      </StyledTabsContainer>
    </div>
  )
}
