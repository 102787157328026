const getItem = <R = unknown>(key: string): R | null => {
  try {
    const item = localStorage.getItem(key)
    return item && JSON.parse(item)
  } catch {
    return null
  }
}

const setItem = (key: string, value: unknown): void => {
  try {
    return localStorage.setItem(key, JSON.stringify(value))
  } catch {
    return undefined
  }
}

const removeItem = (key: string): void => {
  try {
    return localStorage.removeItem(key)
  } catch {
    return undefined
  }
}

const clear = (): void => {
  try {
    return localStorage.clear()
  } catch {
    return undefined
  }
}

export const localStore = {
  getItem,
  setItem,
  removeItem,
  clear,
}
