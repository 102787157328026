import React from 'react'
import { useMoveTemplate } from '../../../services/Move/Template'
import { useTemplateExplorer } from '../TemplateExplorerProvider'
import { useAddNotification } from '../../Notifications'

export const useHandleDropMove = () => {
  const templateExplorer = useTemplateExplorer()
  const moveTemplateService = useMoveTemplate()
  const addNotification = useAddNotification()

  const handleDrop = async (
    e: React.DragEvent<HTMLElement>,
    destinationPath: string,
    onMoved: () => void,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    const template = e.dataTransfer.getData('node')
    templateExplorer.dispatch({
      type: 'SET_IS_MOVING_TEMPLATE',
      payload: { movingTemplate: true },
    })
    try {
      await moveTemplateService(template, destinationPath)
      addNotification({
        id: 'MOVING',
        text: `Template was moved successfully to ${destinationPath.replace(
          />/g,
          ' > ',
        )}.`,
        variant: 'success',
      })
      if (onMoved) onMoved()
    } catch (error) {
      addNotification({
        id: 'MOVING',
        text: `Unable to move template to ${destinationPath.replace(
          />/g,
          ' > ',
        )}.`,
        variant: 'error',
      })
    }
    templateExplorer.dispatch({
      type: 'SET_IS_MOVING_TEMPLATE',
      payload: { movingTemplate: false },
    })
  }

  return { handleDrop }
}
