import { Heading } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'

export const StyledHeading = styled(Heading)`
  position: relative;
  text-align: center;
  border-bottom: 1px solid ${colorValue('colorBlack20')};
  margin: 0px;
  padding: 12px 0px;
`
