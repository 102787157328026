import { FC } from 'react'
import { InteractiveFrame } from '../InteractiveFrame'
import { useMachine } from '../../state/xstate'

type InteractiveImageProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
}

export const InteractiveImage: FC<InteractiveImageProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
}) => {
  const { state, send } = useMachine()
  const selectedElementId = state.context.selectedElementId!
  const isSelected = id === selectedElementId
  return (
    <>
      <InteractiveFrame
        key={id}
        ariaLabel="Edit Image"
        selected={isSelected}
        onClick={() => {
          send({ type: 'SELECT_ELEMENT', id })
        }}
        frame={{ x, y, width, height, rotation }}
      />
    </>
  )
}
