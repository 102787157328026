import { Flex, LoadingIndicator } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { FC } from 'react'
import { StyledDialogContent } from '../Content'

const StyledTemplateContentNoResults = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 16px 16px;
  flex: 1;
`

export const Loading: FC = () => {
  return (
    <StyledDialogContent className="loading-indicator-container">
      <StyledTemplateContentNoResults>
        <Flex alignItems="center" flexDirection="column">
          <LoadingIndicator label="Loading content" />
        </Flex>
      </StyledTemplateContentNoResults>
    </StyledDialogContent>
  )
}
