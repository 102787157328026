import {
  Box,
  Flex,
  Modal,
  PrimaryButton,
  TertiaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { FC } from 'react'
import { CrossIconButton } from '../../Buttons'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import { StyledHeading } from '../components/Text'

type LeaveDialogProps = {
  open: boolean
  handleCancel: () => void
  handleConfirm: () => void
}

export const LeaveDialog: FC<LeaveDialogProps> = ({
  open,
  handleCancel,
  handleConfirm,
}) => {
  return (
    <>
      <OverrideReachModalPadding />
      <Modal
        label="Leaving this page?"
        isOpen={open}
        onDismiss={handleCancel}
        hasCloseButton={false}
      >
        <Flex
          width="430px"
          height="249px"
          flexDirection="column"
          justifyContent="space-between"
        >
          <StyledHeading width={'100%'} level="h2" fontWeight={400}>
            Leaving this page?
            <CrossIconButton
              data-testid="context-menu-close-icon"
              onClick={handleCancel}
            />
          </StyledHeading>
          <Box paddingX={6} paddingY="24px" paddingBottom="16px" width="385px">
            <Flex flexDirection="column">
              <Text typography={'body'}>
                There are unsaved changes on this template.
              </Text>
              <Text typography={'body'} marginTop={8}>
                Are you sure you want to leave?
              </Text>
            </Flex>
          </Box>
          <Flex marginBottom={7} justifyContent="space-around">
            <TertiaryButton width="182px" height="48px" onClick={handleConfirm}>
              Yes, leave
            </TertiaryButton>
            <PrimaryButton width="182px" height="48px" onClick={handleCancel}>
              <Text fontWeight={400}>Keep editing</Text>
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
