import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import { StudioFont } from '../types'

type SetFontsAction = {
  type: 'SET_FONTS'
  fonts: StudioFont[]
}

type Action = SetFontsAction

type ActionDispatch = (action: Action) => void

type State = {
  fonts: StudioFont[]
}

type Context =
  | {
      state: State
      dispatch: ActionDispatch
    }
  | undefined

const FontContext = createContext<Context>(undefined)

const reducer = (state: State, action: Action): State => {
  if (action.type === 'SET_FONTS') {
    return {
      ...state,
      fonts: action.fonts,
    }
  }

  return state
}

export const FontProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    fonts: [],
  })

  return (
    <FontContext.Provider value={{ state, dispatch }}>
      {children}
    </FontContext.Provider>
  )
}

export const useFontProvider = () => {
  const context = useContext(FontContext)

  if (context === undefined) {
    throw new Error('useFontProvider must be used within a FontProvider')
  }

  return context
}
