import { Box, Grid, Text } from '@moonpig/launchpad-components'
import { FC } from 'react'
import {
  StudioElement,
  StudioTextElement,
  StudioTextShadow,
} from '../../../types'
import { StudioTextInput } from '../../StudioTextInput'
import { ColorEditing } from '../Color/ColorEditing'

type TextShadowingProps = {
  selectedElement: StudioTextElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const TextShadowing: FC<TextShadowingProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  const shadowObject: StudioTextShadow = {
    __typename: 'StudioTextShadow',
    color: {
      __typename: 'StudioColor',
      name: 'Black',
      hex: '#000000',
    },
    offsetY: 0,
    offsetX: 0,
    ...selectedElement.shadow,
  }

  return (
    <Box paddingY={4}>
      <Grid gap={6}>
        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                X
              </Text>
            }
            name="shadow-x"
            value={`${shadowObject.offsetX}`}
            onChange={e =>
              onUpdateElement({
                shadow: { ...shadowObject, offsetX: Number(e.target.value) },
              })
            }
            placeholder=""
            step={0.1}
          />
        </Box>

        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                Y
              </Text>
            }
            name="shadow-y"
            value={`${shadowObject.offsetY}`}
            onChange={e =>
              onUpdateElement({
                shadow: { ...shadowObject, offsetY: Number(e.target.value) },
              })
            }
            placeholder=""
            step={0.1}
          />
        </Box>

        <Box width={2 / 3}>
          <ColorEditing
            hex={shadowObject.color.hex}
            onHexChange={hex => {
              onUpdateElement({
                shadow: {
                  ...shadowObject,
                  color: { ...shadowObject.color, hex: hex, name: hex },
                },
              })
            }}
            id="shadow"
          />
        </Box>
      </Grid>
    </Box>
  )
}
