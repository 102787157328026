import polly from 'polly-js'
import { UPLOAD_IMAGE_ATTEMPTS } from '../constants'
import { getImageBlobFromUrl } from '../utils'

export type UploadImageService = (
  imageBlogUrl: string,
  signedUrl: string,
) => Promise<number>

export const useUploadImage = (): UploadImageService => {
  return async (imageBlobUrl, signedUrl) => {
    return polly()
      .waitAndRetry(UPLOAD_IMAGE_ATTEMPTS)
      .executeForPromise(async () => {
        const blob = await getImageBlobFromUrl(imageBlobUrl)

        const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': blob.type },
          body: blob,
        })

        if (response.status > 200) {
          throw new Error('Image not successfully uploaded')
        }

        return response.status
      })
  }
}
