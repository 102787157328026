import { fromEvent, MonoTypeOperatorFunction, Observable } from 'rxjs'
import {
  buffer,
  concatMap,
  debounceTime,
  elementAt,
  filter,
  first,
  map,
  mapTo,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { documentMouseMove$, documentMouseUp$ } from './misc'

interface ClickObservableOptions {
  // HTML element used as a basis for all calculations.
  element: HTMLElement
}

export const preventDefault = <
  T extends Event,
>(): MonoTypeOperatorFunction<T> => {
  return tap(e => {
    e.preventDefault()
  })
}

/**
 * Create a double click event listener for an element.
 * The emitted value is a <number>, for number of clicks
 */
export const createDoubleClickObservable = ({
  element,
}: ClickObservableOptions): Observable<number> => {
  const click$ = fromEvent(element, 'click')
  return click$.pipe(
    preventDefault(),
    buffer(click$.pipe(debounceTime(250))),
    map(clicks => clicks.length),
    filter(clicksLength => clicksLength >= 2),
  )
}
/**
 * Create a click event listener for an element. Because actual click events
 * may be problematic for our other observables, this observable is based off
 * of `mousedown`, `mousemove`, and `mouseup` events. Therefore, the emitted
 * value is the element's `mousedown` event, not a `click` event.
 */
export const createClickObservable = ({
  element,
}: ClickObservableOptions): Observable<MouseEvent> => {
  const mouseDown$ = fromEvent<MouseEvent>(element, 'mousedown')

  return mouseDown$.pipe(
    concatMap(mouseDownEvent =>
      documentMouseUp$.pipe(
        first(),
        takeUntil(documentMouseMove$.pipe(elementAt(3))),
        tap(e => {
          e.stopPropagation()
          e.preventDefault()
        }),
        mapTo(mouseDownEvent),
      ),
    ),
  )
}
