import { IconSystemCross } from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'

export const CrossIconButton = styled(IconSystemCross)`
  position: absolute;
  top: 16px;
  right: 12px;
  color: ${colorValue('colorBlack100')};
  width: 24px;
  height: 24px;
  cursor: pointer;
`
