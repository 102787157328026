import { useMemo } from 'react'
import { StudioNode } from '../../../types'
import { truncateTextInMiddle } from '../../../utils/truncateTextInMiddle'

export const useFormattedNodeName = (node: StudioNode, threshold: number) => {
  const formattedName = useMemo(() => {
    if (node.__typename === 'StudioGroupTemplate') return node.name
    return truncateTextInMiddle(node.name, threshold)
  }, [node, threshold])

  return formattedName
}
