import React, { FC, HTMLAttributes, useEffect, useRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'

const StyledHeading = styled.h1`
  outline: none;
  user-select: none;
`

export const PageHeading: FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  ...props
}) => {
  const headingRef = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    headingRef.current!.focus()
  }, [])

  return (
    <StyledHeading ref={headingRef} tabIndex={-1} {...props}>
      {children}
    </StyledHeading>
  )
}
