import React, { FC, useEffect, useState } from 'react'
import { Frame } from '@moonpig/renderer-scene-types'
import { isPlaceholderTextPart } from '../../utils'
import { rotate, toCSS } from 'transformation-matrix'
import { deg2rad } from '../../utils/matrix'
import { StudioTextElement } from '../../types'
import { getContentFromTextElement } from '../../utils/getContentFromTextElement'
import { useElementData } from '../../data'
import { checkFontExists, waitForFont } from '../../utils/checkFontExists'
import { createFontFace } from '../../utils/createFontFace'
import { INLINE_TEXT_EDITING_FONT_SCALE } from '../../constants'
import {
  studioAlignToTextAlign,
  studioVerticalAlignMap,
  textAreaAdjust,
} from '../../utils/interactiveTextAreaUtils'
import { useMachine } from '../../state/xstate'
import {
  StudioHorizontalAlignment,
  StudioVerticalAlignment,
} from '../../__graphql__/types'
import { styled } from '@moonpig/launchpad-utils'
import { theme } from '@moonpig/launchpad-theme'

type InteractivePlaceholderTextFrameProps = {
  selected: boolean
  frame: Frame
  onClick: () => void
  ariaLabel: string
  elementDataId: string
  unitsPerPixel: number
  editingText: boolean
}

interface StyledTextAreaProps {
  textElementData: {
    verticalAlignment: string
    font: { id: string }
    horizontalAlignment: string
    height: number
  }
  placeholderMode: boolean
  fontSize: number
  lineHeight: number
  unitsPerPixel: number
}

const StyledTextArea = styled.div<StyledTextAreaProps>`
  order: 0;
  flex: 1 1 auto;
  alignself: ${({ textElementData }) =>
    studioVerticalAlignMap[
      textElementData.verticalAlignment as StudioVerticalAlignment
    ]};
  overflowwrap: unset;
  wordbreak: keep-all;
  whitespace: nowrap;
  border: transparent;
  resize: none;
  background: ${({ placeholderMode }) =>
    placeholderMode ? 'white' : 'transparent'};
  caretcolor: black;
  overflow: hidden;
  fontfamily: ${({ textElementData }) => textElementData.font.id};
  color: ${({ placeholderMode }) =>
    placeholderMode ? 'black' : 'transparent'};
  fontsize: ${({ fontSize }) => `${fontSize}px`};
  lineheight: ${({ lineHeight }) => `${lineHeight}px`};
  height: ${({ textElementData, unitsPerPixel }) =>
    `${textElementData.height / unitsPerPixel}px`};
  outline: ${({ placeholderMode }) =>
    placeholderMode ? '2px dotted red' : '0'};
  textalign: ${({ textElementData }) =>
    studioAlignToTextAlign(
      textElementData.horizontalAlignment as StudioHorizontalAlignment,
    )};
`

const StyledInteractiveFrame = styled.button`
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  display: block;
  width: 100%;
  height: 100%;
`

export const InteractivePlaceholderTextFrame: FC<
  InteractivePlaceholderTextFrameProps
> = ({
  frame,
  selected,
  onClick,
  ariaLabel,
  elementDataId,
  unitsPerPixel,
  editingText,
}) => {
  const { elementData } = useElementData(elementDataId)
  const textElementData = elementData as StudioTextElement
  const context = getContentFromTextElement(textElementData)
  const [text, setText] = useState(context)
  const textAreaRef: React.RefObject<HTMLDivElement> = React.createRef()
  useEffect(() => {
    setText(context)
  }, [textElementData, context])

  const border = !selected
    ? 'rgba(206,51,86,0.4)'
    : theme.colors.colorInteractionTextLink

  const transform: string = toCSS(rotate(deg2rad(frame.rotation)))
  const [err, setError] = useState(false)
  const { font } = textElementData
  const [fontLoaded, setFontLoaded] = useState(false)
  const [placeholderMode, setPlaceholderMode] = useState(false)

  useEffect(() => {
    if (!err) {
      if (checkFontExists(font.id)) {
        setFontLoaded(true)
      } else {
        createFontFace(font.id, font.urls.source!, setError)
        waitForFont(font.id).then(() => {
          setFontLoaded(true)
        })
      }
    }
  }, [err, font.id, font.urls.source])

  useEffect(() => {
    if (text.indexOf('[') > -1) {
      setPlaceholderMode(true)
    } else {
      setPlaceholderMode(false)
    }
  }, [text])

  const fontSizeScaleFactor: number = INLINE_TEXT_EDITING_FONT_SCALE
  const lineHeight: number =
    textElementData.lineSpacing / unitsPerPixel / fontSizeScaleFactor
  const fontSize =
    textElementData.fontSize / unitsPerPixel / fontSizeScaleFactor
  const { send } = useMachine()

  useEffect(() => {
    textAreaAdjust(
      textAreaRef.current!,
      textElementData,
      unitsPerPixel,
      fontSize,
      lineHeight,
    )
  })

  return (
    <StyledInteractiveFrame
      data-testid="std-interactive-frame"
      aria-label={ariaLabel}
      onClick={onClick}
      style={{
        transform,
        cursor: selected ? 'move' : 'pointer',
        border: `2px ${selected ? 'solid' : 'dotted'} ${border}`,
        pointerEvents: 'all',
      }}
    >
      {fontLoaded && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignContent: 'stretch',
            alignItems: 'flex-start',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
          }}
        >
          {editingText && (
            <StyledTextArea
              data-testid="text-edit-textarea"
              ref={textAreaRef}
              textElementData={textElementData}
              placeholderMode={placeholderMode}
              fontSize={fontSize}
              lineHeight={lineHeight}
              unitsPerPixel={unitsPerPixel}
            >
              {isPlaceholderTextPart(textElementData.text) &&
                textElementData.text.textParts.map((part, index) => {
                  const isPlainText = part.__typename === 'StudioPlainTextPart'
                  return (
                    <span
                      title={`Text Part (${index + 1}):${part.text}`}
                      style={{
                        cursor: 'pointer',
                        backgroundColor: isPlainText
                          ? 'rgba(255,224,0,0.4)'
                          : 'transparent',
                        border: isPlainText
                          ? '1px solid rgba(206,51,86,0.4)'
                          : 'none',
                      }}
                      key={index}
                      onClick={() => {
                        send({
                          type: 'SELECT_TEXT_PART',
                          elementId: elementDataId,
                          index: index,
                        })
                      }}
                    >
                      {part.text}
                    </span>
                  )
                })}
            </StyledTextArea>
          )}
        </div>
      )}
    </StyledInteractiveFrame>
  )
}
