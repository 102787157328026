import React, { FC, PropsWithChildren, useContext } from 'react'
import { StudioGroup, StudioNode } from '../../types'
import { getRecentlyOpenedGroup } from './lib'

export type ActionableNodeTypes =
  | 'create'
  | 'move'
  | 'rename'
  | 'delete'
  | 'openNewWindow'
export type SortByTypes = 'ASC' | 'DESC'
export type ViewByTypes = 'GRID' | 'LIST'

type SetCurrentGroupAction = {
  type: 'SET_CURRENT_GROUP'
  payload: {
    group: StudioGroup
  }
}

type SetRootGroupAction = {
  type: 'SET_ROOT_GROUP'
  payload: {
    group: StudioGroup
  }
}

type SetActionableNodeAction = {
  type: 'SET_ACTIONABLE_NODE'
  payload: {
    action: ActionableNodeTypes
    node: StudioNode
  }
}

type ClearActionableAction = {
  type: 'CLEAR_ACTIONABLE_NODE'
}

type SetSortByAction = {
  type: 'SET_SORT_BY'
  payload: {
    sortBy: SortByTypes
  }
}

type SetViewByAction = {
  type: 'SET_VIEW_BY'
  payload: {
    viewBy: ViewByTypes
  }
}

type SetIsMovingTemplate = {
  type: 'SET_IS_MOVING_TEMPLATE'
  payload: {
    movingTemplate: boolean
  }
}

type Action =
  | SetCurrentGroupAction
  | SetRootGroupAction
  | SetActionableNodeAction
  | ClearActionableAction
  | SetSortByAction
  | SetViewByAction
  | SetIsMovingTemplate

type Dispatch = (action: Action) => void

type State = {
  currentGroup: StudioGroup
  rootGroup?: StudioGroup
  actionableNode?: { action: ActionableNodeTypes; node: StudioNode }
  sortBy: SortByTypes
  viewBy: ViewByTypes
  isMovingTemplate?: boolean
}
type Context = { state: State; dispatch: Dispatch } | undefined

const TemplateExplorerContext = React.createContext<Context>(undefined)

const reducer = (state: State, action: Action): State => {
  if (action.type === 'SET_CURRENT_GROUP') {
    return {
      ...state,
      currentGroup: action.payload.group,
    }
  } else if (action.type === 'SET_ROOT_GROUP') {
    return {
      ...state,
      rootGroup: action.payload.group,
    }
  } else if (action.type === 'SET_ACTIONABLE_NODE') {
    return {
      ...state,
      actionableNode: action.payload,
    }
  } else if (action.type === 'CLEAR_ACTIONABLE_NODE') {
    return {
      ...state,
      actionableNode: undefined,
    }
  } else if (action.type === 'SET_SORT_BY') {
    return {
      ...state,
      sortBy: action.payload.sortBy,
    }
  } else if (action.type === 'SET_VIEW_BY') {
    return {
      ...state,
      viewBy: action.payload.viewBy,
    }
  } else if (action.type === 'SET_IS_MOVING_TEMPLATE') {
    return {
      ...state,
      isMovingTemplate: action.payload.movingTemplate,
    }
  }

  return state
}

export const TemplateExplorerProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    currentGroup: getRecentlyOpenedGroup(),
    sortBy: 'ASC',
    viewBy: 'GRID',
  })

  return (
    <TemplateExplorerContext.Provider value={{ state, dispatch }}>
      {children}
    </TemplateExplorerContext.Provider>
  )
}

export type TemplateExplorer = {
  state: State
  dispatch: Dispatch
}

export const useTemplateExplorer = (): TemplateExplorer => {
  const context = useContext(TemplateExplorerContext)

  if (context === undefined) {
    throw new Error(
      'useTemplateExplorer must be used within a TemplateExplorerProvider',
    )
  }

  return context
}
