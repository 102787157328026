import React, { FC, useEffect } from 'react'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { Document as FilledDocumentIcon } from '@styled-icons/fluentui-system-filled'
import { Document as OutlineDocumentIcon } from '@styled-icons/fluentui-system-regular'
import { FolderOpen } from '@styled-icons/material'
import { Box, Flex, Grid, Text } from '@moonpig/launchpad-components'
import { StudioGroup, StudioNode } from '../../../../types'
import { dateTimeToString } from '../../../../utils/dateTimeToString'
import { DropdownMenuContainer } from '../../../Dropdown'
import { TemplateExplorerContextMenu } from '../ContextMenu/ContextMenu'
import { useFormattedNodeName } from '../../hooks'
import { Section } from '../AlphabetList/AlphabetListHelpers'
import { StyledSectionTitle } from '../SectionList/StyledSectionTitle'
import { truncateTextInMiddle } from '../../../../utils/truncateTextInMiddle'
import { StudioTemplateStatus } from '../../../../__graphql__/types'
import { useHandleDropMove } from '../../hooks/dragAndDropHandler'

const LISTVIEW_GROUP_NAME_TRUNCATION_THRESHOLD = 50
const LISTVIEW_PATH_TRUNCATION_THRESHOLD = 35

const FolderIcon = styled(({ ...props }) => <FolderOpen {...props} />)`
  color: ${({ theme }) => theme.colors.colorBlack100}; // Deprecated
`

const ListRow = styled(Grid)`
  width: 100%;
  text-align: left;
  margin-bottom: 0;
`

const ListHeaders = styled(Box)`
  margin: 0 4px;
`

const IconContainer = styled(Flex)`
  margin: 0;
  padding-bottom: 0;
`

const ListField = styled(Box)`
  padding: 0;
  margin-bottom: 0;
  text-overflow: ellipsis;
`

const ListLink = styled.a<{ isDisabled?: boolean }>`
  cursor: ${props => (props.isDisabled ? 'wait' : 'pointer')};
  display: block;
  color: ${({ theme }) => theme.colors.colorTextBody};
  margin: 0 4px;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};

  :hover {
    text-decoration: none;
  }
`

const NameText = styled(Box)`
  text-overflow: ellipsis;
  padding-right: 8px;
  word-break: break-word;
`

const ListItem = styled.li`
  border-radius: 4px;
  display: block;
  min-height: 32px;
  padding: 8px 0;
  opacity: 0.999;

  &:has(a):hover {
    background-color: ${({ theme }) =>
      theme.colors.colorBackgroundInformation}; // Deprecated
  }
`

const formatLastUpdated = (lastUpdated: string): string => {
  const formattedDate = dateTimeToString(lastUpdated)
  return formattedDate === 'Invalid DateTime' ? lastUpdated : formattedDate
}
const formatUpdatedBy = (updatedBy?: string | null): string => {
  if (!updatedBy) return '-'
  return updatedBy.includes('MOONPIG\\')
    ? updatedBy.split('MOONPIG\\')[1]
    : updatedBy.split('@')[0]
}

type TemplateExplorerListViewProps = {
  setSelectedGroup: (group: StudioGroup, newWindow: boolean) => void
  onTemplateSelect: (node: StudioNode, newWindow: boolean) => void
  sections: Section[]
  onMoved: () => void
}

export const ListView: FC<TemplateExplorerListViewProps> = ({
  setSelectedGroup,
  onTemplateSelect,
  sections,
  onMoved,
}) => {
  const onOpen = (node: StudioNode, newWindow: boolean) => {
    if (node.__typename === 'StudioGroup') {
      setSelectedGroup(node, newWindow)
    }
    if (node.__typename === 'StudioGroupTemplate') {
      onTemplateSelect(node, newWindow)
    }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  })

  return (
    <ol data-testid="std-list-view-list">
      <ListItem>
        <ListHeaders>
          <ListRow gap={true}>
            <Box paddingBottom={0} marginBottom={0} flex={1} />
            <ListField flex={8}>
              <Text typography="typeBodyLabel">Name</Text>
            </ListField>
            <ListField flex={6}>
              <Text typography="typeBodyLabel">Path</Text>
            </ListField>
            <ListField flex={3}>
              <Text typography="typeBodyLabel">Last Updated</Text>
            </ListField>
            <ListField flex={4}>
              <Text typography="typeBodyLabel">Updated By</Text>
            </ListField>
          </ListRow>
        </ListHeaders>
      </ListItem>
      {sections.map(section => {
        return (
          <div key={section.title}>
            {section.title !== '' && (
              <StyledSectionTitle id={`alpha_entry_${section.title}`}>
                {section.title}
              </StyledSectionTitle>
            )}
            {section.data.map(data => (
              <ListViewItem
                key={data.id}
                node={data}
                onMoved={onMoved}
                onClick={(node, newWindow) => onOpen(node, newWindow)}
              />
            ))}
          </div>
        )
      })}
    </ol>
  )
}

type ListViewItemProps = {
  node: StudioNode
  onClick: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}

const ListViewNameField: React.FC<{ node: StudioNode }> = ({ node }) => {
  const formattedName = useFormattedNodeName(
    node,
    LISTVIEW_GROUP_NAME_TRUNCATION_THRESHOLD,
  )

  return (
    <ListField flex={8}>
      <Flex>
        <NameText>
          <Text typography="typeBodyText">{formattedName}</Text>
        </NameText>
      </Flex>
    </ListField>
  )
}

const ListViewParentPathField: React.FC<{ node: StudioNode }> = ({ node }) => {
  const expandedPath = node.parentPath.replaceAll('>', ' > ')
  const truncatedPath = truncateTextInMiddle(
    expandedPath,
    LISTVIEW_PATH_TRUNCATION_THRESHOLD,
  )

  return (
    <ListField
      flex={6}
      title={expandedPath}
      flexWrap="nowrap"
      flexShrink="0"
      whiteSpace="nowrap"
    >
      <Flex>
        <NameText>
          <Text typography="body">{truncatedPath}</Text>
        </NameText>
      </Flex>
    </ListField>
  )
}

const ListViewItem: React.FC<ListViewItemProps> = ({
  node,
  onClick,
  onMoved,
}) => {
  const triggerRef = React.createRef<HTMLButtonElement>()
  const theme = useTheme()

  const isTemplateReady =
    node.__typename === 'StudioGroupTemplate'
      ? node.templateStatus !== StudioTemplateStatus.IMPORTING &&
        node.templateStatus !== StudioTemplateStatus.SAVING
      : true

  const { handleDrop } = useHandleDropMove()

  return (
    <DropdownMenuContainer triggerRef={triggerRef}>
      <ListItem
        draggable={node.__typename === 'StudioGroupTemplate'}
        onDragStart={e => {
          e.dataTransfer.setData('node', node.id)
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <ListLink
          data-testid={`${node.id}-list-view-item`}
          id={node.id}
          onClick={isTemplateReady ? () => onClick(node, false) : () => {}}
          isDisabled={!isTemplateReady}
        >
          {node.__typename === 'StudioGroupTemplate' && (
            <ListRow gap={true}>
              <IconContainer flex={1}>
                {!isTemplateReady ? (
                  <OutlineDocumentIcon
                    size="25px"
                    color={theme.colors.colorTextHeadline}
                  />
                ) : (
                  <FilledDocumentIcon
                    color={theme.colors.colorBlack100}
                    size="25px"
                  />
                )}
                {isTemplateReady && (
                  <Flex marginLeft="6px" onClick={e => e.stopPropagation()}>
                    <TemplateExplorerContextMenu
                      triggerRef={triggerRef}
                      node={node}
                      onOpen={(node, newWindow) => onClick(node, newWindow)}
                    />
                  </Flex>
                )}
              </IconContainer>
              <ListViewNameField node={node} />
              <ListViewParentPathField node={node} />
              <ListField flex={3} m={{ xs: 0 }}>
                <Text typography="body">
                  {formatLastUpdated(node.lastUpdated || '-')}
                </Text>
              </ListField>
              <ListField flex={4} m={{ xs: 0 }}>
                <Text typography="body">{formatUpdatedBy(node.updatedBy)}</Text>
              </ListField>
            </ListRow>
          )}
          {node.__typename === 'StudioGroup' && (
            <ListRow gap={true} onDrop={e => handleDrop(e, node.path, onMoved)}>
              <IconContainer flex={1}>
                <FolderIcon size="25px" />

                <Flex marginLeft="6px" onClick={e => e.stopPropagation()}>
                  <TemplateExplorerContextMenu
                    triggerRef={triggerRef}
                    node={node}
                    onOpen={(node, newWindow) => onClick(node, newWindow)}
                  />
                </Flex>
              </IconContainer>
              <ListViewNameField node={node} />
              <ListViewParentPathField node={node} />
              <ListField flex={3} />
              <ListField flex={4} />
            </ListRow>
          )}
        </ListLink>
      </ListItem>
    </DropdownMenuContainer>
  )
}
