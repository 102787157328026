import { FC, useMemo, useState } from 'react'
import {
  Alert,
  Box,
  Flex,
  Heading,
  Modal,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { StudioTextInput } from '../../StudioTextInput'
import { CrossIconButton } from '../../Buttons'
import { OverrideReachModalPadding } from '../components/OverrideReachModalPadding'
import { StyledHeading } from '../components/Text'
import { StudioNode } from '../../../types'
import { useAddNotification } from '../../Notifications'
import { createCreateModalMachine } from '../../../machines/create-modal-machine'
import { useMachine } from '@xstate/react'
import { useCreateTemplateGroup } from '../../../services/CreateTemplateGroup'

type NewFolderDialogProps = {
  node: StudioNode
  onCreated: () => void
  onClose: () => void
}

export const NewFolderDialog: FC<NewFolderDialogProps> = ({
  node,
  onCreated,
  onClose,
}) => {
  const addNotification = useAddNotification()
  const createFolderService = useCreateTemplateGroup()

  const [inputValue, setInputValue] = useState('')

  const machine = useMemo(() => createCreateModalMachine({ node }), [node])

  const [state, send] = useMachine(machine, {
    actions: {
      notifySuccess: () => {
        addNotification({
          id: 'CREATE',
          text: `Folder created successfully.`,
          variant: 'success',
        })

        onCreated()
      },
      notifyError: context => {
        if (context.error) {
          if (
            ['StudioCreatedTemplateGroupUnknownError'].includes(
              context.error.name,
            )
          ) {
            addNotification({
              id: 'CREATING',
              text: context.error?.message,
              variant: 'error',
            })
            onClose()
          }
        }
      },
    },
    services: {
      create: async () => {
        await createFolderService(inputValue, node.path)
      },
    },
  })

  const onCreate = () => {
    send({ type: 'CREATE_CONFIRMED', name: inputValue })
  }

  const isCreateDisabled = useMemo(() => inputValue.length === 0, [inputValue])

  const shouldShowError =
    state.context.error?.name &&
    state.context.error?.name !== 'StudioCreatedTemplateGroupUnknownError'

  return (
    <>
      <OverrideReachModalPadding />
      <Modal label="New folder" isOpen={true}>
        <Flex
          width="400px"
          flexDirection="column"
          justifyContent="space-between"
          as="form"
          onSubmit={e => {
            e.preventDefault()

            onCreate()
          }}
        >
          <StyledHeading
            level="h2"
            marginBottom="24px"
            textAlign="center"
            fontWeight={400}
          >
            New folder
            <CrossIconButton
              onClick={onClose}
              data-test-id="new-folder-modal-close-icon"
            />
          </StyledHeading>

          {shouldShowError && (
            <Box paddingX={6} paddingTop={6}>
              <Alert variant="error">
                <Heading level="h4" mb={0}>
                  Oops!
                </Heading>
                <span>{state.context.error?.message}</span>
              </Alert>
            </Box>
          )}

          <Box padding="16px">
            <StudioTextInput
              autoFocus
              label="New folder name"
              name="name"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </Box>

          <Flex width="100%" padding="16px">
            <TertiaryButton
              type="button"
              marginRight="12px"
              onClick={onClose}
              width="100%"
            >
              Cancel
            </TertiaryButton>
            <PrimaryButton
              disabled={isCreateDisabled || state.matches('confirmed')}
              loading={state.matches('confirmed')}
              width="100%"
              type="submit"
            >
              Create folder
            </PrimaryButton>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
