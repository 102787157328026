import React, { useEffect, useRef, useState } from 'react'

export const useElementInView = (
  options: IntersectionObserverInit,
): { containerRef: React.RefObject<HTMLDivElement>; inView: boolean } => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [inView, setInView] = useState(false)

  const checkIntersecting = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setInView(entry.isIntersecting)
  }

  useEffect(() => {
    let observerRefValue: HTMLDivElement | null = null
    const observer = new IntersectionObserver(checkIntersecting, options)
    if (containerRef.current) {
      observer.observe(containerRef.current)
      observerRefValue = containerRef.current
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue)
    }
  }, [containerRef, options])

  return { containerRef, inView }
}
