import { FC } from 'react'
import { InteractiveFrame } from '../InteractiveFrame'
import { useMachine } from '../../state/xstate'

type InteractiveRectangleProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
}

export const InteractiveRectangle: FC<InteractiveRectangleProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
}) => {
  const { state, send } = useMachine()

  const selectedElementId = state.context.selectedElementId!
  const isSelected = id === selectedElementId

  return (
    <InteractiveFrame
      key={id}
      ariaLabel="Edit Rectangle"
      selected={isSelected}
      onClick={() => {
        send({ type: 'SELECT_ELEMENT', id })
      }}
      frame={{ x, y, width, height, rotation }}
    />
  )
}
