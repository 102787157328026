import { FC, useCallback, useMemo } from 'react'
import {
  Flex,
  PrimaryButton,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { useTemplateValue } from '../../../data'
import { useMachine } from '../../../state/xstate'
import { TemplateBreadcrumbs } from '../TemplateBreadcrumbs'
import { colorValue } from '@moonpig/launchpad-theme'

const StyledToolbar = styled.nav`
  padding-top: 8px;
  padding-bottom: 8px;
  background: ${colorValue('colorBackgroundDeactivated')};
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 324px) auto minmax(auto, 434px);
  padding: 0 16px;
`

type TemplateGlobalToolbarProps = {
  onTemplateExplorerClick: () => void
  onSaveAsClick: () => void
}

export const TemplateGlobalToolbar: FC<TemplateGlobalToolbarProps> = ({
  onTemplateExplorerClick,
  onSaveAsClick,
}) => {
  const { getTemplate } = useTemplateValue()
  const { send, state } = useMachine()

  const isLoading = state.matches('LOADING_TEMPLATE')
  const isPreviewing = state.matches({ STUDIO: 'PREVIEWING' })
  const isSavingTemplate =
    state.matches({ STUDIO: 'UPDATE_TEMPLATE' }) ||
    state.matches({ STUDIO: { SAVE_AS: 'CREATE_NEW_TEMPLATE' } })

  const onPreviewClick = useCallback(() => {
    send({ type: 'PREVIEW' })
  }, [send])

  const onEditClick = useCallback(() => {
    send({ type: 'EDIT' })
  }, [send])

  const onSaveClick = useCallback(() => {
    const template = getTemplate()
    send({ type: 'UPDATE_TEMPLATE', template })
  }, [getTemplate, send])

  const templatePath = useMemo(() => {
    const template = getTemplate()
    if (template) {
      return template.groupMapping.path
    }

    return undefined
  }, [getTemplate])

  return (
    <StyledToolbar>
      <StyledContainer>
        <Flex alignItems="center">
          <SecondaryButton px={6} py={0} onClick={onTemplateExplorerClick}>
            Template Explorer
          </SecondaryButton>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          typography={'typeBodyLabel'}
        >
          {templatePath && !isLoading && (
            <TemplateBreadcrumbs path={templatePath} />
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end">
          {!isPreviewing && (
            <SecondaryButton
              px={6}
              py={0}
              minWidth="100px"
              onClick={onPreviewClick}
            >
              Preview
            </SecondaryButton>
          )}
          {isPreviewing && (
            <SecondaryButton
              px={6}
              py={0}
              minWidth="100px"
              onClick={onEditClick}
            >
              Edit
            </SecondaryButton>
          )}
          <PrimaryButton
            data-testid="template-save-button"
            minWidth="100px"
            onClick={onSaveClick}
            disabled={isSavingTemplate}
            ml={4}
            px={6}
            py={0}
          >
            {isSavingTemplate ? 'Saving' : 'Save'}
          </PrimaryButton>
          <SecondaryButton
            data-testid="template-save-as-button"
            minWidth="100px"
            onClick={onSaveAsClick}
            disabled={isSavingTemplate}
            title="Save as"
            ml={4}
            px={6}
            py={0}
          >
            {isSavingTemplate ? 'Saving' : 'Save as...'}
          </SecondaryButton>
        </Flex>
      </StyledContainer>
    </StyledToolbar>
  )
}
