import { FC } from 'react'
import { Breadcrumbs } from '../../TemplateExplorer/components/Breadcrumbs'
import { pathToURI } from '../../../utils/path'

export const TemplateBreadcrumbs: FC<{ path: string }> = ({ path }) => {
  const onClick = (groupPath: string) => {
    window.open(`/${pathToURI(groupPath)}`, '_blank')?.focus()
  }

  return <Breadcrumbs path={path} onClick={onClick} />
}
