import React, { FC } from 'react'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Text } from '@moonpig/launchpad-components'
import { ChevronRight as ChevronRightIcon } from '@styled-icons/material'
import { Link } from 'react-router-dom'
import { useAddNotification } from '../../Notifications'
import { useHandleDropMove } from '../hooks/dragAndDropHandler'
import { DropdownMenu, DropdownMenuItem } from '../../Dropdown/Dropdown'

type BreadcrumbsProps = {
  type?: 'folder' | 'template'
  path: string
  onClick: (groupPath: string) => void
  onMoved?: () => void
}

const StyledBreadcrumbs = styled.nav`
  display: flex;
  justify-content: center;
  min-height: 35px;
`

const StyledList = styled.ol`
  display: flex;
`

const StyledBreadcrumb = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colorBlack100};
`

const StyledBreadcrumbText = styled(Text)`
  padding: 8px;

  &:hover {
    background-color: ${colorValue('colorBackground03')};
    border-radius: 8px;
  }
`

const StyledRightChevron = styled.span`
  display: inline-block;
  color: ${colorValue('colorBlack40')};
`

const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  color: ${colorValue('colorBlack100')};

  &:hover,
  a:visited {
    text-decoration: none;
  }
`

type Breadcrumb = {
  index: number
  text: string
  url: string
  path: string
  last: boolean
}

const BreadcrumbText: FC<{
  breadcrumb: Breadcrumb
}> = ({ breadcrumb }) => {
  return (
    <StyledBreadcrumbText
      aria-current={breadcrumb.last ? 'page' : undefined}
      data-testid={`breadcrumb-${breadcrumb.index}`}
      typography={
        breadcrumb.last && breadcrumb.index !== 0
          ? 'typeBodyText'
          : 'typeBodyLabel'
      }
    >
      {breadcrumb.text}
    </StyledBreadcrumbText>
  )
}

type BreadcrumbContextMenuProps = {
  onSelect: (breadcrumb: Breadcrumb) => void
  breadcrumbs: Breadcrumb[]
}

const BreadcrumbContextMenu: FC<BreadcrumbContextMenuProps> = ({
  onSelect,
  breadcrumbs,
}) => {
  return (
    <DropdownMenu
      data-testid="breadcrumbs"
      trigger={() => (
        <Flex>
          <Text
            aria-current={'page'}
            data-testid={`breadcrumb-context-menu`}
            color={'colorBlack100'}
          >
            ...
          </Text>
        </Flex>
      )}
    >
      <>
        {breadcrumbs.map(breadcrumb => (
          <DropdownMenuItem
            key={breadcrumb.path}
            onSelect={() => onSelect(breadcrumb)}
          >
            {breadcrumb.text}
          </DropdownMenuItem>
        ))}
      </>
    </DropdownMenu>
  )
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  type = 'template',
  path,
  onClick,
  onMoved,
}) => {
  const addNotification = useAddNotification()
  const { handleDrop } = useHandleDropMove()

  const breadcrumbs: Breadcrumb[] = React.useMemo(() => {
    const parts = path.split('>')

    return parts.reduce<Breadcrumb[]>((acc, curr, i, arr) => {
      const path = arr.slice(0, i + 1).join('>')

      acc.push({
        index: i,
        text: curr,
        url: encodeURI(`/` + arr.slice(0, i + 1).join('/')),
        path,
        last: parts.length === i + 1,
      })
      return acc
    }, [])
  }, [path])

  const handleBreadcrumbClick = (breadcrumb: Breadcrumb) => {
    if (breadcrumb.last) {
      addNotification({
        id: `COPY_BREADCRUMB_${breadcrumb.index}`,
        text: type === 'folder' ? 'Folder name copied' : 'Template name copied',
        variant: 'success',
        dismissible: true,
      })

      navigator?.clipboard && navigator.clipboard.writeText(breadcrumb.text)
    } else {
      onClick(breadcrumb.path)
    }
  }

  return (
    <StyledBreadcrumbs aria-label="Breadcrumbs" data-testid="breadcrumbs">
      <StyledList>
        {[...breadcrumbs.slice(-3)].map((breadcrumb, index) => {
          return (
            <StyledBreadcrumb
              key={`breadcrumb-${breadcrumb.index}`}
              onDragLeave={e => {
                e.currentTarget.style.backgroundColor = ''
                e.currentTarget.style.borderRadius = ''
              }}
              onDragOver={e => {
                e.preventDefault()
                e.stopPropagation()
                e.currentTarget.style.backgroundColor = '#e6eefa'
                e.currentTarget.style.borderRadius = '8px'
              }}
              onDrop={e => {
                if (onMoved) handleDrop(e, breadcrumb.path, onMoved)
                e.currentTarget.style.backgroundColor = ''
                e.currentTarget.style.borderRadius = ''
              }}
            >
              {breadcrumbs.length > 3 && index === 0 && (
                <>
                  <BreadcrumbContextMenu
                    breadcrumbs={breadcrumbs.slice(0, breadcrumbs.length - 3)}
                    onSelect={selected => onClick(selected.path)}
                  />
                  <StyledRightChevron aria-hidden>
                    <ChevronRightIcon size="30px" />
                  </StyledRightChevron>
                </>
              )}
              <button
                title={
                  breadcrumb.last ? `Copy ${breadcrumb.text}` : breadcrumb.text
                }
                data-testid={`breadcrumb-${index}-btn`}
                onClick={() => handleBreadcrumbClick(breadcrumb)}
                color={breadcrumb.last ? undefined : 'colorBlack100'}
              >
                {breadcrumb.last && breadcrumbs.length > 1 ? (
                  <BreadcrumbText breadcrumb={breadcrumb} />
                ) : (
                  <StyledLink
                    to={breadcrumb.url}
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation()
                      e.preventDefault()
                      onClick(breadcrumb.path)
                    }}
                  >
                    <BreadcrumbText breadcrumb={breadcrumb} />
                  </StyledLink>
                )}
              </button>
              {!breadcrumb.last && (
                <StyledRightChevron aria-hidden>
                  <ChevronRightIcon size="30px" />
                </StyledRightChevron>
              )}
            </StyledBreadcrumb>
          )
        })}
      </StyledList>
    </StyledBreadcrumbs>
  )
}
