import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  StudioElement,
  StudioPlainTextPart,
  StudioStaticTextPart,
} from '../../../types'
import { Box } from '@moonpig/launchpad-components'
import { TextEditorTextArea } from './TextEditorArea'

type PlaceholderParts = StudioStaticTextPart | StudioPlainTextPart

type PlaceholderTextAreaProps = {
  selectedTextPartIndex: number | null
  textParts: PlaceholderParts[]
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const PlaceholderTextArea: FC<PlaceholderTextAreaProps> = ({
  selectedTextPartIndex,
  textParts,
  onUpdateElement,
}) => {
  const parts = useMemo(() => {
    return textParts.map(part => {
      return {
        title: part.text,
        part: part,
        key: part.text,
      }
    })
  }, [textParts])

  const [selectedPart, setSelectedPart] = useState<number>(
    selectedTextPartIndex && parts[selectedTextPartIndex]
      ? selectedTextPartIndex
      : 0,
  )
  const [text, setText] = useState(parts[selectedPart]?.part.text)
  const part = parts[selectedPart]
  const sanitizePartText = (text: string) => {
    // Do not allow square brackets in text parts since they are used to identify text parts themselves
    return text.replace(/[[\]]/g, '')
  }
  useEffect(() => {
    setSelectedPart(selectedTextPartIndex || 0)
  }, [part, selectedPart, selectedTextPartIndex])
  useEffect(() => {
    if (part) {
      setText(part.part.text)
    }
  }, [part])
  return (
    <Box width={'100%'} paddingBottom={'15px'}>
      <TextEditorTextArea
        label={`${selectedPart + 1}: Text Placeholder Content`}
        name="placeholder-text-content"
        data-testid="placeholder-text-content"
        rows={4}
        style={{
          resize: 'vertical',
        }}
        width="100%"
        paddingTop="8px"
        value={text}
        onChange={e => {
          const sanitizedText = sanitizePartText(e.target.value)
          setText(sanitizedText)
          let updatedTextParts = textParts.map((part, index) => {
            if (index === selectedPart) {
              return {
                ...part,
                text: sanitizedText,
              }
            }
            return part
          })
          onUpdateElement({
            text: {
              __typename: 'StudioPlaceholderTextPart',
              textParts: updatedTextParts,
            },
          })
        }}
        placeholder="e.g. Adam"
      />
    </Box>
  )
}
