import { Flex } from '@moonpig/launchpad-components'
import { FC } from 'react'
import { StudioElement, StudioImageElement } from '../../../types'
import { PositionPanel } from '../PositionPanel'
import { ImageOptions } from './ImageOptions'

type ImageControlsProps = {
  selectedElementId: string
  selectedElement: StudioImageElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const ImageControls: FC<ImageControlsProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  return (
    <Flex flexDirection={'column'} marginX={6} marginY={4}>
      <ImageOptions
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />

      <PositionPanel
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
    </Flex>
  )
}
