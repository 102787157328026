import React, { ChangeEvent } from 'react'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import { useErrorMessages } from '../../../../hooks'
import { checkAcceptString } from '../../../../utils/checkMimeTypeInFileBytes'

const StyledFileInput = styled.input`
  ${screenReaderOnly}
`

type FileUploadInputProps = {
  id: string
  onChange(fileList: FileList | null): void
  accept: string
  failAcceptMessage: string
}

export const FileUploadInput = React.forwardRef<
  HTMLInputElement,
  FileUploadInputProps
>(({ id, accept, failAcceptMessage, onChange }, ref) => {
  const { showFileTypeIncorrectError } = useErrorMessages()

  const handleInputChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.removeAttribute('file-checked')

      if (e.target.files) {
        checkAcceptString(accept, e.target.files[0])
          .then(checkOk => {
            e.target.setAttribute('file-checked', 'true')
            if (checkOk) {
              onChange(e.target.files)
            } else {
              showFileTypeIncorrectError(failAcceptMessage)
            }
          })
          .catch(() => {
            showFileTypeIncorrectError(failAcceptMessage)
          })
          .finally(() => {
            e.target.value = ''
          })
      }
    },
    [onChange, showFileTypeIncorrectError, accept, failAcceptMessage],
  )

  return (
    <StyledFileInput
      aria-hidden
      tabIndex={-1}
      ref={ref}
      id={id}
      type="file"
      data-testid={id}
      accept={accept}
      onChange={handleInputChange}
    />
  )
})
