import {
  StudioFont,
  StudioTemplate,
  UpdateTemplateValidationError,
} from '../types'

const createValidationErrorMessage = (
  errors: UpdateTemplateValidationError,
  template: StudioTemplate,
  fonts: StudioFont[],
) => {
  const messages = errors.map(error => {
    const { name } = template.elements[error.elementIndex ?? 0]
    switch (error.code) {
      case 'InvalidMaxCharactersError':
        return `Element '${name}' has text content of length ${error.info[0].value} but max characters is set to ${error.info[1].value}.`
      case 'InvalidElementSizeError':
        return `Element '${name}' has width or height set to 0.`
      case 'InvalidFontSizeError':
        return `Element '${name}' has an invalid font size of ${error.info[0].value}.`
      case 'MissingDefaultFontSizeError':
        return `Element '${name}' has a font size of ${error.info[0].value} but this is not in the available font size list.`
      case 'InvalidAvailableFontSizeError':
        return `Available font sizes contains an invalid font size of ${error.info[0].value}.`
      case 'MissingDefaultFontError':
        const fontName = fonts.filter(f => f.id === error.info[0].value)[0]
          .displayName
        return `Element '${name}' has font ${fontName} but this is missing from the available font list.`
      case 'MissingDefaultColorError':
        return `Element '${name}' has colour ${error.info[0].value} but this is missing from the available colours list.`
      case 'InvalidTextContentError':
        return `Element '${name}' has text content with character(s) outside of the Windows-1252 character set.`
      default:
        return `Unknown Validation Error`
    }
  })

  return messages
}

export { createValidationErrorMessage }
