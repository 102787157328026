import { StudioAssetStatus } from '../__graphql__/types'
import { UNSET_ASSET_KEY } from '../constants'

export const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', e => {
      reject(e)
    })
    image.src = url
  })

export const stripImageMetadataAndGetMimeType = async (
  image: HTMLImageElement,
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const renderCanvas: HTMLCanvasElement = document.createElement('canvas')
    const { naturalWidth, naturalHeight } = image
    renderCanvas.width = naturalWidth
    renderCanvas.height = naturalHeight
    const ctx = renderCanvas.getContext('2d')!
    ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight)
    // If no transparency, prefer JPEG. If transparency, use PNG
    const imageData = ctx.getImageData(0, 0, naturalWidth, naturalHeight).data
    let exportFormat = 'jpeg'
    for (let i = 0; i < imageData.length; i += 4) {
      const alpha = imageData[i + 3]
      if (alpha !== 255) {
        exportFormat = 'png'
        break
      }
    }
    const mimeType = `image/${exportFormat}`
    const output = renderCanvas.toDataURL(mimeType, 0.9)
    image.onload = () => {
      resolve(mimeType)
    }
    image.onerror = e => {
      reject(e)
    }
    image.src = output
  })
}

export const loadImageFromBlob = async (imageData: Blob) => {
  const url = URL.createObjectURL(imageData)
  const imageElement = await loadImage(url)
  const mimeType: string = await stripImageMetadataAndGetMimeType(imageElement)
  const width = imageElement.naturalWidth
  const height = imageElement.naturalHeight
  return {
    url,
    width,
    height,
    mimeType,
    aspectRatio: width / height,
  }
}

export const getImageBlobFromUrl = async (url: string, cors = false) => {
  return fetch(url, {
    mode: cors ? 'cors' : 'no-cors',
  })
    .then(r => {
      if (!r.ok) throw new Error(r.statusText)
      return r.blob()
    })
    .catch(err => {
      throw err
    })
}

export const studioImageAssetFromFile = async (file: File) => {
  const imageInfo = await loadImageFromBlob(file)
  return {
    url: imageInfo.url,
    contentType: imageInfo.mimeType,
    key: UNSET_ASSET_KEY,
    status: StudioAssetStatus.PENDING,
    __typename: 'StudioAsset' as const,
  }
}
