import { GET_FONTS } from '../queries'
import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { StudioFont } from '../types'
import { GetFontsQuery, GetFontsQueryVariables } from '../__graphql__/queries'

export type FetchFontsService = () => Promise<StudioFont[]>

export const useFetchFonts = (): FetchFontsService => {
  const client = useApolloClient()
  return useCallback(async () => {
    const { data, error } = await client.query<
      GetFontsQuery,
      GetFontsQueryVariables
    >({
      errorPolicy: 'all',
      query: GET_FONTS,
    })
    if (data.studioFonts) {
      const fonts = (data.studioFonts as StudioFont[]).filter(
        (f: StudioFont) => f.displayName,
      )
      return fonts!
    }
    throw new Error(error?.message)
  }, [client])
}
