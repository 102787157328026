import React, { FC, useEffect } from 'react'
import { Box, Flex, Heading } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { StudioGroup, StudioNode } from '../../../../types'
import { TemplateExplorerContextMenu } from '../ContextMenu/ContextMenu'
import { FolderButton } from './FolderButton'
import { TemplateButton, TemplateImportingPlaceholder } from './TemplateButton'
import { useFormattedNodeName } from '../../hooks'
import { Section } from '../AlphabetList/AlphabetListHelpers'
import { StyledSectionTitle } from '../SectionList/StyledSectionTitle'
import { StudioTemplateStatus } from '../../../../__graphql__/types'
import { useHandleDropMove } from '../../hooks/dragAndDropHandler'
import { DropdownMenuContainer } from '../../../Dropdown'

const GRIDVIEW_GROUP_NAME_TRUNCATION_THRESHOLD = 50

const ExplorerButton = styled.a<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.colorTextBody};
  cursor: pointer;
  cursor: ${props => (props.isDisabled ? 'wait' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  border-radius: 8px;
  padding: 16px;
`

const TitleTextWrap = styled.span`
  word-break: break-word;
  text-overflow: ellipsis;
  text-align: center;
`

const StyledItemContainer = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0.999;

  &:hover a,
  &:has(button.selected) a {
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.colorBackgroundInformation};
  }
`

type TemplateExplorerGridViewProps = {
  setSelectedGroup: (group: StudioGroup, newWindow: boolean) => void
  onTemplateSelect: (node: StudioNode, newWindow: boolean) => void
  sections: Section[]
  onMoved: () => void
}

export const GridView: FC<TemplateExplorerGridViewProps> = ({
  setSelectedGroup,
  onTemplateSelect,
  sections,
  onMoved,
}) => {
  const handleClick = (node: StudioNode, newWindow: boolean) => {
    if (node.__typename === 'StudioGroup') {
      setSelectedGroup(node, newWindow)
    }
    if (node.__typename === 'StudioGroupTemplate') {
      onTemplateSelect(node, newWindow)
    }
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  })

  return (
    <div data-testid="std-grid-view-list">
      {sections.map(section => {
        return (
          <div key={section.title}>
            {section.title !== '' && (
              <StyledSectionTitle id={`alpha_entry_${section.title}`}>
                {section.title}
              </StyledSectionTitle>
            )}
            <Flex as="ol" flexWrap="wrap">
              {section.data.map(data => (
                <GridViewItem
                  key={data.id}
                  node={data}
                  onClick={handleClick}
                  onMoved={onMoved}
                />
              ))}
            </Flex>
          </div>
        )
      })}
    </div>
  )
}

type GridViewItemProps = {
  node: StudioNode
  onClick: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}

const StyledDropdownMenuContainer = styled(DropdownMenuContainer)`
  width: 75%;
  max-width: 275px;
  position: relative;
`

const GridViewItem: React.FC<GridViewItemProps> = ({
  node,
  onClick,
  onMoved,
}) => {
  const triggerRef = React.createRef<HTMLButtonElement>()

  const isTemplateReady =
    node.__typename === 'StudioGroupTemplate' &&
    node.templateStatus !== StudioTemplateStatus.IMPORTING &&
    node.templateStatus !== StudioTemplateStatus.SAVING

  const isDisabled =
    node.__typename === 'StudioGroupTemplate' ? !isTemplateReady : false

  const handleClick = () => {
    if (node.__typename === 'StudioGroupTemplate' && !isTemplateReady) {
      return
    }

    onClick(node, false)
  }

  const formattedName = useFormattedNodeName(
    node,
    GRIDVIEW_GROUP_NAME_TRUNCATION_THRESHOLD,
  )
  const formattedPath = node.parentPath.replace('>', ' > ')

  const { handleDrop } = useHandleDropMove()

  return (
    <Box
      key={node.id}
      id={node.id}
      as="li"
      width={{ xs: '50%', lg: '33%', xxl: '25%' }}
      mb={7}
    >
      <StyledItemContainer
        draggable={node.__typename === 'StudioGroupTemplate'}
        onDrop={e => {
          if (node.__typename === 'StudioGroup')
            handleDrop(e, node.path, onMoved)
        }}
        onDragStart={e => {
          e.dataTransfer.setData('node', node.id)
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <StyledDropdownMenuContainer
          triggerRef={triggerRef}
          data-testid={`${node.id}-grid-view-item`}
        >
          <Flex
            position={'absolute'}
            top="8px"
            right="8px"
            onClick={e => e.stopPropagation()}
          >
            <TemplateExplorerContextMenu
              node={node}
              triggerRef={triggerRef}
              onOpen={(node, newWindow) => onClick(node, newWindow)}
            />
          </Flex>
          <ExplorerButton
            onClick={handleClick}
            isDisabled={isDisabled}
            title={formattedPath}
          >
            <>
              {node.__typename === 'StudioGroupTemplate' &&
                (!isTemplateReady ? (
                  <TemplateImportingPlaceholder />
                ) : (
                  <TemplateButton
                    id={node.id}
                    previewUrl={node.previewUrl}
                    path={formattedPath}
                  />
                ))}
              {node.__typename === 'StudioGroup' && (
                <div data-testid={`${node.id} folder`}>
                  <FolderButton />
                </div>
              )}
              <Heading level="h2" typography="typeBodyText" mb={0} mt={4}>
                <TitleTextWrap>{formattedName}</TitleTextWrap>
              </Heading>
            </>
          </ExplorerButton>
        </StyledDropdownMenuContainer>
      </StyledItemContainer>
    </Box>
  )
}
