import { ChangeEvent, ChangeEventHandler, FC, useState } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemSearch } from '@moonpig/launchpad-assets'
import { HelperText } from '@moonpig/launchpad-forms'

const StyledInput = styled<any>('input').attrs(
  ({ error }: { error: boolean }) => ({
    className: error ? 'has-error' : '',
  }),
)`
  ${s({ color: 'colorBlack100', borderRadius: 2 })}
  border: none;
  box-shadow: inset 0 0 0 2px ${colorValue('colorBlack40')};
  height: 40px;
  padding: 1rem 0 1rem 3rem;
  width: 100%;
  font: inherit;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable */

  &:focus {
    box-shadow: inset 0 0 0 2px ${colorValue('colorBlack100')};
    outline: none;
  }

  &.has-error {
    ${s({ bgcolor: 'errorBg' })}
    box-shadow: inset 0 0 0 2px ${colorValue('colorBackgroundError')};
    &:not(:disabled):hover {
      box-shadow: inset 0 0 0 2px ${colorValue('colorBackgroundError')};
  }
`

const StyledSearchContainer = styled.div`
  position: relative;
  width: 220px;
`

const StyledSearchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  color: #666666;
`

const StyledInputWrapper = styled.div`
  position: relative;
`

type SearchInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>
  onEnterDown: (searchString: string) => void
}

export const SearchInput: FC<SearchInputProps> = ({
  onEnterDown,
  onChange,
}) => {
  const searchTermValidation = /^([\w\s\d.—–()&'!-])+$/i
  const [searchString, setSearchString] = useState('')
  const [isValid, setIsValid] = useState(true)
  const applySearchString = (value: string) => {
    const valid = value.length === 0 || searchTermValidation.test(value)
    setIsValid(valid)
    setSearchString(value)
  }
  return (
    <StyledSearchContainer>
      <StyledInputWrapper>
        <StyledSearchIconContainer>
          <IconSystemSearch width="20px" height="20px" fill="colorBlack40" />
        </StyledSearchIconContainer>
        <StyledInput
          id="std-search-input"
          name="search"
          value={searchString}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            applySearchString(e.target.value)
            onChange(e)
          }}
          onKeyDown={(evt: { key: string }) => {
            if (evt.key === 'Enter' && isValid) {
              onEnterDown(searchString)
            }
          }}
          placeholder="Search"
          autoFocus
          autoComplete="off"
          aria-label="Search for template"
          error={!isValid}
        />
      </StyledInputWrapper>

      {!isValid && (
        <HelperText
          id="std-search-validation-error"
          error={true}
          aria-label="search validation error"
        >
          Valid characters: A-Z a-z 0-9 . _ -()&amp;'!
        </HelperText>
      )}
    </StyledSearchContainer>
  )
}
