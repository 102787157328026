import { FC, useEffect, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useElementData } from '../../../data'
import { StudioTextElement } from '../../../types'
import { getContentFromTextElement } from '../../../utils/getContentFromTextElement'
import { TextEditorTextArea } from './TextEditorArea'
import { isPlaceholderTextPart } from '../../../utils'
import { useTemplateProvider } from '../../../contexts/TemplateProvider'

type TextEditorProps = {
  selectedElementId: string
}

export const TextEditor: FC<TextEditorProps> = ({ selectedElementId }) => {
  const templateProvider = useTemplateProvider()
  const { elementData, updateElement } = useElementData(selectedElementId)
  const textElementData = elementData as StudioTextElement
  const [text, setText] = useState(getContentFromTextElement(textElementData))

  useEffect(() => {
    setText(getContentFromTextElement(textElementData))
  }, [textElementData])

  return (
    <Box paddingBottom={6}>
      <TextEditorTextArea
        label="Text Content"
        name="text-content"
        rows={5}
        width="100%"
        paddingTop="8px"
        value={text}
        onFocus={() => templateProvider.startBatch()}
        onBlur={() => templateProvider.endBatch()}
        onChange={e => {
          setText(e.target.value)
          if (!isPlaceholderTextPart(textElementData.text)) {
            updateElement({
              ...textElementData,
              name: e.target.value,
              text: {
                ...textElementData.text,
                text: e.target.value,
              },
            })
          }
        }}
        placeholder="e.g. Happy Birthday Adam"
      />
    </Box>
  )
}
