import ReactSelect, { components, GroupBase, Props } from 'react-select'
import ReactSelectAsync, { AsyncProps } from 'react-select/async'
import { theme } from '@moonpig/launchpad-theme-mission-control'

const SHADOW_SIZING = '0px 0px 0px 2px inset'

export const Select = <Option,>(props: Props<Option>) => {
  return (
    <ReactSelect
      theme={theme => ({ ...theme, borderRadius: 8 })}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      styles={{
        menuPortal: baseStyles => ({
          ...baseStyles,
          pointerEvents: 'auto',
          zIndex: '9999',
        }),
        control: baseStyles => ({
          ...baseStyles,
          fontFamily: 'Moonpig, sans-serif',
          borderRadius: '8px',
          paddingLeft: '4px',
          fontSize: '16px',
          fontWeight: '300',
          border: 'none',
          boxShadow: `${theme.colors.colorBlack40} ${SHADOW_SIZING}`,
          minHeight: '40px',
          ':hover': {
            boxShadow: `${theme.colors.colorBlack100} ${SHADOW_SIZING}`,
          },
          ':focus': {
            boxShadow: `${theme.colors.colorBlack100} ${SHADOW_SIZING}`,
          },
        }),
      }}
      openMenuOnFocus
      isClearable={false}
      {...props}
      components={{
        DropdownIndicator: undefined,
        Option: components.Option,
        ...props.components,
      }}
    />
  )
}

export const AsyncSelect = <Option,>(
  props: AsyncProps<Option, boolean, GroupBase<Option>>,
) => {
  return (
    <ReactSelectAsync
      theme={theme => ({ ...theme, borderRadius: 8 })}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      styles={{
        menuPortal: baseStyles => ({
          ...baseStyles,
          pointerEvents: 'auto',
          zIndex: '9999',
        }),
        control: baseStyles => ({
          ...baseStyles,
          fontFamily: 'Moonpig, sans-serif',
          borderRadius: '8px',
          paddingLeft: '4px',
          fontSize: '16px',
          fontWeight: '300',
          border: 'none',
          boxShadow: `${theme.colors.colorBlack40} ${SHADOW_SIZING}`,
          minHeight: '40px',
          ':hover': {
            boxShadow: `${theme.colors.colorBlack100} ${SHADOW_SIZING}`,
          },
          ':focus': {
            boxShadow: `${theme.colors.colorBlack100} ${SHADOW_SIZING}`,
          },
        }),
      }}
      isClearable={false}
      {...props}
      components={{
        DropdownIndicator: undefined,
        Option: components.Option,
        ...props.components,
      }}
    />
  )
}
