import { ApolloClient } from '@apollo/client'
import {
  ActorRefWithDeprecatedState,
  AnyEventObject,
  assign,
  createMachine,
  spawn,
} from 'xstate'
import { createStatusService } from '../../services/Import/Status'
import { createUploadService } from '../../services/Import/Upload'
import { StudioGroup, StudioUser } from '../../types'
import {
  createUploadMachine,
  UploadMachineContext,
  UploadStates,
} from './upload-machine'

export type ImportUploadRef = ActorRefWithDeprecatedState<
  UploadMachineContext,
  AnyEventObject,
  UploadStates
>

export type ImportUpload = {
  upload: File
  ref: ImportUploadRef
}

export type ImportMachineContext = {
  client: ApolloClient<object> | undefined
  user: StudioUser | undefined
  uploads: ImportUpload[]
}

export type NewUploadEvent = {
  type: 'NEW_UPLOAD'
  file: File
  currentGroup: StudioGroup
}

export type RefreshGroupEvent = {
  type: 'REFRESH_GROUP'
  currentGroup: StudioGroup
}

export type CheckImportStatusEvent = {
  type: 'CHECK_IMPORT_STATUS'
}

export type ClearImportsEvent = {
  type: 'CLEAR_IMPORTS'
}

type ImportMachineEvents =
  | NewUploadEvent
  | RefreshGroupEvent
  | ClearImportsEvent
  | CheckImportStatusEvent

type ImportMachineServices = {}

export const createImportMachine = (context: ImportMachineContext) => {
  return createMachine(
    {
      id: 'import-machine',
      initial: 'IDLE',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as ImportMachineContext,
        events: {} as ImportMachineEvents,
        services: {} as ImportMachineServices,
      },
      context: {
        ...context,
        uploads: [],
      },
      states: {
        IDLE: {},
        IMPORTING: {},
        ALL_IMPORTS_PROCESSED: {},
      },
      on: {
        NEW_UPLOAD: {
          target: 'IMPORTING',
          actions: assign<ImportMachineContext, NewUploadEvent>({
            uploads: (context, event) => {
              const uploadService = createUploadService(
                context.client!,
                context.user!,
              )

              const statusService = createStatusService(context.client!)

              return [
                ...context.uploads,
                {
                  upload: event.file,
                  ref: spawn(
                    createUploadMachine({
                      uploadService,
                      statusService,
                      upload: {
                        file: event.file,
                        currentGroup: event.currentGroup,
                      },
                    }),
                    `upload-${event.file.name}`,
                  ),
                },
              ]
            },
          }),
        },
        REFRESH_GROUP: {
          actions: ['refreshGroup'],
        },
        CHECK_IMPORT_STATUS: {
          target: 'ALL_IMPORTS_PROCESSED',
          cond: 'haveAllImportsProcessed',
        },
        CLEAR_IMPORTS: {
          target: 'IDLE',
          actions: [
            assign<ImportMachineContext, ClearImportsEvent>({
              uploads: [],
            }),
          ],
        },
      },
    },
    {
      guards: {
        haveAllImportsProcessed: context => {
          return context.uploads
            .map(upload => upload.ref.getSnapshot()?.value)
            .every(status => ['COMPLETED', 'FAILED'].includes(status as string))
        },
      },
    },
  )
}
