import { useCallback, useState } from 'react'
import { StudioGroup } from '../../../types'
import { useTemplateExplorer } from '../TemplateExplorerProvider'

export const useSearch = (
  onSearch: (searchString: string) => Promise<StudioGroup>,
) => {
  const [searching, setSearching] = useState(false)
  const templateExplorer = useTemplateExplorer()

  const clear = () => {
    setSearching(false)
  }

  const search = useCallback(
    async (toSearch: string) => {
      setSearching(true)

      const group = await onSearch(toSearch)

      templateExplorer.dispatch({
        type: 'SET_CURRENT_GROUP',
        payload: { group },
      })

      setSearching(false)
    },
    [onSearch, templateExplorer],
  )

  return { searching, search, clear }
}
