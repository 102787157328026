import { Box, Flex, SecondaryButton, Text } from '@moonpig/launchpad-components'
import { FC } from 'react'
import { styled, ThemeProvider } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { BrandLogo, Illustration404 } from '@moonpig/launchpad-assets'
import { PageHeading } from '../PageHeading'
import { studioTheme } from '../../theme'
import { useNavigate } from 'react-router-dom'

const StyledPageHeading = styled(PageHeading)`
  ${s({
    mb: 2,
    typography: 'bodyBold',
  })}
`

const LOGO_WIDTH_PX = 96

const StyledBrandLogo = styled.div`
  position: absolute;
  bottom: 64px;
  width: ${LOGO_WIDTH_PX}px;
  ${s({
    color: 'brandOne',
  })}

  svg {
    display: block;
  }
`

type ErrorScreenProps = {
  title: string
  description: string
}

export const ErrorScreen: FC<ErrorScreenProps> = ({ title, description }) => {
  const navigate = useNavigate()

  return (
    <ThemeProvider theme={studioTheme}>
      <Flex
        width="100%"
        height="100%"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding={7}
      >
        <Box mb={6}>
          <Illustration404 width={256} />
        </Box>
        <Flex mb={2}>
          <StyledPageHeading>{title}</StyledPageHeading>
        </Flex>
        <Text typography="bodySmall" textAlign="center">
          {description}
        </Text>
        <StyledBrandLogo>
          <BrandLogo />
        </StyledBrandLogo>
        <Box mt={6}>
          <SecondaryButton onClick={() => navigate('/')}>
            Back to Studio
          </SecondaryButton>
        </Box>
      </Flex>
    </ThemeProvider>
  )
}
