import { assign, createMachine } from 'xstate'
import { FOLDER_NAME_VALIDATION_REGEX } from '../constants'
import { FOLDER_NAME_ERROR } from '../hooks/useErrorMessages'
import { StudioNode } from '../types'

type CreateModalMachineContext = {
  node: StudioNode
  error?: CreateNodeError
}

type CreateShowingEvent = {
  type: 'CREATE_SHOWING'
  data?: { type: 'error'; message: CreateNodeErrorTypes }
}

type CreateConfirmedEvent = {
  type: 'CREATE_CONFIRMED'
  name: string
}

type CreateCancelEvent = { type: 'CREATE_CANCEL' }

type CreateModalMachineEvents =
  | CreateShowingEvent
  | CreateConfirmedEvent
  | CreateCancelEvent

type CreateModalMachineServices = {
  validate: { data: any }
  create:
    | { data: { type: 'success' } }
    | { data: { type: 'error'; message: string } }
}

export const createCreateModalMachine = (
  context: Omit<CreateModalMachineContext, 'error'>,
) => {
  return createMachine(
    {
      id: 'create-modal-machine',
      initial: 'showing',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as CreateModalMachineContext,
        events: {} as CreateModalMachineEvents,
        services: {} as CreateModalMachineServices,
      },
      context: {
        error: undefined,
        ...context,
      },
      states: {
        showing: {
          on: {
            CREATE_CONFIRMED: {
              target: 'validate',
              actions: ['clearError'],
            },
            CREATE_CANCEL: {
              target: 'close',
            },
          },
        },
        validate: {
          invoke: {
            src: 'validate',
            onDone: [{ target: 'confirmed' }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        confirmed: {
          invoke: {
            src: 'create',
            onDone: [{ target: 'close', actions: ['notifySuccess'] }],
            onError: [
              {
                target: 'showing',
                actions: ['hasError', 'notifyError'],
              },
            ],
          },
        },
        close: {},
      },
    },
    {
      actions: {
        clearError: context => (context.error = undefined),
        hasError: assign({
          error: (_, event: CreateShowingEvent) => {
            if (event.data) {
              if (event.data.message === 'StudioInvalidNameError') {
                return new CreateNodeError(
                  event.data.message,
                  FOLDER_NAME_ERROR,
                )
              }

              return new CreateNodeError(
                event.data.message,
                CreateNodeError.getDisplayMessage(event.data.message),
              )
            }

            return undefined
          },
        }),
      },
      services: {
        validate: (_, event) => {
          if (event.type === 'CREATE_CONFIRMED') {
            const isNameValid = FOLDER_NAME_VALIDATION_REGEX.test(event.name)

            if (!isNameValid) {
              return Promise.reject(new Error('StudioInvalidNameError'))
            }
          }

          return Promise.resolve()
        },
      },
    },
  )
}

export type CreateNodeErrorTypes =
  | 'StudioInvalidNameError'
  | 'StudioCreateTemplateGroupError'
  | 'StudioDuplicatePathError'
  | 'StudioCreatedTemplateGroupUnknownError'

export class CreateNodeError extends Error {
  public readonly name: CreateNodeErrorTypes

  constructor(name: CreateNodeErrorTypes, message: string) {
    super(message)

    this.name = name
  }

  static getDisplayMessage(
    type: Omit<CreateNodeErrorTypes, 'StudioInvalidNameError'>,
  ) {
    switch (type) {
      case 'StudioCreateTemplateGroupError':
      case 'StudioDuplicatePathError':
        return 'That name is already in use in this folder. Please choose another name or create it in another folder.'
    }

    return "We've encountered an unknown error, please contact the support team."
  }
}
