import { StudioElement, StudioTemplate, StudioTextElement } from '../../types'
import { clamp, loadImageFromBlob } from '../../utils'
import { buildImageElement } from './image'
import { buildRendererScene } from './scene'
import { buildTextElement } from './text'
import { UNSET_ASSET_KEY } from '../../constants'
import { StudioAssetStatus } from '../../__graphql__/types'
import { useTemplateProvider } from '../../contexts/TemplateProvider'
import { useMemo } from 'react'

export const useTemplateValue = () => {
  const templateProvider = useTemplateProvider()

  const template = useMemo(
    () => templateProvider.state.template!,
    [templateProvider.state.template],
  )

  return {
    getTemplate: () => template,
    getTemplateScene: () => buildRendererScene(template),
  }
}

const getLayerNameFromFilenameAndMimeType = (
  filename: string,
  mimeType: string,
): string => {
  const mimeToExtensionMap: { [key: string]: string } = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
  }
  const extension = mimeToExtensionMap[mimeType.toLowerCase()]
  const filenameWithoutExtension = filename.replace(/\.[^.]+$/, '')
  return filenameWithoutExtension + extension
}

export const useSetTemplateElements = () => {
  const templateProvider = useTemplateProvider()

  const template = useMemo(
    () => templateProvider.state.template!,
    [templateProvider.state.template],
  )

  const elements = useMemo(() => template.elements, [template.elements])

  return {
    createImageElement: async (file: File) => {
      const {
        url,
        aspectRatio,
        width: imageWidth,
        mimeType,
      } = await loadImageFromBlob(file)
      const name = getLayerNameFromFilenameAndMimeType(file.name, mimeType)
      const elementWidth = clamp(imageWidth, imageWidth, template!.width)
      const elementHeight = elementWidth / aspectRatio

      templateProvider.dispatch({
        type: 'SET_TEMPLATE_ELEMENTS',
        elements: [
          ...elements,
          buildImageElement(
            {
              name,
              width: elementWidth,
              height: elementHeight,
              image: {
                __typename: 'StudioAsset',
                url,
                contentType: file.type,
                status: StudioAssetStatus.PENDING,
                key: UNSET_ASSET_KEY,
              },
            },
            template!,
          ),
        ],
      })

      return `${elements.length}`
    },
    createTextElement: (overrides?: Partial<StudioTextElement>) => {
      const elementWidth = 125
      const elementHeight = 25

      const element = {
        ...buildTextElement(
          { width: elementWidth, height: elementHeight },
          template!,
        ),
        ...overrides,
      }

      templateProvider.dispatch({
        type: 'SET_TEMPLATE_ELEMENTS',
        elements: [...elements, element],
      })

      return `${elements.length}`
    },
  }
}

// This hook differs from `useSetTemplateElements`
// as it doesn't require data the Template atom.
// This prevents components from subscribing
// to the Template store when they don't need to.
export const useTemplateElementsCollection = () => {
  const templateProvider = useTemplateProvider()

  return {
    setElements: (elements: StudioElement[]) => {
      templateProvider.dispatch({ type: 'SET_TEMPLATE_ELEMENTS', elements })
    },
    getElements: () => templateProvider.state.template?.elements ?? [],
    removeElementById: (elementId: string) => {
      templateProvider.dispatch({
        type: 'SET_TEMPLATE_ELEMENTS',
        elements:
          templateProvider.state.template?.elements.filter(
            (_, index) => `${index}` !== elementId,
          ) ?? [],
      })
    },
  }
}

export const useTemplate = () => {
  const templateProvider = useTemplateProvider()

  return {
    setTemplate: (newTemplate: StudioTemplate) => {
      templateProvider.dispatch({
        type: 'SET_TEMPLATE',
        template: newTemplate,
      })
    },
    updateAvailableFontSizes: (newFontSizes: number[]) => {
      templateProvider.dispatch({
        type: 'SET_TEMPLATE',
        template: {
          ...templateProvider.state.template!,
          availableFontSizes: newFontSizes,
        },
      })
    },
    updateAvailableFonts: (newFonts: any) => {
      templateProvider.dispatch({
        type: 'SET_TEMPLATE',
        template: {
          ...templateProvider.state.template!,
          availableFonts: newFonts,
        },
      })
    },
  }
}

export const useSetTemplate = () => {
  const templateProvider = useTemplateProvider()

  return async (template: StudioTemplate) => {
    templateProvider.dispatch({
      type: 'SET_TEMPLATE',
      template,
    })
  }
}
