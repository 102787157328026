import React from 'react'
import {
  sizingSpacing,
  SizingSpacingSystemProps,
  system as s,
} from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'
import { HelperText, Label } from '@moonpig/launchpad-forms'

export type TextInputProps = {
  autoFocus?: boolean
  /** Label (used for <label>) */
  label?: string | React.ReactNode
  /** Name attribute */
  name: string
  /** Value attribute */
  value: string
  /** Handler for change value can be found in e.target.value */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** Handler for when input loses focus */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  /** Handler for when input receives focus */
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: string
  /** ID for the input (uses name if not provided) */
  id?: string
  /** Placeholder used when empty value provided */
  placeholder?: string
  /** Input disabled */
  disabled?: boolean
  /** Determines whether input value is required or optional */
  required?: boolean
  /** Determines whether the input has autocomplete enabled and takes a number of [values]( https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values) */
  autoComplete?: string
  /** Set to true if the form contains errors */
  error?: boolean
  /** Set to true if the form contains successes */
  success?: boolean
  /** Adds helper text */
  helperText?: string
  /** Restricts the maximum length */
  maxLength?: number
  /** Adds component to the end of the text input */
  componentEnd?: React.ReactNode
  /** Adds component to the start of the text input */
  componentStart?: React.ReactNode
  /** componentEnd data-testid */
  componentEndTestId?: string
  /** componentStart data-testid */
  componentStartTestId?: string
  /** Max value for number input */
  max?: number
  /** Min value for number input */
  min?: number
  /** Graduation values */
  step?: number
} & SizingSpacingSystemProps

const COMPONENT_SIZE = '40px'
const SHADOW_SIZING = 'inset 0 0 0 2px'

const StyledTextInput = styled.div<SizingSpacingSystemProps>`
  ${sizingSpacing}
`

const StyledInputWrapper = styled.div`
  position: relative;
`

const buildClassName = (error: boolean, success: boolean) => {
  if (error) return 'has-error'
  if (success) return 'has-success'
  return ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledInput = styled<any>('input').attrs(({ error, success }) => ({
  className: buildClassName(error, success),
}))`
  ${s({ color: 'colorBlack100', borderRadius: 2 })}
  border: none;
  box-shadow: ${SHADOW_SIZING} ${colorValue('colorBlack40')};
  height: ${COMPONENT_SIZE};
  padding: 0 1rem;
  width: 100%;
  font: inherit;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable */
  ${({ hasComponentEnd }) =>
    hasComponentEnd ? `padding-right: ${COMPONENT_SIZE}` : ''};
  ${({ hasComponentStart }) =>
    hasComponentStart ? `padding-left: ${COMPONENT_SIZE}` : ''};

  &:disabled {
    opacity: 0.5;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      box-shadow: ${SHADOW_SIZING} ${colorValue('colorBlack100')};
    }
  }

  &::placeholder {
    ${s({ color: 'colorBlack100' })}
  }

  &.has-error {
    ${s({ bgcolor: 'errorBg' })}
    box-shadow: ${SHADOW_SIZING} ${colorValue('colorBackgroundError')};

    &:not(:disabled):hover {
      box-shadow: ${SHADOW_SIZING} ${colorValue('colorBackgroundError')};
    }
  }

  &.has-success {
    ${s({ bgcolor: 'successBg' })}
    box-shadow: ${SHADOW_SIZING} ${colorValue('colorBackgroundSuccess')};

    &:not(:disabled):hover {
      box-shadow: ${SHADOW_SIZING} ${colorValue('colorBackgroundSuccess')};
    }
  }
`

const StyledComponentEnd = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${COMPONENT_SIZE};
  height: ${COMPONENT_SIZE};
`

const StyledComponentStart = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${COMPONENT_SIZE};
  height: ${COMPONENT_SIZE};
`

type Ref = HTMLInputElement

export const StudioTextInput = React.forwardRef<Ref, TextInputProps>(
  (
    {
      autoFocus = false,
      type = 'text',
      label,
      id,
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      disabled,
      required,
      error,
      success,
      autoComplete,
      helperText,
      maxLength,
      componentEnd,
      componentStart,
      componentEndTestId = 'lp-forms-input-component-end',
      componentStartTestId = 'lp-forms-input-component-start',
      max,
      min,
      step,
      ...props
    },
    forwardedRef,
  ) => {
    const inputId = id || name
    const helperTextId = `${inputId}__helper-text`

    return (
      <StyledTextInput {...props}>
        {label && (
          <Label htmlFor={inputId} required={required} disabled={disabled}>
            {label}
          </Label>
        )}
        <StyledInputWrapper>
          {componentStart && (
            <StyledComponentStart data-testid={componentStartTestId}>
              {componentStart}
            </StyledComponentStart>
          )}
          <StyledInput
            ref={forwardedRef}
            autoFocus={autoFocus}
            type={type}
            id={inputId}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            aria-required={required}
            aria-describedby={helperTextId}
            error={error}
            success={success}
            maxLength={maxLength}
            hasComponentEnd={!!componentEnd}
            hasComponentStart={!!componentStart}
            max={max}
            min={min}
            step={step}
            data-testid={inputId}
          />
          {componentEnd && (
            <StyledComponentEnd
              className={'input-component-end'}
              data-testid={componentEndTestId}
            >
              {componentEnd}
            </StyledComponentEnd>
          )}
          {helperText && (
            <HelperText id={helperTextId} error={error}>
              {helperText}
            </HelperText>
          )}
        </StyledInputWrapper>
      </StyledTextInput>
    )
  },
)

StudioTextInput.displayName = 'StudioTextInput'
