import { FC, useCallback } from 'react'
import { AsyncSelect } from '../Form/Combobox'
import { StudioGroup, StudioGroupSearch } from '../../__graphql__/types'
import { useService } from '../../services'
import { Label } from '../Form/Label'
import { debounce } from '@moonpig/launchpad-utils'
import { useIsMounted } from '../../hooks/useIsMounted'

export type TPathComboboxOption = {
  label: string
  value: string
} & StudioGroupSearch

type PathComboboxProps = {
  options: StudioGroupSearch[]
  onSelect: (group: StudioGroup | undefined) => void
}

const truncateBreadcrumbs = (path: string) => {
  const breadcrumbs = path.split('>')
  const showFullPath = breadcrumbs.length < 3 || path.length < 45 ? true : false

  return showFullPath
    ? breadcrumbs.join(' > ')
    : [breadcrumbs[0], '...', breadcrumbs[breadcrumbs.length - 1]].join(' > ')
}

export const PathCombobox: FC<PathComboboxProps> = props => {
  const isMounted = useIsMounted()
  const { searchGroupsByPath } = useService()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      searchGroupsByPath(inputValue).then(
        results =>
          isMounted() &&
          callback(
            results.map(r => ({
              label: truncateBreadcrumbs(r.path),
              value: r.path.replace(/>/g, ' > '),
              ...r,
            })),
          ),
      )
    }, 500),
    [],
  )

  return (
    <>
      <Label name="search-folders" label="Search folders" id="search-folders" />
      <AsyncSelect<TPathComboboxOption>
        aria-label="Search folders"
        id="search-folders"
        name="search-folders"
        loadOptions={loadOptions}
        loadingMessage={() => 'Searching'}
        onChange={newValue => {
          const option: TPathComboboxOption = Array.isArray(newValue)
            ? newValue[0]
            : newValue

          props.onSelect(
            option.value
              ? {
                  __typename: 'StudioGroup',
                  id: option.id,
                  name: option.name,
                  path: option.path,
                  parentPath: option.path.split('>').slice(-1).join('>'),
                  children: [],
                }
              : undefined,
          )
        }}
        onInputChange={(_, action) => {
          if (action.action === 'input-change') {
            props.onSelect(undefined)
          }
        }}
        placeholder="Type folder name ..."
      />
    </>
  )
}
