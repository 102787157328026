import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import { FetchTemplateService, useFetchTemplate } from './FetchTemplate'
import { UpdateTemplateService, useUpdateTemplate } from './UpdateTemplate'
import {
  FetchGroupByPathService,
  useFetchGroupByPath,
} from './FetchGroupByPath'
import {
  FetchGroupTemplateService,
  useFetchGroupTemplate,
} from './FetchGroupTemplateById'
import { FetchRootGroupsService, useFetchRootGroups } from './FetchRootGroups'
import {
  useWaitForTemplateReadyStatus,
  WaitForTemplateReadyStatus,
} from './TemplateStatus'
import { SearchTemplateService, useSearchTemplate } from './SearchTemplate'
import { UploadImageService, useUploadImage } from './UploadImage'
import { FetchFontsService, useFetchFonts } from './FetchFonts'
import { FetchIntentService, useFetchIntents } from './FetchIntents'
import { CreateTemplateService, useCreateTemplate } from './CreateTemplate'
import {
  CreateTemplateGroupService,
  useCreateTemplateGroup,
} from './CreateTemplateGroup'
import {
  GetTemplateGroupsService,
  useFetchTemplateGroupsByIds,
} from './FetchTemplateGroupsByIds'
import { FetchPathService, useFetchPath } from './FetchPath'
import { RenameNodeService, useRenameNode } from './RenameNode'
import {
  SearchGroupsByPathService,
  useSearchGroupsByPath,
} from './SearchGroupsByPath'

type ServiceProviderProps = {}

type Context = {
  fetchTemplate: FetchTemplateService
  updateTemplate: UpdateTemplateService
  fetchGroupTemplate: FetchGroupTemplateService
  fetchRootGroups: FetchRootGroupsService
  fetchGroupByPath: FetchGroupByPathService
  searchTemplateByName: SearchTemplateService
  uploadImage: UploadImageService
  fetchFonts: FetchFontsService
  createTemplate: CreateTemplateService
  createTemplateGroup: CreateTemplateGroupService
  fetchTemplateGroupsByIds: GetTemplateGroupsService
  waitForTemplateReadyStatus: WaitForTemplateReadyStatus
  fetchPath: FetchPathService
  renameNode: RenameNodeService
  searchGroupsByPath: SearchGroupsByPathService
  fetchIntents: FetchIntentService
}

const serviceContext = createContext<Context | undefined>(undefined)

export const ServiceProvider: FC<PropsWithChildren<ServiceProviderProps>> = ({
  children,
}) => {
  const fetchTemplate = useFetchTemplate()
  const updateTemplate = useUpdateTemplate()
  const fetchGroupTemplate = useFetchGroupTemplate()
  const fetchRootGroups = useFetchRootGroups()
  const fetchGroupByPath = useFetchGroupByPath()
  const searchTemplateByName = useSearchTemplate()
  const uploadImage = useUploadImage()
  const fetchFonts = useFetchFonts()
  const createTemplate = useCreateTemplate()
  const createTemplateGroup = useCreateTemplateGroup()
  const fetchTemplateGroupsByIds = useFetchTemplateGroupsByIds()
  const waitForTemplateReadyStatus = useWaitForTemplateReadyStatus()
  const fetchPath = useFetchPath()
  const renameNode = useRenameNode()
  const searchGroupsByPath = useSearchGroupsByPath()
  const fetchIntents = useFetchIntents()

  const value: Context = useMemo(
    () => ({
      fetchTemplate,
      updateTemplate,
      fetchGroupTemplate,
      fetchRootGroups,
      fetchGroupByPath,
      searchTemplateByName,
      uploadImage,
      fetchFonts,
      createTemplate,
      createTemplateGroup,
      fetchTemplateGroupsByIds,
      waitForTemplateReadyStatus,
      fetchPath,
      renameNode,
      searchGroupsByPath,
      fetchIntents,
    }),
    [
      fetchIntents,
      fetchTemplate,
      updateTemplate,
      fetchGroupTemplate,
      fetchRootGroups,
      fetchGroupByPath,
      searchTemplateByName,
      uploadImage,
      fetchFonts,
      createTemplate,
      createTemplateGroup,
      fetchTemplateGroupsByIds,
      waitForTemplateReadyStatus,
      fetchPath,
      renameNode,
      searchGroupsByPath,
    ],
  )

  return (
    <serviceContext.Provider value={value}>{children}</serviceContext.Provider>
  )
}

export const useService = () => {
  const context = useContext(serviceContext)

  if (context === undefined) {
    throw new Error('useService must be used within a ServiceContextProvider')
  }

  return context
}
