import { StudioFontOrFallbackFont } from '../__graphql__/types'
import { checkFontExists } from './checkFontExists'

let measureCanvas: HTMLCanvasElement
let measureContext: CanvasRenderingContext2D
let measureDiv: HTMLDivElement
let measureRange: Range

export interface ITextMetrics {
  width: number
  height: number
}

export const measureTextOnTextAreaLine = (
  element: HTMLTextAreaElement | HTMLDivElement,
  text: string,
): TextMetrics => {
  const fontSize = parseFloat(element.style.fontSize)
  const fontFamily = element.style.fontFamily
  measureContext.font = `${fontSize}px ${fontFamily}`
  // Works regardless of Canvas being in the DOM or having a big-enough size
  return measureContext.measureText(text)
}
/**
 * Measures the width and height of the contents of a given TextArea element.
 * Uses Canvas' measureText() for proper width measurement in an initial step
 * For vertical size, simply takes the clientHeight of a DIV containing the result
 */
export const measureTextInTextArea = (
  element: HTMLTextAreaElement | HTMLDivElement,
): ITextMetrics => {
  const lines =
    element instanceof HTMLTextAreaElement
      ? element.value.split('\n')
      : element.innerText.split('\n')
  const results: TextMetrics[] = []
  if (!measureCanvas) {
    measureCanvas = document.createElement('canvas')
    measureContext = measureCanvas.getContext('2d')!
    measureCanvas.width = 1
    measureCanvas.height = 1
    measureDiv = document.createElement('div')
    measureRange = document.createRange()
  }
  for (const line of lines) {
    results.push(measureTextOnTextAreaLine(element, line))
  }
  const resultingWidth = results.sort((a, b) => (a.width > b.width ? -1 : 1))[0]
    .width
  const { style } = measureDiv
  style.fontSize = element.style.fontSize
  style.fontFamily = element.style.fontFamily
  style.lineHeight = element.style.lineHeight
  style.position = 'absolute'
  style.height = 'auto'
  style.width = `${resultingWidth}px`
  style.visibility = 'visible'
  // The DIV needs to be present in the DOM in order to measure its size
  // We can do this without having to wait for a frame render though
  document.body.appendChild(measureDiv)
  measureDiv.innerText =
    element instanceof HTMLTextAreaElement ? element.value : element.innerText
  measureRange.selectNodeContents(measureDiv.firstChild!)
  const rect = measureRange.getBoundingClientRect()
  document.body.removeChild(measureDiv)
  return {
    width: resultingWidth,
    height: rect.height,
  }
}

export const getDefaultFontLineHeight = async (
  font: StudioFontOrFallbackFont,
  fontSize: number,
) => {
  await checkFontExists(font.id)
  // Measure a line of text that extends from ascender to descender
  const metrics: ITextMetrics = measureTextInTextArea({
    innerText: 'Wqg_^',
    style: {
      fontFamily: font.id,
      fontSize: `${fontSize}px`,
      lineHeight: 'auto',
    },
  } as HTMLDivElement)
  return metrics.height
}
