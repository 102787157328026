import { Flex, Heading, IconButton } from '@moonpig/launchpad-components'
import { useMachine } from '../../../state/xstate'
import { useAddNotification } from '../../Notifications'
import { IconSystemCopy } from '@moonpig/launchpad-assets'

export const TemplateId: React.FC = () => {
  const {
    state: {
      context: { selectedTemplateId },
    },
  } = useMachine()

  const addNotification = useAddNotification()

  const copyTemplateIdToClipboard = () => {
    if (!selectedTemplateId || !navigator?.clipboard) return

    addNotification({
      id: `COPY_TEMPLATE_ID`,
      text: 'Template id copied',
      variant: 'success',
      dismissible: true,
    })

    navigator.clipboard.writeText(selectedTemplateId)
  }

  return selectedTemplateId ? (
    <Flex alignItems="center">
      <Heading level="h4" margin="0 2px 0 16px" padding="0 2px 0 16px">
        Template Id: {selectedTemplateId}
      </Heading>
      <IconButton
        icon={IconSystemCopy}
        label="Copy to clipboard"
        onClick={copyTemplateIdToClipboard}
      />
    </Flex>
  ) : null
}
