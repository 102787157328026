import { StudioTemplate, StudioTextElement } from '../../types'
import { DEFAULT_TEXT_ELEMENT } from '../../constants'

export const buildTextElement = (
  {
    width,
    height,
  }: {
    width: number
    height: number
  },
  template: StudioTemplate,
): StudioTextElement => {
  const centerOfElement = [width / 2, height / 2]

  return {
    ...DEFAULT_TEXT_ELEMENT,
    x: template!.width / 2 - centerOfElement[0],
    y: template!.height / 2 - centerOfElement[1],
    width: width,
    height: height,
  }
}
