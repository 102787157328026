import React, { PropsWithChildren, useState } from 'react'
import { useInterpret } from '@xstate/react'
import {
  createImportMachine,
  RefreshGroupEvent,
} from '../../machines/templates/import-machine'
import { InterpreterFrom } from 'xstate'
import { useApolloClient } from '@apollo/client'
import { StudioUser } from '../../types'
import { useTemplateExplorer } from './TemplateExplorerProvider'
import { useService } from '../../services'
import { useIsMounted } from '../../hooks/useIsMounted'

const UploadMachineContext = React.createContext({
  importMachineService: {} as InterpreterFrom<typeof createImportMachine>,
})

type UploadMachineProviderProps = {
  user: StudioUser
}

export const UploadMachineProvider: React.FC<
  PropsWithChildren<UploadMachineProviderProps>
> = ({ user, children }) => {
  const isMounted = useIsMounted()
  const client = useApolloClient()
  const templateExplorer = useTemplateExplorer()
  const { fetchGroupByPath } = useService()
  const [importMachine] = useState(() =>
    createImportMachine({
      uploads: [],
      client,
      user,
    }),
  )

  const importMachineService = useInterpret(importMachine, {
    devTools: true,
    actions: {
      refreshGroup: async (_, event: RefreshGroupEvent) => {
        if (event.currentGroup.id === templateExplorer.state.currentGroup.id) {
          const group = await fetchGroupByPath(event.currentGroup.path)

          isMounted() &&
            templateExplorer.dispatch({
              type: 'SET_CURRENT_GROUP',
              payload: { group },
            })
        }
      },
    },
  })

  return (
    <UploadMachineContext.Provider
      value={{
        importMachineService,
      }}
    >
      {children}
    </UploadMachineContext.Provider>
  )
}

export const useImportMachine = () => {
  const context = React.useContext(UploadMachineContext)

  if (context === undefined) {
    throw new Error(
      'useImportMachine must be used within a UploadMachineProvider',
    )
  }

  return context
}
