import { Box, IconButton } from '@moonpig/launchpad-components'
import {
  IconSystemEye,
  IconSystemZoomIn,
  IconSystemZoomOut,
  IconSystemUndo,
  IconSystemRedo,
} from '@moonpig/launchpad-assets'
import {
  ChangeEvent,
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useSceneProvider } from '../../contexts/SceneProvider'
import {
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_SLIDER_STEP,
  ZOOM_STEP,
} from '../../constants'

import { useTemplateProvider } from '../../contexts/TemplateProvider'

type ToolbarButtonProps = {
  icon: React.ComponentType<any>
  onClick: MouseEventHandler<HTMLButtonElement>
  label: string
  className?: string
  disabled?: boolean
}

const ToolbarButton: FC<ToolbarButtonProps> = (props: ToolbarButtonProps) => {
  return (
    <IconButton
      {...props}
      title={props.label}
      px={0}
      py={0}
      style={{ width: '28px', height: '28px' }}
    />
  )
}

type ToggleToolbarButtonProps = Omit<ToolbarButtonProps, 'label'> & {
  initialState: boolean
  activeLabel: string
  inactiveLabel: string
}

const ToolbarToggleButton: FC<ToggleToolbarButtonProps> = (
  props: ToggleToolbarButtonProps,
) => {
  const [toggled, setToggled] = useState(props.initialState)
  return (
    <ToolbarButton
      {...props}
      label={toggled ? props.activeLabel : props.inactiveLabel}
      className={toggled ? 'toggled-on' : 'toggled-off'}
      onClick={e => {
        props.onClick(e)
        if (toggled) {
          e.currentTarget.blur()
        }
        setToggled(!toggled)
      }}
    />
  )
}

const SingleActionToolbarButton: FC<ToolbarButtonProps> = (
  props: ToolbarButtonProps,
) => {
  return (
    <ToolbarButton
      {...props}
      onClick={e => {
        props.onClick(e)
        e.currentTarget.blur()
      }}
    />
  )
}

export const ActionsToolbar = () => {
  const scene = useSceneProvider()
  const templateProvider = useTemplateProvider()

  const handleZoomChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      scene.dispatch({
        type: 'SET_ZOOM',
        value: parseFloat(e.target.value || '1'),
      })
    },
    [scene],
  )

  const onToggleCustomisations = () => {
    scene.state.customisationsVisible
      ? scene.dispatch({ type: 'SET_HIDE_CUSTOMISATIONS' })
      : scene.dispatch({ type: 'SET_SHOW_CUSTOMISATIONS' })
  }
  const zoomOut = () => {
    scene.dispatch({
      type: 'SET_ZOOM',
      value: Math.max(MIN_ZOOM, scene.state.zoom - ZOOM_STEP),
    })
  }

  const zoomIn = () => {
    scene.dispatch({
      type: 'SET_ZOOM',
      value: Math.min(MAX_ZOOM, scene.state.zoom + ZOOM_STEP),
    })
  }

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (
        // e is not always a KeyboardEvent unfortunately
        // When using autocomplete in Chrome on Mac, e is an Event and thus has no key property
        e.key?.toLowerCase() === 'z' &&
        (e.ctrlKey || e.metaKey) &&
        e.shiftKey
      ) {
        // handle redo action
        templateProvider.redo()
      } else if (e.key?.toLowerCase() === 'z' && (e.ctrlKey || e.metaKey)) {
        // handle undo action
        templateProvider.undo()
      }
    },
    [templateProvider],
  )

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  return (
    <Box
      flex
      alignContent={'flex-start'}
      justifyContent={'start'}
      flexDirection={'column'}
      width={'100%'}
      height={'100%'}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        borderBottom={'1px solid lightgrey'}
        style={{
          backgroundColor: 'white',
        }}
        p={4}
      >
        <Box display="flex" flexDirection="row">
          <SingleActionToolbarButton
            onClick={() => templateProvider.undo()}
            icon={IconSystemUndo}
            label="Undo"
            disabled={!templateProvider.state.canUndo}
          />
          <SingleActionToolbarButton
            onClick={() => templateProvider.redo()}
            icon={IconSystemRedo}
            label="Redo"
            disabled={!templateProvider.state.canRedo}
          />
        </Box>
        <Box display="flex" flexDirection="row">
          <SingleActionToolbarButton
            onClick={() => zoomOut()}
            icon={IconSystemZoomOut}
            label="Zoom Out"
          />
          <input
            type="range"
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={ZOOM_SLIDER_STEP}
            onChange={handleZoomChange}
            value={scene.state.zoom}
            data-testid="zoom-slider"
          ></input>
          <SingleActionToolbarButton
            onClick={() => zoomIn()}
            icon={IconSystemZoomIn}
            label="Zoom In"
          />
        </Box>
        <Box display="flex" flexDirection="row">
          <ToolbarToggleButton
            onClick={() => onToggleCustomisations()}
            icon={IconSystemEye}
            initialState={scene.state.customisationsVisible}
            activeLabel="Hide Customisations"
            inactiveLabel="Show Customisations"
          />
        </Box>
      </Box>
    </Box>
  )
}
