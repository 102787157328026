import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import { StudioTemplate } from '../types'
import { StudioTemplateElement } from '../__graphql__/types'

type SetTemplateAction = {
  type: 'SET_TEMPLATE'
  template: StudioTemplate
}

type SetTemplateElementsAction = {
  type: 'SET_TEMPLATE_ELEMENTS'
  elements: StudioTemplateElement[]
}

type UndoAction = {
  type: 'UNDO'
}

type RedoAction = {
  type: 'REDO'
}

type StartBatchAction = {
  type: 'START_BATCH'
}

type EndBatchAction = {
  type: 'END_BATCH'
}

type Action =
  | SetTemplateAction
  | SetTemplateElementsAction
  | UndoAction
  | RedoAction
  | StartBatchAction
  | EndBatchAction

type ActionDispatch = (action: Action) => void

type State = {
  template?: StudioTemplate
  canUndo: boolean
  canRedo: boolean
}

type HistoricState = {
  past: State[]
  present: State
  future: State[]
  isBatching: boolean
  batchStartState: State | null
}

type Context =
  | {
      state: HistoricState
      dispatch: ActionDispatch
    }
  | undefined

const TemplateContext = createContext<Context>(undefined)

const reducer = (state: HistoricState, action: Action): HistoricState => {
  if (action.type === 'START_BATCH') {
    return {
      ...state,
      isBatching: true,
      batchStartState: state.isBatching ? state.batchStartState : state.present,
    }
  } else if (action.type === 'END_BATCH') {
    if (!state.isBatching || !state.batchStartState) {
      return state
    }

    return {
      ...state,
      isBatching: false,
      batchStartState: null,
      past: [...state.past, state.batchStartState],
    }
  } else if (action.type === 'SET_TEMPLATE') {
    return {
      isBatching: false,
      batchStartState: null,
      past: [],
      present: {
        ...state.present,
        template: action.template,
      },
      future: [],
    }
  } else if (action.type === 'SET_TEMPLATE_ELEMENTS') {
    if (state.present.template === undefined) {
      throw new Error('Template must be defined.')
    }

    if (state.isBatching) {
      return {
        ...state,
        present: {
          ...state.present,
          template: {
            ...state.present.template,
            elements: action.elements,
          },
        },
      }
    }

    return {
      isBatching: false,
      batchStartState: null,
      past: [...state.past, state.present],
      present: {
        ...state.present,
        template: {
          ...state.present.template,
          elements: action.elements,
        },
      },
      future: [],
    }
  } else if (action.type === 'UNDO') {
    if (state.past.length === 0) {
      return state
    }

    const previous = state.past[state.past.length - 1]
    const newPast = state.past.slice(0, state.past.length - 1)

    return {
      isBatching: false,
      batchStartState: null,
      past: newPast,
      present: previous,
      future: [state.present, ...state.future],
    }
  } else if (action.type === 'REDO') {
    if (state.future.length === 0) {
      return state
    }

    const next = state.future[0]
    const newFuture = state.future.slice(1)

    return {
      isBatching: false,
      batchStartState: null,
      past: [...state.past, state.present],
      present: next,
      future: newFuture,
    }
  }

  return state
}

export const TemplateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    isBatching: false,
    batchStartState: null,
    past: [],
    present: { template: undefined, canUndo: false, canRedo: false },
    future: [],
  })

  return (
    <TemplateContext.Provider value={{ state, dispatch }}>
      {children}
    </TemplateContext.Provider>
  )
}

export const useTemplateProvider = () => {
  const context = useContext(TemplateContext)

  if (context === undefined) {
    throw new Error(
      'useTemplateProvider must be used within a TemplateProvider',
    )
  }

  const { state, dispatch } = context

  const undo = () => dispatch({ type: 'UNDO' })
  const redo = () => dispatch({ type: 'REDO' })
  const startBatch = () => dispatch({ type: 'START_BATCH' })
  const endBatch = () => dispatch({ type: 'END_BATCH' })

  return {
    state: {
      ...state.present,
      canUndo: state.past.length > 0,
      canRedo: state.future.length > 0,
    },
    dispatch,
    undo,
    redo,
    startBatch,
    endBatch,
  }
}
