export const DEFAULT_AVAILABLE_FONTS = [
  {
    id: 'Playfair Display',
  },
  {
    id: 'Caveat Bold',
  },
  {
    id: 'Dancing Script',
  },
  {
    id: 'Edu TAS Beginner Regular',
  },
  {
    id: 'Shadows Into Light',
  },
  {
    id: 'Poppins Bold',
  },
  {
    id: 'Poppins Regular',
  },
  {
    id: 'Praise Regular',
  },
  {
    id: 'Mansalva Regular',
  },
  {
    id: 'PressedKeys',
  },
]

export const DEFAULT_AVAILABLE_FONT_SIZES = [
  12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65,
]

export const DEFAULT_AVAILABLE_FONT_COLORS = [
  {
    name: 'Black',
    hex: '#000000',
  },
  {
    name: 'Blue C100 M95 Y11 K2',
    hex: '#21318B',
  },
  {
    name: 'Blue C80 M61 Y27 K7',
    hex: '#456289',
  },
  {
    name: 'BLUE C82 M36 Y6 K0',
    hex: '#0C87BF',
  },
  {
    name: 'Green Bright',
    hex: '#44963D',
  },
  {
    name: 'Green C80 M36 Y96 K28',
    hex: '#336735',
  },
  {
    name: 'Green C88 M46 Y86 K60',
    hex: '#033A23',
  },
  {
    name: 'Grey C53 M45 Y45 K10',
    hex: '#7A7A7A',
  },
  {
    name: 'Grey C64 M60 Y59 K41',
    hex: '#4A4746',
  },
  {
    name: 'Mint C60 M6 Y36 K0',
    hex: '#62B9AF',
  },
  {
    name: 'Natural Teal',
    hex: '#008c98',
  },
  {
    name: 'Orange ABC',
    hex: '#f2822f',
  },
  {
    name: 'Orange C9 M83 Y100 K1',
    hex: '#DD4F12',
  },
  {
    name: 'Pink C21 M81 Y44 K2',
    hex: '#C2536B',
  },
  {
    name: 'Pink C21 M92 Y52 K4',
    hex: '#BF375B',
  },
  {
    name: 'Pink C7 Y100 M11 K0',
    hex: '#DF0075',
  },
  {
    name: 'Pink Prima',
    hex: '#ee3e8f',
  },
  {
    name: 'Purple ABC',
    hex: '#7056a4',
  },
  {
    name: 'Purple C81 M100 Y19 K7',
    hex: '#572A77',
  },
  {
    name: 'Red',
    hex: '#ED2124',
  },
  {
    name: 'Red C26 M100 Y85 K25',
    hex: '#951429',
  },
  {
    name: 'Yellow C8 M40 Y100 K0',
    hex: '#E8A11E',
  },
]
