import { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Grid,
  ScreenReaderOnly,
  Text,
  Heading,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { Link } from 'styled-icons/material'
import { StudioTextInput } from '../StudioTextInput'
import { StudioElement } from '../../types'

type PositionPanelProps = {
  selectedElement: StudioElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

const StyledAspectLockButton = styled.button`
  color: ${colorValue('colorBlack100')};
  transform: rotate(-90deg);

  &.is-active {
    color: ${colorValue('colorInteractionTextLink')};
  }
`

const StyledRotationInput = styled(({ ...props }) => (
  <StudioTextInput {...props} />
))`
  input[type='number'] {
    padding-right: 18px;
  }

  .input-component-end {
    width: 20px;
    right: 4px;
  }
`

export const PositionPanel: FC<PositionPanelProps> = ({
  selectedElement,
  onUpdateElement,
}) => {
  const [aspectLocked, setAspectLocked] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(
    selectedElement.height / selectedElement.width,
  )

  const calculateAspectRatio = (width: number, height: number) => {
    const ratio = height / width
    return isNaN(ratio) ? 1 : ratio
  }

  const [inputs, setInputs] = useState({
    x: String(selectedElement.x),
    y: String(selectedElement.y),
    rotation: String(selectedElement.rotation),
  })

  useEffect(() => {
    setInputs({
      x: String(selectedElement.x),
      y: String(selectedElement.y),
      rotation: String(selectedElement.rotation),
    })
  }, [selectedElement.x, selectedElement.y, selectedElement.rotation])

  return (
    <Box paddingY={4}>
      <Heading level="h4" typography="typeBodyLabel" marginBottom={1}>
        Position
      </Heading>
      <Grid gap={6} rowGap={4}>
        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                X
              </Text>
            }
            name="element-position-x"
            value={inputs.x}
            onChange={e => {
              const newX = parseFloat(e.target.value).toFixed(2)

              setInputs({ ...inputs, x: e.target.value })

              onUpdateElement({
                x: newX !== 'NaN' ? Number(newX) : Number(e.target.placeholder),
              })
            }}
            placeholder="0"
            step={0.5}
          />
        </Box>
        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                Y
              </Text>
            }
            name="element-position-y"
            value={inputs.y}
            onChange={e => {
              const newY = parseFloat(e.target.value).toFixed(2)

              setInputs({ ...inputs, y: e.target.value })

              onUpdateElement({
                y: newY !== 'NaN' ? Number(newY) : Number(e.target.placeholder),
              })
            }}
            placeholder="1.5"
            step={0.5}
          />
        </Box>
        <Box width={1 / 3}>
          <StyledRotationInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                Rotation
              </Text>
            }
            name="element-rotation"
            value={inputs.rotation}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const newRotation = parseFloat(e.target.value).toFixed(2)

              setInputs({ ...inputs, rotation: e.target.value })

              onUpdateElement({
                rotation:
                  newRotation !== 'NaN'
                    ? Number(newRotation)
                    : Number(e.target.placeholder),
              })
            }}
            placeholder="0"
            min={-360}
            max={360}
            step={0.5}
            componentEnd="º"
          />
        </Box>

        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                W
              </Text>
            }
            name="element-w"
            value={`${
              selectedElement!.width === 0 ? '' : selectedElement!.width
            }`}
            onChange={e => {
              const newWidth = Number(
                e.target.value === ''
                  ? '0'
                  : parseFloat(e.target.value).toFixed(2),
              )

              onUpdateElement({
                width: newWidth,
                ...(aspectLocked && {
                  height: Number((newWidth * aspectRatio).toFixed(2)),
                }),
              })
            }}
            placeholder="0"
            step={0.5}
          />
        </Box>
        <Box width={1 / 3}>
          <StudioTextInput
            type="number"
            label={
              <Text fontSize={'14px '} typography={'typeBodyLabel'}>
                H
              </Text>
            }
            name="element-h"
            value={`${
              selectedElement!.height === 0 ? '' : selectedElement!.height
            }`}
            onChange={e => {
              const newHeight = Number(
                e.target.value === ''
                  ? '0'
                  : parseFloat(e.target.value).toFixed(2),
              )

              onUpdateElement({
                height: newHeight,
                ...(aspectLocked && {
                  width: Number((newHeight / aspectRatio).toFixed(2)),
                }),
              })
            }}
            placeholder="0"
            step={0.5}
          />
        </Box>
        <Box width={1 / 3}>
          <Flex width={'100%'} height={'100%'}>
            <Flex
              paddingBottom={4}
              alignItems="flex-end"
              justifyContent="center"
            >
              <StyledAspectLockButton
                className={aspectLocked ? 'is-active' : ''}
                onClick={() => {
                  setAspectRatio(
                    calculateAspectRatio(
                      selectedElement.width,
                      selectedElement.height,
                    ),
                  )
                  setAspectLocked(!aspectLocked)
                }}
              >
                <Link size="24px" />
                <ScreenReaderOnly>Lock Aspect</ScreenReaderOnly>
              </StyledAspectLockButton>
            </Flex>
          </Flex>
        </Box>
      </Grid>
    </Box>
  )
}
