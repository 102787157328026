import { IconInformationError } from '@moonpig/launchpad-assets'
import { StudioElementWithId, StudioImageElement } from '../../types'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Text } from '@moonpig/launchpad-components'
import { useMemo } from 'react'

const StyledWarningIcon = styled(IconInformationError)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.colorFeedbackError} !important;
`

type ElementErrorProps = {
  element: StudioElementWithId
}

export const ElementError: React.FC<ElementErrorProps> = ({ element }) => {
  return (
    <Flex
      marginTop={4}
      alignItems="center"
      data-testid={`draggable-element-error-${element.id}`}
    >
      <StyledWarningIcon
        data-testid={`draggable-element-error-icon-${element.id}`}
      />
      <Text
        marginLeft={3}
        fontSize={12}
        color={'colorFeedbackError'}
        data-testid={`draggable-element-error-text-${element.id}`}
      >
        {element.__typename === 'StudioImageElement' && (
          <ImageElementError element={element} />
        )}
        {element.__typename === 'StudioTextElement' && <TextElementError />}
        {element.__typename === 'StudioRectangleElement' && (
          <RectangleElementError />
        )}
      </Text>
    </Flex>
  )
}

type ImageElementErrorProps = {
  element: StudioImageElement
}

const ImageElementError: React.FC<ImageElementErrorProps> = ({ element }) => {
  const error = useMemo(() => {
    return [
      element.image.status === 'FAILED' ? 'Unable to load image.' : undefined,
      element.mask?.status === 'FAILED'
        ? 'Unable to load mask image.'
        : undefined,
    ]
      .filter(Boolean)
      .join(' ')
  }, [element])

  return <>{error}</>
}

const TextElementError: React.FC = () => {
  return <>Unsupported font. Upload or change font.</>
}

const RectangleElementError: React.FC = () => {
  return <>Unknown Error</>
}
