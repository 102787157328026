import * as React from 'react'
import { PositionableProps, RenderCallbackArgs } from './Positionable'
import { PositionableContainerProps } from './PositionableContainer'
import ResizeHandle from './ResizeHandle'
import RotateHandle from './RotateHandle'
import { scaleOfElement } from './utils/dom'
import {
  calculateResizeObservableConfigs,
  calculateRotateObservableConfigs,
} from './utils/misc'
import { rotate, toCSS } from 'transformation-matrix'
import { deg2rad } from '../../utils/matrix'
import { styled } from '@moonpig/launchpad-utils'

export interface TransformBoxProps
  extends React.HTMLAttributes<HTMLDivElement> {
  position: RenderCallbackArgs['renderedPosition']
  refHandlers: RenderCallbackArgs['refHandlers']
  resizable: PositionableContainerProps['resizable']
  rotatable: PositionableProps['rotatable']
  unitsPerPixel: PositionableContainerProps['unitsPerPixel']
}

interface TransformBoxState {
  scale: number
}

const initialState: TransformBoxState = {
  scale: 1,
}

export class TransformBox extends React.Component<
  TransformBoxProps,
  TransformBoxState
> {
  public state = initialState

  public componentDidMount() {
    this.calculateScale()
  }

  public componentDidCatch() {
    this.calculateScale()
  }

  public render() {
    const {
      unitsPerPixel,
      position,
      refHandlers,
      resizable,
      rotatable,
      style,
      ...rest
    } = this.props

    const { scale } = this.state
    const transform: string = toCSS(
      rotate(deg2rad(parseFloat(position.rotation))),
    )

    return (
      <Root
        {...rest}
        ref={refHandlers.dnd}
        scale={scale}
        aria-label={'Transform Box'}
        data-testid={'transform-tool-container'}
        style={{
          ...style,
          cursor: 'move',
          height: `${position.height}`,
          left: `${position.left}`,
          top: `${position.top}`,
          transform,
          width: `${position.width}`,
        }}
      >
        {resizable &&
          calculateResizeObservableConfigs(resizable).map(resizablePosition => (
            <ResizeHandle
              size={10}
              key={resizablePosition.refHandlerName}
              // @ts-ignore
              ref={refHandlers[resizablePosition.refHandlerName]}
              top={resizablePosition.top}
              right={resizablePosition.right}
              bottom={resizablePosition.bottom}
              left={resizablePosition.left}
              rotation={parseInt(position.rotation, 10)}
              style={{ transform: `scale(${scale})` }}
            />
          ))}
        {rotatable &&
          calculateRotateObservableConfigs().map(rotatablePosition => (
            <RotateHandle
              key={rotatablePosition.refHandlerName}
              // @ts-ignore
              ref={refHandlers[rotatablePosition.refHandlerName]}
              top={rotatablePosition.top}
              right={rotatablePosition.right}
              bottom={rotatablePosition.bottom}
              left={rotatablePosition.left}
              style={{ transform: `scale(${scale})` }}
            />
          ))}
      </Root>
    )
  }

  /**
   * Determine how much visual elements like handles and borders need to be scaled
   * in order to nullify any scale done to the container or its ancestors.
   */
  private calculateScale = () => {
    const { refHandlers } = this.props
    const { container: containerRef } = refHandlers

    if (!containerRef.current) {
      return
    }

    const containerScale = scaleOfElement(containerRef.current)
    const scale = 1 / containerScale

    if (this.state.scale === scale) {
      return
    }

    this.setState({ scale })
  }
}

interface RootProps {
  scale: number
}

const Root = styled.div<RootProps>`
  box-sizing: border-box;
  border: ${(props: RootProps) => props.scale}px dotted rgba(233, 30, 99, 0.5);
  position: absolute;
  z-index: 1000;
`

export default TransformBox
