export const createFontFace = async (
  name: string,
  ttf: string,
  setError: (bool: boolean) => void,
) => {
  const fontFace = new FontFace(name, `url('${ttf}') format('truetype')`, {
    display: 'optional',
  })

  await fontFace
    .load()
    .then(() => {
      document.fonts.add(fontFace)
    })
    .catch(() => {
      setError(true)
    })
}
