import { fromEvent, Observable } from 'rxjs'
import {
  concatMap,
  elementAt,
  first,
  mapTo,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { documentMouseMove$, documentMouseUp$ } from './misc'

interface InteractionEndObservableOptions {
  // HTML element used as a basis for all calculations.
  element: HTMLElement
}

export const createInteractionEndObservable = ({
  element,
}: InteractionEndObservableOptions): Observable<MouseEvent> => {
  const mouseDown$ = fromEvent<MouseEvent>(element, 'mouseup')

  return mouseDown$.pipe(
    concatMap(mouseDownEvent =>
      documentMouseUp$.pipe(
        first(),
        takeUntil(documentMouseMove$.pipe(elementAt(3))),
        tap(e => {
          e.stopPropagation()
          e.preventDefault()
        }),
        mapTo(mouseDownEvent),
      ),
    ),
  )
}
