import {
  StudioPlaceholderTextPart,
  StudioPlainTextPart,
  StudioStaticTextPart,
  StudioStyledTextPart,
  StudioTextParts,
} from '../types'
import { levenshtein } from './levenshtein'

/**
 * Attempt to find a placeholder text part that matches the parts found in the text string
 * First try full string matching
 * If not found, use string similarity matching with a bias on place in array
 */
const findBestMatch = (
  inputText: string,
  templateTexts: string[],
  textParts: StudioPlaceholderTextPart,
): StudioPlainTextPart | StudioStaticTextPart | null => {
  let bestMatch = null
  let bestMatchScore = Infinity
  const textPartsArray = textParts.textParts

  templateTexts.forEach((templateText, index1) => {
    if (templateText === inputText) {
      textPartsArray.forEach((part, index2) => {
        if (part.text === inputText) {
          const distance = Math.abs(index2 - index1)
          if (distance < bestMatchScore) {
            bestMatch = part
            bestMatchScore = distance
          }
        }
      })
    } else {
      const similarityScore = levenshtein(inputText, templateText)
      if (similarityScore < bestMatchScore) {
        textPartsArray.forEach(part => {
          const score = levenshtein(inputText, part.text)
          if (score < 2) {
            // Only accept full match and one-character (editing) changes
            bestMatch = part
            bestMatchScore = score
          }
        })
      }
    }
  })
  return bestMatch
}

interface IExistingTextPart {
  text: string
  customisableParts: string[]
  textParts:
    | StudioStaticTextPart
    | StudioPlainTextPart
    | StudioStyledTextPart
    | StudioPlaceholderTextPart
}

const findExistingTextPart = ({
  text,
  customisableParts,
  textParts,
}: IExistingTextPart): StudioStaticTextPart | StudioPlainTextPart => {
  const { __typename } = textParts
  if (__typename === 'StudioPlaceholderTextPart') {
    const match = findBestMatch(text, customisableParts, textParts)
    if (match && match.__typename === 'StudioPlainTextPart') {
      const result = { ...match, text }
      if (match.maxCharacters !== null) {
        ;(result as StudioPlainTextPart).maxCharacters = Math.max(
          (match as StudioPlainTextPart).maxCharacters || 0,
          text.length,
        )
      } else {
        result.maxCharacters = null
      }
      return result
    }
  }
  // Give up or no part to match to

  return {
    __typename: 'StudioPlainTextPart',
    text,
    allowDefault: true,
    allowBlank: false,
    customNo: 0,
    maxCharacters: null,
    textTransform: null,
  }
}

const splitOnBracketedWords = (text: string) => {
  const pattern = /((?<=^|\s)\[[^[\]]+](?=$|\s|[.,;:?!]))/g
  const parts = []
  let lastIndex = 0
  text.replace(pattern, (match, _p1, offset) => {
    if (offset > lastIndex) {
      parts.push(text.slice(lastIndex, offset))
    }
    parts.push(match)
    lastIndex = offset + match.length
    return match
  })
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex))
  }
  return parts
}

const mapCustomisableParts = (
  customisableParts: string[],
  textParts:
    | StudioStaticTextPart
    | StudioPlainTextPart
    | StudioStyledTextPart
    | StudioPlaceholderTextPart,
): StudioTextParts => {
  return {
    __typename: 'StudioPlaceholderTextPart',
    textParts: customisableParts.map(part => {
      const text = /((?<=^|\s)\[[^[\]]+](?=$|\s|[.,;:?!]))/i.exec(part)
      if (text?.length === 2) {
        return findExistingTextPart({
          text: text[1]?.replace(/[[\]]/g, ''),
          customisableParts: customisableParts,
          textParts: textParts,
        })
      }
      return {
        __typename: 'StudioStaticTextPart',
        text: part,
      }
    }),
  }
}

export const buildTextPartsFromString = (
  text: string,
  textParts:
    | StudioStaticTextPart
    | StudioPlainTextPart
    | StudioStyledTextPart
    | StudioPlaceholderTextPart,
): StudioTextParts => {
  const customisableParts = splitOnBracketedWords(text)
  let { __typename } = textParts
  let { allowBlank, allowDefault } = textParts as StudioStyledTextPart
  if (customisableParts.length > 0) {
    return mapCustomisableParts(customisableParts, textParts)
  }
  // Make sure we reset the text part to styled text if we are in StudioPlaceholderTextPart mode
  // but no text parts are present
  if (__typename === 'StudioPlaceholderTextPart') {
    __typename = 'StudioStyledTextPart'
    allowDefault = true
    allowBlank = false
  }
  return {
    ...textParts,
    __typename,
    allowDefault,
    allowBlank,
    text,
  } as StudioTextParts
}
