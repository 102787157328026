import React from 'react'
import {
  system as s,
  sizingSpacing,
  SizingSpacingSystemProps,
} from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, ScreenReaderOnly } from '@moonpig/launchpad-components'
import { useLocaleText } from '@moonpig/launchpad-localisation'
import { DescriptorText, HelperText, Label } from '@moonpig/launchpad-forms'

type TextAreaProps = {
  /** Label (used for <label>) */
  label: string
  /** Name attribute */
  name: string
  /** Value attribute */
  value: string
  /** added Select handler **/
  onSelect?: React.ReactEventHandler<HTMLTextAreaElement> | undefined
  /** Handler for change value can be found in e.target.value */
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  /** Handler for when input gets focus */
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  /** Handler for when input loses focus */
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  /** ID for the input (uses name if not provided) */
  id?: string
  /** Placeholder used when empty value provided */
  placeholder?: string
  /** Text below label for descriptor */
  descriptor?: string
  /** Input disabled */
  disabled?: boolean
  /** Determines whether input value is required or optional */
  required?: boolean
  /** Determines whether the input has autocomplete enabled and takes a number of [values]( https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values) */
  autoComplete?: string
  /** Set to true if the form contains errors */
  error?: boolean
  /** Adds helper text */
  helperText?: string
  /** Restricts the maximum length */
  maxLength?: number
  /** Specifies the number of rows */
  rows?: number
  /** Adds CSS styles */
  style?: React.CSSProperties
} & SizingSpacingSystemProps

const SpacedTextArea = styled.div<SizingSpacingSystemProps>`
  ${sizingSpacing}
`

const StyledTextAreaWrapper = styled.div`
  position: relative;
`

const StyledCharacterLimit = styled.span`
  ${s({ typography: 'typeBodyCaption', pt: 4 })}
  margin-left: auto;
`

const StyledTextArea = styled.textarea`
  ${s({ color: 'colorTextBody', borderRadius: 2, p: 5, mb: -3 })}
  border: 2px solid ${colorValue('colorBlack50')};
  width: 100%;
  font: inherit;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable */

  &:disabled {
    ${s({
      bgcolor: 'colorBlack10',
      borderColor: 'colorInteractionDeactivated',
    })}
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover {
      ${s({ borderColor: 'colorBackground07' })}
    }
    &:focus-visible {
      outline: none;
      ${s({ boxShadow: 5 })}
    }
  }

  &::placeholder {
    ${s({ color: 'colorBlack60' })}
  }

  &.has-error {
    ${s({ bgcolor: 'colorBackgroundError', borderColor: 'colorFeedbackError' })}
  }
`

type Ref = HTMLTextAreaElement

export const TextEditorTextArea = React.forwardRef<Ref, TextAreaProps>(
  (
    {
      label,
      id,
      name,
      value,
      onChange,
      onFocus,
      onBlur,
      onSelect,
      placeholder,
      descriptor,
      disabled,
      required,
      error,
      autoComplete,
      helperText,
      maxLength,
      rows,
      style,
      ...props
    },
    forwardedRef,
  ) => {
    const inputId = id || name
    const descriptorId = `${inputId}__descriptor-text`
    const helperTextId = `${inputId}__helper-text`
    const t = useLocaleText()

    return (
      <SpacedTextArea {...props} style={{ ...style }}>
        <Label htmlFor={inputId} required={required} disabled={disabled}>
          {label}
        </Label>
        {descriptor && (
          <DescriptorText id={descriptorId}>{descriptor}</DescriptorText>
        )}
        <StyledTextAreaWrapper>
          <StyledTextArea
            style={{ ...style }}
            onSelect={onSelect}
            className={error ? 'has-error' : undefined}
            ref={forwardedRef}
            id={inputId}
            name={name}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            autoComplete={autoComplete}
            aria-required={required}
            aria-describedby={helperTextId}
            maxLength={maxLength}
            rows={rows}
          />
          <Flex>
            {helperText && (
              <HelperText id={helperTextId} error={error}>
                {helperText}
              </HelperText>
            )}
            {maxLength && (
              <>
                <StyledCharacterLimit
                  data-testid="mp-textarea-character-limit"
                  aria-hidden
                  className={error ? 'has-error' : undefined}
                >
                  {value.length} / {maxLength}
                </StyledCharacterLimit>
                <ScreenReaderOnly>
                  <span aria-live="polite">
                    {t('text_area.characters_limit', value.length, maxLength)}
                  </span>
                </ScreenReaderOnly>
              </>
            )}
          </Flex>
        </StyledTextAreaWrapper>
      </SpacedTextArea>
    )
  },
)

TextEditorTextArea.displayName = 'TextArea'
